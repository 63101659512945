import { useState, useMemo, useEffect } from "react";
import Slider from "@mui/material/Slider";
import AnimatedNumber from "react-animated-number";
import { useIcons } from "../../assets/hooks/useIcons";
import bet_buttons_sound from "../../assets/sounds/bet_buttons_sound.wav";
import autoplay_slider_sound from "../../assets/sounds/autoplay_slider_sound.wav";
import demo from "../../assets/images/currency/jellybet-currency.png";
import { bigNumberMax, bigNumberMin } from "../../assets/utilis/utilis";
import {
  MIN_AUTOPLAY_NUMBER,
  MAX_AUTOPLAY_NUMBER,
  MIN_DEMO_BET_NUMBER,
  DEMO_DECIMALS
} from "../../assets/utilis/constants";
import "./GameOptions.css";
import { ethers } from "ethers";

function GameOptions({
  payout,
  payoutText = "Payout",
  chance,
  btnText,
  onClick,
  rolling,
  isSoundOn,
  isBlocked,
  minMaxBet,
  user,
  bet,
  setBet,
  autoplayAmount,
  setAutoplayAmount,
  switchNetwork,
  isSupportedNetwork,
  isAutoplay,
  demoBalance,
  queue,
  isMaxBet,
  isMinBet,
  isSufficientAllowance,
  allowToken,
  isDemo,
  demoCoinLogo,
  preferredChainId,
  tokenDecimals,
  betValueWriten,
  setBetValueWriten,
  autoplayText = 'Autoplay',
  isDemoSwitch,
  openWalletModal,
  externalAccount,
  active,
  sendToParent,
  isExternalWalletConnected,
  isExternalAccountConnection,
  preferredToken
}) {
  const { setCurrencyIconByTokenName } = useIcons();
  const [writtenBet, setWrittenBet] = useState(null)
  const buttonsAudio = useMemo(() => new Audio(bet_buttons_sound), []);
  const autoplayAudio = useMemo(() => new Audio(autoplay_slider_sound), []);
  const [autoPlayAmountWritten, setAutoPlayAmountWriten] = useState(autoplayAmount.toString())

  const [isDisabled, setDisabled] = useState(false);
  const [isBetBtnDisabled, setBetBtnDisabled] = useState({
    increase: false,
    reduce: false,
    min: false,
    max: false,
    multiply: false,
    divide: false,
  });

  useEffect(() => {
    if (localStorage.getItem("bet") !== null) return;
    if (
      user &&
      active &&
      typeof user.balance === "number" &&
      !minMaxBet.min.isZero() &&
      minMaxBet.max.isZero()
    ) {
      setBet(minMaxBet.min);
      setBetValueWriten(prettyTableBetValue(minMaxBet.min))
    }
    if (!user || !active) {
      setBet(MIN_DEMO_BET_NUMBER);
      setBetValueWriten(prettyTableBetValue(MIN_DEMO_BET_NUMBER))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minMaxBet, user, active]);

  useEffect(() => {
    console.log({ queue });
    if (queue && queue.length > 0) {
      setDisabled(true);
    } else {
      if (isAutoplay) {
        if (
          autoPlayAmountWritten.length > 0 &&
          autoplayAmount >= MIN_AUTOPLAY_NUMBER &&
          autoplayAmount <= MAX_AUTOPLAY_NUMBER
        ) {
          setDisabled(
            user && active && !isDemo
              ? user.balance.lt(bet.mul(autoplayAmount))
              : demoBalance.lt(bet.mul(autoplayAmount))
          );
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(user && active && !isDemo ? user.balance.lt(bet) : demoBalance.lt(bet));
      }
    }
  }, [user, bet, isAutoplay, autoplayAmount, autoPlayAmountWritten, demoBalance, queue, active, isDemo]);

  useEffect(() => {
    if (user && active && !isDemo) {
      setBetBtnDisabled((prevObj) => ({
        ...prevObj,
        increase:
          bet.eq(minMaxBet.max) || bet.eq(user.balance),
        reduce: bet.eq(minMaxBet.min),
        min: bet.eq(minMaxBet.min),
        max: bet.eq(minMaxBet.max) || bet.eq(user.balance),
        multiply:
          bet.eq(minMaxBet.max) || bet.eq(user.balance),
        divide: bet.eq(minMaxBet.min),
      }));
    } else {
      setBetBtnDisabled((prevObj) => ({
        ...prevObj,
        increase: bet.eq(minMaxBet.max) || bet.eq(demoBalance),
        reduce: bet.eq(minMaxBet.min),
        min: bet.eq(minMaxBet.min),
        max: bet.eq(minMaxBet.max) || bet.eq(demoBalance),
        multiply: bet.eq(minMaxBet.max) || bet.eq(demoBalance),
        divide: bet.eq(minMaxBet.min),
      }));
    }
  }, [bet, minMaxBet, user, demoBalance, active, isDemo]);

  function prettyTablePayoutValue(n) {
    return `x${n.toFixed(2).split(".").join(",")}`;
  }

  function prettyTableChanceValue(n) {
    return `${n.toFixed(2).split(".").join(",")}%`;
  }

  function prettyTableBetValue(n) {
    if (n instanceof ethers.BigNumber) return Number(Number(ethers.utils.formatUnits(n, isDemo ? DEMO_DECIMALS : tokenDecimals)).toFixed(4));
    return n;
  }

  function playSound(audio) {
    if (isSoundOn) {
      audio.play().catch((err) => {
        console.log(err);
      });
    }
  }

  function handleIncreaseBet() {
    const newBet = bigNumberMin([
      bet.add(ethers.BigNumber.from(1).mul(ethers.BigNumber.from(10).pow(isDemo ? DEMO_DECIMALS : tokenDecimals)).div(1)),
      minMaxBet.max,
      demoBalance > 0 ? demoBalance : minMaxBet.max
    ]);
    setWrittenBet(null);
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
    if (!newBet.eq(bet)) playSound(buttonsAudio);
  }

  function handleReduceBet() {
    const newBet = bigNumberMax(
      [
        bet.sub(ethers.BigNumber.from(1).mul(ethers.BigNumber.from(10).pow(isDemo ? DEMO_DECIMALS : tokenDecimals)).div(1)),
        minMaxBet.min
      ]
    );
    setWrittenBet(null);
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
    if (!newBet.eq(bet)) playSound(buttonsAudio);
  }

  function setMinBet() {
    const newBet = minMaxBet.min;
    setWrittenBet(null);
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
    if (!newBet.eq(bet)) playSound(buttonsAudio);
  }

  function setMaxBet() {
    const newBet = bigNumberMin(
      [
        minMaxBet.max,
        user && active && !isDemo
          ? user.balance.gt(0)
            ? user.balance
            : minMaxBet.min
          : demoBalance.gt(0)
            ? demoBalance
            : minMaxBet.min
      ]
    );
    setWrittenBet(null);
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
    if (!newBet.eq(bet)) playSound(buttonsAudio);
  }

  function handleMultiplyBet() {
    const newBet = bigNumberMin(
      [
        bet.mul(2),
        minMaxBet.max,
        user && active && !isDemo
          ? user.balance.gt(0)
            ? user.balance
            : minMaxBet.max
          : demoBalance.gt(0)
            ? demoBalance
            : minMaxBet.max
      ]
    );
    setWrittenBet(null);
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
    if (!newBet.eq(bet)) playSound(buttonsAudio);
  }

  function handleDivideBet() {
    const newBet = bigNumberMax(
      [
        bet.div(2), minMaxBet.min
      ]
    );
    setWrittenBet(null);
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
    if (!newBet.eq(bet)) playSound(buttonsAudio);
  }

  function handleBetChange(evt) {
    const initValue = evt.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    let value = ethers.BigNumber.from(0);
    if (initValue && Number(initValue) > 0) {
      value = ethers.utils.parseUnits(initValue, isDemo ? DEMO_DECIMALS : tokenDecimals);
    }
    setWrittenBet(initValue)
    setBet(value);
    setBetValueWriten(prettyTableBetValue(value))
  }

  function switchNetworkInternal() {
    if (externalAccount) {
      sendToParent("switchNetwork", { "chainId": preferredChainId })
    } else {
      switchNetwork(preferredChainId)
    }
  }

  function checkBetChange(evt) {
    const value = evt.target.value;
    let newBet;
    if (value && !isNaN(Number(value))) {
      newBet = ethers.utils.parseUnits(value, isDemo ? DEMO_DECIMALS : tokenDecimals)
    } else {
      newBet = minMaxBet.min;
    }
    setBet(newBet);
    setBetValueWriten(prettyTableBetValue(newBet))
    localStorage.setItem("bet", newBet);
  }

  function handleAmountChange(evt) {
    const value = evt.target.value.replace(/[^0-9]/g, "");
    let writenValue = value;
    if (value) {
      let newValue = Number(value) > MAX_AUTOPLAY_NUMBER
        ? MAX_AUTOPLAY_NUMBER
        : Number(value)
      setAutoplayAmount(newValue);
      writenValue = newValue.toString()
    }

    setAutoPlayAmountWriten(writenValue)
    playSound(autoplayAudio);
  }

  function handleSliderChange(evt) {
    setAutoplayAmount(evt.target.value);
    setAutoPlayAmountWriten(evt.target.value.toString())
  }

  function stopSlider() {
    playSound(autoplayAudio);
  }

  return (
    <div className={`options ${isBlocked ? "options_disabled" : ""}`}>
      <div className="options__payout-block">
        <div className="options__payout-text-block">
          <p className="options__block-title">{payoutText}</p>
          <AnimatedNumber
            component="p"
            value={Number(payout)}
            className="options__payout-numbers"
            duration={200}
            formatValue={(n) => prettyTablePayoutValue(n)}
          />
        </div>

        <div className="options__payout-text-block">
          <p className="options__block-title">Chance</p>
          <AnimatedNumber
            component="p"
            value={Number(chance)}
            className="options__payout-numbers"
            duration={200}
            formatValue={(n) => prettyTableChanceValue(n)}
          />
        </div>
      </div>

      <div className="options__bet-block">
        <p className="options__block-title">Your bet</p>

        <div className="options__set-bet-block">
          <button
            className={`options__bet-btn ${isBetBtnDisabled.reduce ? "options__bet-btn_disabled" : ""
              }`}
            type="button"
            aria-label="Reduce the bet size"
            onClick={handleReduceBet}
          >
            -
          </button>
          <div className="options__bet-box">
            <img
              className="options__currency-icon"
              src={
                !isDemo ? setCurrencyIconByTokenName(preferredToken)
                  : demoCoinLogo ? demoCoinLogo : demo
              }
              alt="Network icon"
            />
            <input
              className="options__bet-input"
              id="bet"
              name="bet"
              type="text"
              // inputMode="numeric"
              autoComplete="off"
              value={writtenBet !== null ? prettyTableBetValue(writtenBet) : betValueWriten}
              onChange={handleBetChange}
              onBlur={checkBetChange}
            />
          </div>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.increase ? "options__bet-btn_disabled" : ""
              }`}
            type="button"
            aria-label="Increase the bet size"
            onClick={handleIncreaseBet}
          >
            +
          </button>
        </div>

        <div className="options__bet-options">
          <button
            className={`options__bet-btn ${isBetBtnDisabled.min ? "options__bet-btn_disabled" : ""
              }`}
            type="button"
            aria-label="Set minimal bet size"
            onClick={setMinBet}
          >
            Min
          </button>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.divide ? "options__bet-btn_disabled" : ""
              }`}
            type="button"
            aria-label="Divide the bet by two"
            onClick={handleDivideBet}
          >
            /2
          </button>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.multiply ? "options__bet-btn_disabled" : ""
              }`}
            type="button"
            aria-label="Multiply the bet by two"
            onClick={handleMultiplyBet}
          >
            x2
          </button>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.max ? "options__bet-btn_disabled" : ""
              }`}
            type="button"
            aria-label="Set maximal bet size"
            onClick={setMaxBet}
          >
            Max
          </button>
        </div>
      </div>

      <div className="options__autoplay-block">
        <div className="options__autoplay-switch-block">
          <div className="options__autoplay-label">
            <p className="options__block-title options__block-title_type_label">
              {autoplayText}
            </p>
          </div>

          <input
            className={`options__autoplay-input ${!isAutoplay ? "options__autoplay-input_disabled" : ""
              }`}
            id="amount"
            name="amount"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={autoPlayAmountWritten}
            onChange={handleAmountChange}
            readOnly={!isAutoplay}
          />
        </div>

        <div className="options__slider-box">
          <Slider
            className={`options__autoplay-slider ${!isAutoplay ? "options__autoplay-slider_disabled" : ""
              }`}
            aria-label="Autoplay"
            min={MIN_AUTOPLAY_NUMBER}
            max={MAX_AUTOPLAY_NUMBER}
            step={1}
            defaultValue={0}
            value={autoplayAmount}
            onChange={handleSliderChange}
            onChangeCommitted={stopSlider}
          />
          <div
            className={`options__slider-disabled ${!isAutoplay ? "options__slider-disabled_visible" : ""
              }`}
          >
            <div className="options__slider-thumb" />
            <div className="options__slider-rail" />
          </div>
        </div>
      </div>

      {isDemo ?
        <div className="options__play-btn-box">
          <button
            className={`options__play-btn ${rolling ? "options__play-btn_rolling" : ""
              } ${isDisabled || isMaxBet || isMinBet ? "options__play-btn_disabled" : ""}`}
            type="button"
            onClick={onClick}
          >
            {btnText}
          </button>
        </div>
        :
        !user || (isExternalAccountConnection && !isExternalWalletConnected) ?
          <div className="options__play-btn-box">
            <button
              className="options__play-btn"
              type="button"
              onClick={openWalletModal}
            >
              Connect

            </button>
          </div>
          :
          <div className="options__play-btn-box">
            {user && !isDemo && !isSupportedNetwork ? (
              <button
                className="options__play-btn"
                type="button"
                onClick={switchNetworkInternal}
              >
                Switch Network
              </button>
            ) : user && !isDemo && !isSufficientAllowance ?
              <button
                className={`options__play-btn`}
                type="button"
                onClick={allowToken}
              >
                Allow token use
              </button> : (
                <button
                  className={`options__play-btn ${rolling ? "options__play-btn_rolling" : ""
                    } ${isDisabled || isMaxBet || isMinBet ? "options__play-btn_disabled" : ""}`}
                  type="button"
                  onClick={onClick}
                >
                  {btnText}
                </button>
              )}
          </div>
      }


    </div>
  );
}

export default GameOptions;