import { useEffect, useRef, useState } from "react";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import "./CrashGame.css";

function CrashGame({ multiplier, isWin, isLost }) {
  const gameRef = useRef();
  const formattedMultiplier =
    multiplier < 100 ? multiplier.toFixed(2) : multiplier.toFixed(1);
  const { width } = useWindowSize();
  const [fontSize, setFontSize] = useState("220px");

  useEffect(() => {
    if (!gameRef) return;

    const scrollWidth = gameRef.current.scrollWidth;
    const newSize =
      width > 800 ? (28.28 * scrollWidth) / 100 : (20 * scrollWidth) / 100;
    setFontSize(`${newSize}px`);
  }, [gameRef, width]);

  return (
    <div
      className="limbo-game"
      ref={gameRef}
      style={{ "--limbo-game-font-size": fontSize }}
    >
      <div
        className={`limbo-game__multiplier ${
          isWin ? "limbo-game__multiplier_type_win" : ""
        } ${isLost ? "limbo-game__multiplier_type_lost" : ""}`}
        data-content={`x ${formattedMultiplier}`}
      >
        x {formattedMultiplier}
      </div>
    </div>
  );
}

export default CrashGame;
