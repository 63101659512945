import { useEffect } from "react";
import {
  DEGEN_GAME_MODE,
  DEGEN_WHEEL_SLICES_LIST,
  RISKY_GAME_MODE,
  RISKY_WHEEL_SLICES_LIST,
  SAFE_GAME_MODE,
  SAFE_WHEEL_SLICES_LIST,
} from "../../../assets/utilis/constants";
import { WheelStarIcon, WheelPointerIcon } from "../../../assets/utilis/icons";
import safeBaraban from "../../../assets/images/free-wheel/baraban-safe-mode.png";
import riskyBaraban from "../../../assets/images/free-wheel/baraban-risky-mode.png";
import degenBaraban from "../../../assets/images/free-wheel/baraban-degen-mode.png";
import "./Spinner.css";

function Spinner({
  spinning,
  starting,
  stoping,
  rotateDeg,
  startDeg,
  prizePosition,
  isFastSpin,
  gameMode,
  isGamePlaying,
}) {
  const isGameOn = spinning || starting || stoping;

  // load baraban images
  useEffect(() => {
    const imagesArr = [safeBaraban, riskyBaraban, degenBaraban];

    Promise.all(imagesArr.map((img) => loadImage(img))).catch(() =>
      console.error("could not load coin images")
    );
  }, []);

  const loadImage = (path) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(path);
      };
      img.onerror = () => reject();

      img.src = path;
    });

  return (
    <div className="spinner">
      <div className="spinner__container">
        <div
          className={`spinner__baraban ${
            spinning ? "spinner__baraban_spinning" : ""
          } ${
            starting && isFastSpin
              ? "spinner__baraban_starting-autoplay"
              : starting
              ? "spinner__baraban_starting"
              : ""
          } ${
            stoping && rotateDeg > 360 && isFastSpin
              ? "spinner__baraban_stoping-faster-autoplay"
              : stoping && rotateDeg > 360
              ? "spinner__baraban_stoping-faster"
              : ""
          } ${
            stoping && rotateDeg <= 360 && isFastSpin
              ? "spinner__baraban_stoping-slower-autoplay"
              : stoping && rotateDeg <= 360
              ? "spinner__baraban_stoping-slower"
              : ""
          }`}
          style={{
            "--wheel-rotate-deg": `${rotateDeg}deg`,
            "--wheel-start-deg": `${startDeg}deg`,
          }}
        >
          <div
            className={`spinner__slices-box spinner__slices-box_type_safe ${
              gameMode === SAFE_GAME_MODE ? "spinner__slices-box_visible" : ""
            }`}
          >
            {Object.values(SAFE_WHEEL_SLICES_LIST).map((slice, i) => (
              <div className="spinner__slice" key={`safe-slice-${i}`}>
                <span
                  className={`spinner__label ${
                    isGamePlaying
                      ? isGameOn ||
                        (prizePosition !== undefined && i !== prizePosition)
                        ? "spinner__label_type_opacity"
                        : ""
                      : ""
                  }`}
                >
                  х{slice}
                </span>
                <div
                  className={`spinner__icon-box ${
                    slice === 2 ? "spinner__icon-box_type_big" : ""
                  }`}
                >
                  <div className="spinner__icon">
                    {WheelStarIcon({
                      mainClassName: "spinner__star-icon",
                      fillClassName: "spinner__star-icon-fill",
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={`spinner__slices-box spinner__slices-box_type_risky ${
              gameMode === RISKY_GAME_MODE ? "spinner__slices-box_visible" : ""
            }`}
          >
            {Object.values(RISKY_WHEEL_SLICES_LIST).map((slice, i) => (
              <div className="spinner__slice" key={`risky-slice-${i}`}>
                <span
                  className={`spinner__label ${
                    isGamePlaying
                      ? isGameOn ||
                        (prizePosition !== undefined && i !== prizePosition)
                        ? "spinner__label_type_opacity"
                        : ""
                      : ""
                  }`}
                >
                  х{slice}
                </span>
                <div
                  className={`spinner__icon-box ${
                    slice === 3 ? "spinner__icon-box_type_big" : ""
                  }`}
                >
                  <div className="spinner__icon">
                    {WheelStarIcon({
                      mainClassName: "spinner__star-icon",
                      fillClassName: "spinner__star-icon-fill",
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={`spinner__slices-box spinner__slices-box_type_degen ${
              gameMode === DEGEN_GAME_MODE ? "spinner__slices-box_visible" : ""
            }`}
          >
            {Object.values(DEGEN_WHEEL_SLICES_LIST).map((slice, i) => (
              <div className="spinner__slice" key={`degen-slice-${i}`}>
                <span
                  className={`spinner__label ${
                    isGamePlaying
                      ? isGameOn ||
                        (prizePosition !== undefined && i !== prizePosition)
                        ? "spinner__label_type_opacity"
                        : ""
                      : ""
                  }`}
                >
                  х{slice}
                </span>
                <div
                  className={`spinner__icon-box ${
                    slice === 19.4 ? "spinner__icon-box_type_big" : ""
                  }`}
                >
                  <div className="spinner__icon">
                    {WheelStarIcon({
                      mainClassName: "spinner__star-icon",
                      fillClassName: "spinner__star-icon-fill",
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="spinner__pointer">
        {WheelPointerIcon({ mainClassName: "spinner__pointer-icon" })}
      </div>
    </div>
  );
}

export default Spinner;
