import { useState, useEffect, useRef } from "react";
import AnimatedNumber from "react-animated-number";
import { useConnector } from '@usedapp/core';
import { useIcons } from "../../assets/hooks/useIcons";
import { getWallet, prettyValue, truncateAddress } from "../../assets/utilis/utilis";
import {
  SOUND_ICON,
  SOUND_OFF_ICON,
  MUSIC_ICON,
  MUSIC_OFF_ICON,
  ARROW_ICON,
  DISCONNECT_ICON,
  WALLET_CHANGE_ICON,
} from "../../assets/utilis/icons";
import demo from "../../assets/images/currency/jellybet-currency.png";
import "./Header.css";
import { ethers } from "ethers";
import {
  DEMO_DECIMALS,
  // FRONTEND_IFRAME_URL,
  INIT_DEMO_BALANCE
} from "../../assets/utilis/constants";

function Header({
  isLoggedIn,
  isSoundOn,
  toggleSound,
  isMusicOn,
  toggleMusic,
  onModalOpen,
  disconnect,
  user,
  demoBalance,
  setDemoBalance,
  demoCoinLogo,
  setIsDemo,
  isDemo,
  isDemoSwitch,
  handleSwitchDemo,
  isGamePlaying,
  isBlocked,
  tokenDecimals,
  returnUrl,
  preferredChainId,
  isExternalAccount,
  account,
  active,
  isMagicConnectorStateVar,
  preferredToken
}) {
  const overlay = useRef();
  const { connector } = useConnector();
  const { setCurrencyIconByTokenName } = useIcons();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current !== evt.target) {
        setPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    if (user && user.balance !== undefined) setBalance(user.balance);
  }, [user]);

  function prettyBalanceValue(n, title) {
    n = n ? ethers.utils.parseUnits(n.toFixed(isDemo ? DEMO_DECIMALS : tokenDecimals), isDemo ? DEMO_DECIMALS : tokenDecimals) : ethers.BigNumber.from(0)
    return prettyValue(n, isDemo ? DEMO_DECIMALS : tokenDecimals) + ` ${title}`
  }

  function logout() {
    disconnect();
    // setIsDemo(true);
    setPopupOpen(false);
  }

  function requestChangeWallet() {
    window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        }
      ]
    })
  }

  return (
    <header className="header">
      <div className="header__container">
        {isDemoSwitch ?
          <div className={`header__demo-switch-container ${isGamePlaying || isBlocked ? 'header__demo-switch-container_disabled' : ''} ${!isDemo ? 'header__demo-switch-container_inactive' : ''}`}>
            <div className={`header__demo-switch ${isDemo ? 'header__demo-switch_active' : ''}`} onClick={handleSwitchDemo}>
              <div className="header__demo-switch-circle"></div>
            </div>
            <p className="header__demo-switch-text">Demo mode</p>
          </div>

          : null}
        <div className="header__sound-block">
          <button
            className="header__sound-button"
            type="button"
            onClick={toggleSound}
          >
            {isSoundOn ? SOUND_ICON : SOUND_OFF_ICON}
          </button>
          <button
            className="header__sound-button"
            type="button"
            onClick={toggleMusic}
          >
            {isMusicOn ? MUSIC_ICON : MUSIC_OFF_ICON}
          </button>
        </div>



        {/* <button
          className="header__burger-button"
          type="button"
          aria-label="Open menu"
          onClick={!isMenuOpen ? toggleMenu : undefined}
        >
          {BURGER_BTN_ICON}
        </button> */}
        <div></div>

        <div className={`header__info-block ${isGamePlaying || isBlocked ? 'header__info-block_disabled' : ''}`}>
          <div
            className={`header__demo-block ${user && active ? "header__demo-block_user" : ""
              }`}
          >

            {isDemo ?
              <div
                className="header__demo-box"
                onClick={() => setDemoBalance(INIT_DEMO_BALANCE)}
              >
                <img
                  className="header__currency-icon"
                  src={demoCoinLogo ? demoCoinLogo : demo}
                  alt="Demo currency icon"
                />
                <AnimatedNumber
                  component="p"
                  value={Number(ethers.utils.formatUnits(demoBalance, DEMO_DECIMALS))}
                  className="header__demo-text"
                  duration={200}
                  formatValue={(n) => prettyBalanceValue(n, "DEMO")}
                />
              </div>
              :
              user && active && !isExternalAccount ?
                <div className="header__demo-box">
                  <img
                    className="header__currency-icon"
                    src={setCurrencyIconByTokenName(preferredToken)}
                    alt="Network icon"
                  />
                  <AnimatedNumber
                    component="p"
                    value={Number(ethers.utils.formatUnits(balance, tokenDecimals))}
                    className="header__demo-text"
                    duration={200}
                    formatValue={(n) => prettyBalanceValue(n, preferredToken.toUpperCase())}
                  />
                </div>
                : null
            }




          </div>

          {!isExternalAccount ?
            <>
              {!active || !isLoggedIn ? (
                <button
                  className={`header__login-btn `}
                  type="button"
                  onClick={onModalOpen}
                >
                  Connect
                </button>
                // <>
                // </>
              )
                : null}
              {!active || !isLoggedIn ? (
                // <button
                //   className="header__login-btn"
                //   type="button"
                //   onClick={onModalOpen}
                // >
                //   Connect
                // </button>
                <>
                </>
              ) :
                isDemo ?
                  null
                  :
                  <div className="header__box-with-drop">
                    <button
                      className="header__user-box"
                      type="button"
                      onClick={() => setPopupOpen(true)}
                    >
                      <p className="header__user-text">
                        {account ? truncateAddress(account) : null}
                      </p>
                      <div
                        className={`header__arrow-icon ${isPopupOpen ? "header__arrow-icon_reverse" : ""
                          }`}
                      >
                        {ARROW_ICON}
                      </div>
                    </button>

                    <div
                      className={`header__logout-popup ${isPopupOpen ? "header__logout-popup_opened" : ""
                        }`}
                      ref={overlay}
                    >
                      {
                        getWallet(connector) === 'injected' && !isMagicConnectorStateVar?
                          <button
                            className="header__logout-btn"
                            type="button"
                            onClick={requestChangeWallet}
                          >
                            <p className="header__logout-text">Change</p>
                            <div className="header__logout-icon">{WALLET_CHANGE_ICON}</div>
                          </button> : null
                      }
                      {/* <a className="header__logout-btn" target="_parent" href={`${FRONTEND_IFRAME_URL}/wallet-change?network=${preferredChainId}&return_url=${returnUrl ? returnUrl : (window.location !== window.parent.location)
                        ? document.referrer + window.location.pathname.substring(1)
                        : document.location.href}`}>
                        <p className="header__logout-text">Change</p>
                        <div className="header__logout-icon">{WALLET_CHANGE_ICON}</div>
                      </a> */}
                      <button
                        className="header__logout-btn"
                        type="button"
                        onClick={logout}
                      >
                        <p className="header__logout-text">Disconnect</p>
                        <div className="header__logout-icon">{DISCONNECT_ICON}</div>
                      </button>
                    </div>
                  </div>
              }
            </>
            : null}

        </div>
      </div>
    </header>
  );
}

export default Header;