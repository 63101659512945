import "./ResultNumber.css";

function ResultNumber({ arr, rolling, rollingEnd, isWin, isLost, isFast }) {
  return (
    <div className="number">
      <div
        className={`number__container ${
          rolling ? "number__container_rolling" : ""
        } ${rollingEnd ? "number__container_stoping" : ""}`}
      >
        {arr.map((num, i) => (
          <span
            className={`number__text ${isWin ? "number__text_type_win" : ""} ${
              isLost ? "number__text_type_lost" : ""
            } ${isFast ? "number__text_type_fast" : ""}`}
            key={i}
          >
            {num}
          </span>
        ))}
      </div>
      <div className="number__border-ledge" />
      <div className="number__border-ledge number__border-ledge_type_down" />
    </div>
  );
}

export default ResultNumber;
