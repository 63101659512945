/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useSearchParams, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import {
  useCall,
  // useConnector,
  useTokenBalance,
  useEthers,
  useConfig,
  Mumbai,
  Polygon,
  Arbitrum
} from "@usedapp/core";
import Web3 from 'web3';
import { Magic } from 'magic-sdk';
import mainApi from "../../assets/api/MainApi";
import useWindowSize from "../../assets/hooks/useWindowSize";

import { ethers, utils } from "ethers";
// import { getWallet } from "../../assets/utilis/utilis";

import Header from "../Header/Header";
import CoinFlip from "../CoinFlip/CoinFlip";
import MegaDice from "../MegaDice/MegaDice";
import Limbo from "../Limbo/Limbo";
import ToastMsg from "../ToastMsg/ToastMsg";
import WalletModal from "../WalletModal/WalletModal";

import { Howl } from "howler";
import main_music_theme from "../../assets/sounds/beat_final.wav";

import {
  DEFAULT_CONSOLE_ADDRESS,
  ICONSOLE_ABI,
  // WALLET_CONNECTOR,
  DEFAULT_USDT_ADDRESS,
  INIT_DEMO_BALANCE,
  DEFAULT_WSS,
  USDT_CURRENCY,
  DEFAULT_FRONTEND_REFERRAL,
  DEMO_CURRENCY,
  DEFAULT_CHAIN_ID,
  DEMO_DECIMALS,
  IERC20_METADATA_ABI,
} from "../../assets/utilis/constants";
import {
  TOAST_LOST_ICON,
  TOAST_WON_ICON,
  TOAST_DECLINED_ICON,
  TOAST_MADE_ICON,
  TOAST_SENT_ICON,
  // TOAST_COPY_ICON,
} from "../../assets/utilis/icons";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Loading from "../Loading/Loading";
// import WalletPromo from "../WalletPromo/WalletPromo";
// import WalletChange from "../WalletChange/WalletChange";
import Crash from "../Crash/Crash";
import LuckySevens from "../LuckySevens/LuckySevens";
import Shuttle from "../Shuttle/Shuttle";
import LuckyWheel from "../LuckyWheel/LuckyWheel";
import Charger from "../Charger/Charger";
// import TechnicalWork from "../TechnicalWork/TechnicalWork";

// toast transitions
const SlideAndFade = cssTransition({
  enter: "slide-in-right",
  exit: "fade-out",
});

// gas per roll amount
const useGasPerRoll = async (consoleAddress, chainId) => {
  const { value, error } =
    useCall(
      consoleAddress && {
        contract: new ethers.Contract(
          consoleAddress,
          new utils.Interface(ICONSOLE_ABI)
        ),
        method: "getGasPerRoll",
      },
      { chainId }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  if (!value) {
    return undefined;
  }
  return value?.[0];
};

function App() {
  const {
    account,
    active,
    chainId,
    library,
    switchNetwork,
    activateBrowserWallet,
    deactivate,
  } = useEthers();
  const { networks, readOnlyUrls } = useConfig();

  // const { connector } = useConnector();

  const overlay = useRef();
  const toastMadeId = useRef(null);
  const toastSentId = useRef(null);
  const toastReduceBetId = useRef(null);
  const toastIncreaseBetId = useRef(null);

  const [isMusicOn, setMusicOn] = useState(false);
  const [isMusicPlaying, setMusicPlaying] = useState(false);
  const [musicId, setMusicId] = useState(0);
  const [music, setMusic] = useState(null);
  const [isSoundOn, setSoundOn] = useState(
    localStorage.getItem("isSoundOn") !== null
      ? JSON.parse(localStorage.getItem("isSoundOn"))
      : false
  );

  const [user, setUser] = useState(undefined);
  const [initAccount, setInitAccount] = useState(undefined);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [, setMenuOpen] = useState(false);
  // const [isMenuOpen, setMenuOpen] = useState(false);
  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const [, setConfirmPopupOpen] = useState(false);
  // const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const [isSupportedNetwork, setSupportedNetwork] = useState(false);
  const [preferredChainId, setPreferredChainId] = useState(DEFAULT_CHAIN_ID);
  const [subscribtions] = useState([]);
  // const [subscribtions, setSubscribtions] = useState([]);
  const [isGamePlaying, setGamePlaying] = useState(false);
  const [isBlocked, setBlocked] = useState(false);
  const [partnerReferralAddress, setPartnerReferralAddress] = useState(
    DEFAULT_FRONTEND_REFERRAL
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeAccountStateVar, setActiveAccountStateVar] = useState(undefined);
  const [isActive, setIsActive] = useState(false);
  const [isMagicConnectorStateVar, setIsMagicConnectorStateVar] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isPageLoadedInit, setIsPageLoadedInit] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const [baseNetworks, setBaseNetworks] = useState([]);
  const [tokenAddress, setTokenAddress] = useState(DEFAULT_USDT_ADDRESS);
  const [preferredToken, setPreferredToken] = useState(USDT_CURRENCY.toLowerCase());
  const [preferredTokenInit, setPreferredTokenInit] = useState(USDT_CURRENCY.toLowerCase());
  const [tokenDecimals, setTokenDecimals] = useState(DEMO_DECIMALS);
  const [consoleAddress, setConsoleAddress] = useState(DEFAULT_CONSOLE_ADDRESS);
  const [wss, setWSS] = useState(DEFAULT_WSS);
  const [demoBalance, setDemoBalance] = useState(INIT_DEMO_BALANCE);

  const [searchParams] = useSearchParams();

  const [isDemo, setIsDemo] = useState(false);
  const [isPrevDemo, setPrevDemo] = useState(false);
  const [demoCoinLogo, setDemoCoinLogo] = useState(undefined);
  const [isDemoSwitch,] = useState(true);  // const [isDemoSwitch,setDemoSwitch] = useState(true);
  const [returnUrl, setReturnUrl] = useState("");
  const [magicApiKey, setMagicApiKey] = useState(null);
  const [magic, setMagic] = useState(null);
  // const [partnerUrl, setPartnerUrl] = useState("");
  const [, setPartnerUrl] = useState("");
  // const [isExternalWallet, setIsExternalWallet] = useState(false)
  const [externalAccount, setExternalAccount] = useState(undefined)
  const [isExternalAccountConnection, setExternalAccountConnection] = useState(undefined)
  const [isExternalWalletConnected, setExternalWalletConnected] = useState(false);

  const gasPerRoll = useGasPerRoll(consoleAddress, preferredChainId);
  const balance = useTokenBalance(tokenAddress, externalAccount ? externalAccount : activeAccountStateVar, {
    refresh: "everyBlock",
    chainId: preferredChainId
  });

  const [txStatus, setTxStatus] = useState(null);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (isExternalAccountConnection) setSupportedNetwork(true);
    window.addEventListener('message', handleMessage);
  }, [isPageLoaded])

  function handleMessage(event) {
    event.stopPropagation();

    let parser = document.createElement('a');
    parser.href = document.referrer;

    if (event.origin === parser.origin) {
      try {
        let receivedData = JSON.parse(event.data);
        if (receivedData.type === "signMessage") {
          /// check signed message
          auth({ uuid: receivedData.payload.uuid, address: receivedData.payload.address, signature: receivedData.payload.signature });
        } else if (receivedData.type === "updateTxStatus") {
          /// update tx status
          setTxStatus(receivedData.payload.status);
        } else if (receivedData.type === "editData") {
          /// update frame config
          /// only changable if externalAccount
          if (isExternalAccountConnection) {
            /// edit isSupportedNetwork parameter
            if (receivedData.payload.set.isSupportedNetwork === true) {
              setSupportedNetwork(true);
            } else if (receivedData.payload.set.isSupportedNetwork === false) {
              setSupportedNetwork(false);
            }

            if (receivedData.payload.set.isExternalWalletConnected === true) {
              setExternalWalletConnected(true);
            } else if (receivedData.payload.set.isExternalWalletConnected === false) {
              setExternalWalletConnected(false);
            }

            /// edit externalAccount parameter
            if (receivedData.payload.set.externalAccount && utils.isAddress(receivedData.payload.set.externalAccount)) {
              setExternalAccount(receivedData.payload.set.externalAccount);
            }
          }

          /// edit preferredChainId parameter
          if (receivedData.payload.set.preferredChainId && networks.map((a) => a.chainId).includes(Number(receivedData.payload.set.preferredChainId))) {
            setPreferredChainId(Number(receivedData.payload.set.preferredChainId));
          }
        }
        console.log({ data: JSON.parse(event.data) })
      } catch {
        console.log('Some event listener err')
      }
    }
  }

  const sendMagicTx = async(txPayload) => {
    setTxStatus("Pending signature");
    const provider = new ethers.providers.Web3Provider(magic.rpcProvider);
    const signer = provider.getSigner();

    // Submit transaction to the blockchain
    await signer
      .sendTransaction(txPayload)
      .then((tx) => {
        setTxStatus("Mining");
        provider.once(tx.hash, (receipt) => {
          setTxStatus("Success");
        });
      })
      .catch((err) => {
        setTxStatus("Fail");
      });
  }

  function sendToParent(eventType, eventPayload) {
    window.parent.postMessage(JSON.stringify({ type: eventType, payload: eventPayload }), '*')
  }

  // close side bar on overlay click
  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    mainApi
      .networksAvailable()
      .then((res) => {
        setBaseNetworks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (isActive && activeAccountStateVar && !externalAccount && !initAccount) setInitAccount(activeAccountStateVar)
    else if (externalAccount) setInitAccount(externalAccount);
  }, [isActive, externalAccount, activeAccountStateVar, isPageLoaded]);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (externalAccount) getUser();
  }, [externalAccount, isPageLoaded]);


  useEffect(() => {
    if (!isPageLoaded) return;
    if (activeAccountStateVar && !externalAccount) getUser();
  }, [activeAccountStateVar, isPageLoaded]);

  function isMagicConnector() {
    return localStorage.getItem('magic:is_authenticated') === 'true' ? true : false;
  }

  function setIsMagicConnector(value) {
    if (!value) {
      localStorage.removeItem('magic:is_authenticated');
    } else {
      localStorage.setItem('magic:is_authenticated', value);
    }
  }

  function activeAccount() {
    return localStorage.getItem('active_account') ?? '';
  }

  function setActiveAccount(value) {
    if (!value) {
      localStorage.removeItem('active_account');
    } else {
      localStorage.setItem('active_account', value);
    }
  }

  useEffect(() => {
    if (!isPageLoaded || isMagicConnectorStateVar) return;
    console.log(isPageLoaded, isMagicConnectorStateVar)
    setActiveAccountStateVar(account);
  }, [isMagicConnectorStateVar, account])

  useEffect(() => {
    if (!isPageLoaded) return;
    setIsActive(active);
  }, [active])

  useEffect(() => {
    if (!isPageLoaded) return;
    setIsMagicConnector(isMagicConnectorStateVar)
  }, [isMagicConnectorStateVar])

  useEffect(() => {
    if (!isPageLoaded) return;
    setActiveAccount(activeAccountStateVar)
  }, [activeAccountStateVar])

  useEffect(() => {
    if (!isPageLoadedInit) return;

    let magic_;
    if (magicApiKey) {

      magic_ = new Magic(magicApiKey, {
        network: {
          rpcUrl: {
            [Mumbai.chainId]: "https://rpc.ankr.com/polygon_mumbai",
            [Arbitrum.chainId]: "https://arb1.arbitrum.io/rpc",
            [Polygon.chainId]: "https://rpc.ankr.com/polygon",
          }[preferredChainId],
          chainId: preferredChainId,
        }
      })

      setMagic(magic_)
    }

    if (isMagicConnector()) {
      setMagicAccount(magic_, true);
    } else setIsPageLoaded(true)

  }, [isPageLoadedInit])

  const setMagicAccount = async (magic_, setPageLoaded) => {
    const accounts = await (magic ? magic: magic_).wallet.connectWithUI();
    if (accounts) {
      setActiveAccountStateVar(accounts[0]);
      setIsActive(true);
      setIsMagicConnectorStateVar(true);
      setSupportedNetwork(true);
    }
    if (setPageLoaded) setIsPageLoaded(true);
  }

  const activateBrowserWalletMain = async (type, isMagic) => {
    if (!isMagic) {
      activateBrowserWallet({ type });
    } else {
      try {
        await setMagicAccount();
      } catch (err) {
        console.log(err)
      }
    }
  }

  // useEffect(() => {
  //   if (window.location.pathname === '/wallet-change') return
  //   if (account && !externalAccount && isPageLoaded) switchNetwork(preferredChainId);
  // }, [account, externalAccount, isPageLoaded]);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (
      isLoaded &&
      isSupportedNetwork &&
      !activeAccountStateVar &&
      chainId
    ) {
      refreshState();
    }
  }, [isLoaded, activeAccountStateVar, isSupportedNetwork, chainId, isPageLoaded]);

  const updateDecimals = async (tokenAddress) => {
    const signer = new ethers.providers.JsonRpcProvider(readOnlyUrls[preferredChainId]);
    const ctr = new ethers.Contract(tokenAddress, IERC20_METADATA_ABI, signer);
    setTokenDecimals(await ctr.decimals());
  };

  function updateBaseData(baseNetworks, chainId) {
    let baseNetworksFiltered = baseNetworks.filter(
      (item) => item.chain_id === chainId
    );
    if (baseNetworksFiltered.length === 1) {
      setConsoleAddress(baseNetworksFiltered[0].addresses.console_address);
      let _preferredToken = preferredToken;
      if (baseNetworksFiltered[0].tokens[preferredTokenInit]) {
        _preferredToken = preferredTokenInit;
        setPreferredToken(_preferredToken)
      }
      setTokenAddress(baseNetworksFiltered[0].tokens[_preferredToken]);
      setWSS(baseNetworksFiltered[0].wss);
      updateDecimals(baseNetworksFiltered[0].tokens[_preferredToken]);
    }
  }

  // track network change
  useEffect(() => {
    if (!isPageLoaded) return;
    if (chainId) checkNetwork();
  }, [chainId, isPageLoaded]);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (baseNetworks.length !== 0) {
      updateBaseData(baseNetworks, preferredChainId);
    } else {
      mainApi
        .networksAvailable()
        .then((res) => {
          setBaseNetworks(res.data);
          updateBaseData(res.data, preferredChainId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [preferredChainId, isPageLoaded])

  // listen for balance changes
  useEffect(() => {
    if (balance === undefined || !user) return;

    const newBalance = balance;
    if (isGamePlaying) {
      if (newBalance.lt(user.balance))
        setUser((prevUser) => ({ ...prevUser, balance: newBalance }));
    } else {
      if (!newBalance.eq(user.balance))
        setUser((prevUser) => ({ ...prevUser, balance: newBalance }));
    }

  }, [balance, isGamePlaying]);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (!externalAccount && !activeAccountStateVar) return;
    if (subscribtions.length !== 0) {
      for (var i = 0; i < subscribtions.length; i++) {
        subscribtions[i].unsubscribe(function (error, success) {
          if (success) {
            console.log("Successfully unsubscribed!");
          }
        });
      }
    }
  }, [externalAccount, activeAccountStateVar, isPageLoaded]);

  // requests to api
  const getUser = () => {
    mainApi
      .getUser()
      .then((res) => {
        if ((isExternalAccountConnection && res.address !== externalAccount && externalAccount) || (!isExternalAccountConnection && isActive && activeAccountStateVar && res.address !== activeAccountStateVar)) {
          refreshState();
          getUuid();
        } else if (!(isExternalAccountConnection && !externalAccount)) {
          setUser({ ...res, balance: ethers.BigNumber.from(0) });
          if (isDemo) setIsDemo(false)
          setLoggedIn(true);
        }
        setIsLoaded(true);
        // console.log("get user:", res);
      })
      .catch((err) => {
        // console.log("get user err:", err);
        // const wallet = getWallet(connector);
        // console.log("connector:", wallet);
        // console.log("active:", active);
        if ((isExternalAccountConnection && isExternalWalletConnected) || (!isExternalAccountConnection && isActive)) {
          // if (wallet === "WALLET_CONNECTOR") {
          //   setConfirmPopupOpen(true);
          // } else {
          getUuid();
          // }
        } else {
          setLoggedIn(false);
        }
        setIsLoaded(true);
      });
  };

  const getUuid = () => {
    setConfirmPopupOpen(false);
    mainApi
      .getNewUuid(externalAccount ? externalAccount : activeAccountStateVar)
      .then((res) => {
        // console.log("get new uuid:", res);
        if (externalAccount) {
          sendToParent("signMessage", { "message": res.message, uuid: res.uuid, address: externalAccount })
          // do smth to send data to parent and then receive to finish onboarding
        } else {
          signAuthMessage(res.message)
            .then((res1) => {
              // console.log("sign auth message:", res1);
              auth({ uuid: res.uuid, address: externalAccount ? externalAccount : activeAccountStateVar, signature: res1 });
            })
            .catch((err) => console.log("sign auth message err:", err));
        }
      })
      .catch((err) => console.log("get new uuid err:", err));
  };

  const auth = ({ uuid, address, signature }) => {
    mainApi
      .auth({ uuid, address, signature })
      .then((res) => {
        // console.log("auth:", res.data);
        setUser({ address, balance: ethers.BigNumber.from(0) });
        if (isDemo) setIsDemo(false)
        setLoggedIn(true);
      })
      .catch((err) => console.log("auth err:", err));
  };

  const checkNetwork = async () => {
    if (isMagicConnector()) return;
    setSupportedNetwork(chainId === preferredChainId);
  };

  const signAuthMessage = async (message) => {  
    let signature;
    if (!isMagicConnector() && !library) {
      return;
    } else if (!isMagicConnector()) {
      console.log(library.getSigner(), message)
      signature = library.getSigner().signMessage(message);
    } else {
      const provider = await magic.wallet.getProvider();
      const magicWeb3 = new Web3(provider);
      signature = await magicWeb3.eth.personal.sign(
        message,
        activeAccount(),
        ""
      );
    }
    return signature;	
  };

  // logout
  const refreshState = () => {
    mainApi
      .logout()
      .then(() => {
        setLoggedIn(false);
        setUser(undefined);
      })
      .catch((err) => console.log("disconnect err:", err));
  };

  const disconnect = async() => {
    if (!isMagicConnectorStateVar) {
      deactivate();
    } else {
      await magic.wallet.disconnect();
      setIsMagicConnectorStateVar(false);
    }
    setActiveAccountStateVar(undefined);
    setIsActive(false);
    refreshState();
  };

  // notifications
  const displayLostMsg = (isFast) => {
    toast(
      <ToastMsg
        startMsg="Unfortunately, you"
        endMsg="your last bet"
        span="lost"
        text="Try again!"
      />,
      { icon: TOAST_LOST_ICON, autoClose: isFast ? 2000 : true }
    );
  };

  const displayWonMsg = (win, isFast) => {
    toast(
      <ToastMsg msg="You won!" win={win} text="It will be deposited shortly" />,
      { icon: TOAST_WON_ICON, autoClose: isFast ? 2000 : true }
    );
  };

  const displayDeclinedMsg = () => {
    toast(<ToastMsg msg="Transaction is declined, please try again" />, {
      icon: TOAST_DECLINED_ICON,
      autoClose: true,
    });
  };

  const displayTxDoneMsg = () => {
    toast(<ToastMsg msg="Transaction is done!" />, {
      icon: TOAST_MADE_ICON,
      autoClose: true,
    });
  };

  const displayMadeMsg = () =>
  (toastMadeId.current = toast(
    <ToastMsg msg="Your bet is made. Please approve it in your wallet" />,
    { icon: TOAST_MADE_ICON, autoClose: false, closeOnClick: false }
  ));

  const dismissMadeMsg = () => toast.dismiss(toastMadeId.current);

  const displaySentMsg = () =>
  (toastSentId.current = toast(
    <ToastMsg msg="Transaction is sent, please wait for approval" />,
    {
      icon: TOAST_SENT_ICON,
      autoClose: false,
      closeOnClick: false,
    }
  ));

  const dismissSentMsg = () => toast.dismiss(toastSentId.current);

  // const displayCopyMsg = () => {
  //   toast(<ToastMsg msg="Copied to clipboard" />, {
  //     icon: TOAST_COPY_ICON,
  //     className: "toast_type_copy",
  //     autoClose: true,
  //   });
  // };

  const displayIncreaseBetMsg = (minBet, isDemo) =>
  (toastIncreaseBetId.current = toast(
    <ToastMsg
      msg={`Min amount is ${minBet} ${!isDemo ? preferredToken.toUpperCase() : DEMO_CURRENCY
        }. Please, increase your bet`}
    />,
    {
      autoClose: false,
      closeOnClick: false,
    }
  ));

  const dismissIncreaseBetMsg = () => toast.dismiss(toastIncreaseBetId.current);

  const displayReduceBetMsg = (maxBet, isDemo) =>
  (toastReduceBetId.current = toast(
    <ToastMsg
      msg={`Max bet is ${maxBet} ${!isDemo ? preferredToken.toUpperCase() : DEMO_CURRENCY
        }. Please, reduce your bet`}
    />,
    {
      autoClose: false,
      closeOnClick: false,
    }
  ));

  const dismissReduceBetMsg = () => toast.dismiss(toastReduceBetId.current);

  // track location for toast position
  const isGamePage = useState(true);

  // toggle side bar state
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > 1150) setMenuOpen(false);
  }, [width]);

  // // toggle side bar state
  // function toggleMenu() {
  //   setMenuOpen(!isMenuOpen);
  // }

  // toggle wallet modal visibility state
  function openWalletModal() {
    if (!isDemo && isExternalAccountConnection && (!user || !isExternalWalletConnected)) {
      if (isExternalWalletConnected) {
        getUser();
      } else {
        sendToParent("requestToWalletConnect", {})
      }
    } else {
      setWalletModalOpen(true);
    }
  }

  function closeWalletModal() {
    setWalletModalOpen(false);
  }

  // toggle sounds state
  function toggleSound() {
    let newValue = !isSoundOn;
    setSoundOn(newValue);
    localStorage.setItem("isSoundOn", newValue);
  }

  // set and control music
  useEffect(() => {
    const audio = new Howl({
      src: [main_music_theme],
      loop: true,
      volume: 0.3,
      onplay: function (id) {
        setMusicId(id);
        setMusicPlaying(true);
      },
      onfade: function () {
        setMusicPlaying(false);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on id ${id}:`, error);
      },
    });

    setMusic(audio);
  }, []);

  const toggleMusic = () => {
    if (music === null) return;

    setMusicOn(!isMusicOn);
    if (!isMusicOn && !isMusicPlaying) {
      music.play();
      setMusicPlaying(true);
    } else {
      music.fade(0.3, 0, 500, musicId);
    }
  };

  const playGameSound = (
    audio,
    isAudioPlaying,
    isGameOn,
    isFast = false,
    speed = 1
  ) => {
    if (audio === null) return;

    if (isSoundOn && !isAudioPlaying && isGameOn) {
      audio.play();
      audio.mute(false);
      isFast ? audio.rate(speed) : audio.rate(1);
    }
  };

  const fadeGameAudio = (audio, audioId) => {
    if (audio === null) return;

    audio.fade(1, 0, 300, audioId);
  };

  // const stopGameAudio = (audio, audioId) => {
  //   if (audio === null) return;

  //   audio.stop(audioId);
  // };

  const playMuteGameAudio = (audio, isFast = false, speed = 1) => {
    if (audio === null) return;

    audio.play();
    audio.mute(true);
    isFast ? audio.rate(speed) : audio.rate(1);
  };

  const muteGameAudio = (audio, audioId) => {
    if (audio === null) return;

    audio.mute(true, audioId);
  };

  const unmuteGameAudio = (audio, audioId) => {
    if (audio === null) return;

    audio.mute(false, audioId);
  };

  useEffect(() => {
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      let root = document.documentElement;
      console.log(param, value);

      switch (param) {
        case "--bg-color":
        case "--text-color":
        case "--contrast-color":
        case "--contrast-color-opacity":
        case "--text-on-contrast-color":
        case "--win-color":
        case "--lose-color":
        case "--border-color":
        case "--container-bg-color":
        case "--container-secondary-bg-color":
        case "--container-contrast-bg-color":
          root.style.setProperty(param, value);
          break;

        // case "is_demo":
        //   try {
        //     setIsDemo(JSON.parse(value));
        //     setPrevDemo(JSON.parse(value));
        //   } catch (err) {}
        //   break;

        case "chain_id":
          if (networks.map((a) => a.chainId).includes(Number(value))) {
            setPreferredChainId(Number(value));
            setSupportedNetwork(chainId === Number(value));
          }
          break;

        case "token":
          setPreferredTokenInit(value);
          break;
        
        case "magic_public_api_key":
          setMagicApiKey(value);
          break

        case "demo_coin_logo_link":
          setDemoCoinLogo(value);
          break;

        case "is_external_account_connection":
          try {
            if (JSON.parse(value) === true) {
              setExternalAccountConnection(true);
            }
          } catch (err) { }
          break;

        // case "show_demo_switch":
        //   try {
        //     setDemoSwitch(JSON.parse(value));
        //   } catch (err) {}
        //   break;

        case "partner_url":
          setPartnerUrl(value);
          break;

        case "partner_referral_address":
          if (ethers.utils.isAddress(value)) {
            setPartnerReferralAddress(value);
          }
          break;

        case "return_url":
          setReturnUrl(value);
          break;

        default:
          break;
      }
    }
    setIsPageLoadedInit(true);
  }, [searchParams]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  function handleSwitchDemo() {
    setIsDemo((prevValue) => !prevValue);
  }

  return (
    <>
      <div className="app">
        {
          // window.location.pathname === "/wallet-change" ? (
          //   <div className="app__content">
          //     <Routes>
          //       <Route path="/wallet-change" element={<WalletChange {...{ account, active, initAccount }} />} />
          //     </Routes>
          //   </div>
          // ) : 
          (
            <>
              {isLoading ? (
                <Loading />
              ) : (
                <div className="app__content">
                  {/* {
                  !isDemo && isExternalAccountConnection && (!user || !isExternalWalletConnected) ? (
                    <WalletPromo onModalOpen={openWalletModal} />
                  ) : null
                  } */}
                  {/* {
                  !isDemo && preferredChainId === Polygon.chainId ? (
                    <TechnicalWork />
                  ) : null
                  } */}
                  <Header
                    onModalOpen={openWalletModal}
                    isGamePlaying={isGamePlaying}
                    isExternalAccount={isExternalAccountConnection}
                    active={isActive}
                    account={activeAccountStateVar}
                    chainId={chainId}
                    {...{
                      preferredToken,
                      isMagicConnectorStateVar,
                      isLoggedIn,
                      isSoundOn,
                      toggleSound,
                      isMusicOn,
                      toggleMusic,
                      disconnect,
                      user,
                      demoBalance,
                      setDemoBalance,
                      demoCoinLogo,
                      setIsDemo,
                      isDemo,
                      isDemoSwitch,
                      handleSwitchDemo,
                      isBlocked,
                      tokenDecimals,
                      returnUrl,
                      preferredChainId,
                      isExternalWalletConnected
                    }}
                  />
                  <Routes>
                    <Route
                      path="/coinflip"
                      element={
                        <CoinFlip
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/megadice"
                      element={
                        <MegaDice
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/limbo"
                      element={
                        <Limbo
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/crash"
                      element={
                        <Crash
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/lucky-sevens"
                      element={
                        <LuckySevens
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/shuttle"
                      element={
                        <Shuttle
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/lucky-wheel"
                      element={
                        <LuckyWheel
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route
                      path="/charger"
                      element={
                        <Charger
                          stopGameAudio={fadeGameAudio}
                          isGamePlaying={isGamePlaying}
                          setGamePlaying={setGamePlaying}
                          account={activeAccountStateVar}
                          active={isActive}
                          {...{
                            preferredToken,
                            sendMagicTx,
                            consoleAddress,
                            wss,
                            isPrevDemo,
                            setPrevDemo,
                            playGameSound,
                            muteGameAudio,
                            unmuteGameAudio,
                            playMuteGameAudio,
                            fadeGameAudio,
                            isSoundOn,
                            displayLostMsg,
                            displayWonMsg,
                            displayDeclinedMsg,
                            displayMadeMsg,
                            displaySentMsg,
                            displayTxDoneMsg,
                            displayReduceBetMsg,
                            displayIncreaseBetMsg,
                            dismissMadeMsg,
                            dismissSentMsg,
                            dismissReduceBetMsg,
                            dismissIncreaseBetMsg,
                            user,
                            switchNetwork,
                            isSupportedNetwork,
                            demoBalance,
                            setDemoBalance,
                            gasPerRoll,
                            isDemo,
                            demoCoinLogo,
                            partnerReferralAddress,
                            tokenAddress,
                            isBlocked,
                            setBlocked,
                            preferredChainId,
                            tokenDecimals,
                            searchParams,
                            isDemoSwitch,
                            openWalletModal,
                            externalAccount,
                            sendToParent,
                            txStatus,
                            isExternalWalletConnected,
                            isExternalAccountConnection,
                            isMagicConnectorStateVar
                          }}
                        />
                      }
                    />
                    <Route path="*" element={<Navigate to="/megadice" />} />
                  </Routes>
                  <WalletModal
                    isOpen={isWalletModalOpen}
                    onClose={closeWalletModal}
                    activateBrowserWallet={activateBrowserWalletMain}
                    {
                      ...{magic}
                    }
                  />
                  <ToastContainer
                    className={`app_toast-container ${isGamePage ? "app_toast-container_type_game" : ""
                      }`}
                    position="top-right"
                    autoClose={5000}
                    closeButton={false}
                    transition={SlideAndFade}
                    theme="dark"
                    closeOnClick={true}
                    limit={6}
                  />
                </div>
              )}
            </>
          )}
      </div>
    </>
  );
}

export default App;
