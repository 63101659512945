import { useState, useEffect } from "react";
import moment from "moment";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

// coin-flip images
import startBlue from "../../../assets/images/coin-flip/start-blue-coin.png";
import startYellow from "../../../assets/images/coin-flip/start-yellow-coin.png";
import continueSpinning from "../../../assets/images/coin-flip/continue-animation.png";
import stopBlue from "../../../assets/images/coin-flip/stop-blue-coin.png";
import stopYellow from "../../../assets/images/coin-flip/stop-yellow-coin.png";
import flipBlue from "../../../assets/images/coin-flip/fast-flip-blue-to-yellow.png";
import flipYellow from "../../../assets/images/coin-flip/fast-flip-yellow-to-blue.png";
import singleBlueCoin from "../../../assets/images/coin-flip/blue-single-coin.png";
import singleYellowCoin from "../../../assets/images/coin-flip/yellow-single-coin.png";

import "./CoinBox.css";

function CoinBox({
  isFliping,
  isStopFliping,
  isBlueTop,
  isBlueWin,
  isBlue,
  isLoaded,
  totalCoins,
  isFastFlip,
  isAnimEnd,
  isReturnAnim,
  isToggleAnim,
  index,
  isAuto,
  time,
}) {
  const [isContinue, setContinue] = useState(false);
  const [isSideChange, setSideChange] = useState(false);
  const [isOpacity, setOpacity] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [isTop, setIsTop] = useState(false);
  const images = {
    singleBlueCoin: singleBlueCoin,
    singleYellowCoin: singleYellowCoin,
    startBlue: startBlue,
    startYellow: startYellow,
    continueSpinning: continueSpinning,
    stopBlue: stopBlue,
    stopYellow: stopYellow,
    flipBlue: flipBlue,
    flipYellow: flipYellow,
  }

  useEffect(() => {
    if (!Boolean(time) || !isFliping || isContinue) return;

    const now = moment();
    const timer = setInterval(() => {
      if (Math.abs(time.diff(now)) >= 3970 && isFliping && !isContinue) {
        const diff = Math.abs(time.diff(now)) % 50;
        if ((diff <= 40 || 4000 - diff <= 40) && !isFastFlip) setContinue(true);
      }

      setTimerValue(timerValue + 1);
      clearInterval(timer);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValue, time]);

  useEffect(() => {
    setContinue(false);
  }, [isFliping, isStopFliping]);

  useEffect(() => {
    if (isAuto) return;

    setSideChange(true);
    setIsTop(isBlueTop);
    setTimeout(() => {
      setSideChange(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlueTop]);

  useEffect(() => {
    setSideChange(false);
  }, []);

  useEffect(() => {
    if (index + 1 === totalCoins) setSideChange(false);
  }, [totalCoins, index]);

  useEffect(() => {
    if (!isAnimEnd) return;

    setOpacity(true);
    setTimeout(() => {
      setOpacity(false);
    }, 700);
  }, [isAnimEnd]);

  return (
    <>
      {isLoaded ? (
        <div
          className={`coin ${totalCoins === 2 ? "coin_type_double" : ""} ${
            totalCoins === 3 ? "coin_type_triple" : ""
          } ${totalCoins === 4 ? "coin_type_quadruple" : ""}
          ${totalCoins === 5 ? "coin_type_quintuple" : ""} ${
            totalCoins > 5 ? "coin_type_multiple" : ""
          }`}
        >
          <img
            className={`coin__single-image ${
              (!isFliping &&
                !isContinue &&
                !isStopFliping &&
                !isAnimEnd &&
                !isReturnAnim &&
                !isToggleAnim &&
                !isSideChange &&
                isTop) ||
              (isReturnAnim && isBlueWin && isTop)
                ? "coin__single-image_visible"
                : ""
            }`}
            src={images.singleBlueCoin}
            alt=""
            key="start-blue-single"
          />

          <img
            className={`coin__single-image ${
              (!isFliping &&
                !isContinue &&
                !isStopFliping &&
                !isAnimEnd &&
                !isReturnAnim &&
                !isToggleAnim &&
                !isSideChange &&
                !isTop) ||
              (isReturnAnim && !isBlueWin && !isTop)
                ? "coin__single-image_visible"
                : ""
            }`}
            src={images.singleYellowCoin}
            alt=""
            key="start-yellow-single"
          />

          <img
            className={`coin__animation coin__animation_type_start ${
              isFliping && !isContinue && isBlueTop
                ? "coin__animation_visible"
                : ""
            } ${isFastFlip ? "coin__animation_type_fast" : ""}`}
            src={images.startBlue}
            alt=""
            key="start-blue"
          />

          <img
            className={`coin__animation coin__animation_type_start ${
              isFliping && !isContinue && !isBlueTop
                ? "coin__animation_visible"
                : ""
            } ${isFastFlip ? "coin__animation_type_fast" : ""}`}
            src={images.startYellow}
            alt=""
            key="start-yellow"
          />

          <img
            className={`coin__animation coin__animation_type_continue ${
              isContinue && !isStopFliping ? "coin__animation_visible" : ""
            }`}
            src={images.continueSpinning}
            alt=""
            key="continue"
          />

          <img
            className={`coin__animation coin__animation_type_stop ${
              isStopFliping && isBlueWin ? "coin__animation_visible" : ""
            } ${isFastFlip ? "coin__animation_type_fast" : ""}`}
            src={images.stopBlue}
            alt=""
            key="stop-blue"
          />

          <img
            className={`coin__animation coin__animation_type_stop ${
              isStopFliping && !isBlueWin ? "coin__animation_visible" : ""
            } ${isFastFlip ? "coin__animation_type_fast" : ""}`}
            src={images.stopYellow}
            alt=""
            key="stop-yellow"
          />

          <img
            className={`coin__single-image ${
              isAnimEnd && isBlueWin && isBlue
                ? "coin__single-image_visible"
                : ""
            } ${
              isAnimEnd && isBlueWin && !isBlue && isOpacity
                ? "coin__single-image_type_to-opacity"
                : ""
            } ${
              isAnimEnd && isBlueWin && !isBlue && !isOpacity
                ? "coin__single-image_type_from-opacity"
                : ""
            }`}
            src={images.singleBlueCoin}
            alt=""
            key="stop-blue-single"
          />

          <img
            className={`coin__single-image ${
              isAnimEnd && !isBlueWin && !isBlue
                ? "coin__single-image_visible"
                : ""
            } ${
              isAnimEnd && !isBlueWin && isBlue && isOpacity
                ? "coin__single-image_type_to-opacity"
                : ""
            } ${
              isAnimEnd && !isBlueWin && isBlue && !isOpacity
                ? "coin__single-image_type_from-opacity"
                : ""
            }`}
            src={images.singleYellowCoin}
            alt=""
            key="stop-yellow-single"
          />

          <img
            className={`coin__animation coin__animation_type_flip ${
              (isReturnAnim && isBlueWin && !isBlueTop) ||
              (isToggleAnim && !isBlueTop) ||
              (isSideChange && !isBlueTop)
                ? "coin__animation_visible"
                : ""
            }`}
            src={images.flipBlue}
            alt=""
            key="flip-blue-to-yellow"
          />

          <img
            className={`coin__animation coin__animation_type_flip ${
              (isReturnAnim && !isBlueWin && isBlueTop) ||
              (isToggleAnim && isBlueTop) ||
              (isSideChange && isBlueTop)
                ? "coin__animation_visible"
                : ""
            }`}
            src={images.flipYellow}
            alt=""
            key="flip-yellow-to-blue"
          />
        </div>
      ) : (
        <MiniPreloader />
      )}
    </>
  );
}

export default CoinBox;
