/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import {
  useContractFunction,
  useTokenAllowance,
  ERC20Interface,
  useCall,
} from "@usedapp/core";
import { ethers } from "ethers";
import {
  bigNumberMin,
  deepEqual,
  // factorial,
  getFee,
  prettyValue,
} from "../../assets/utilis/utilis";
// import moment from "moment";
import CrashGame from "./CrashGame/CrashGame";
import GameOptions from "../GameOptions/GameOptions";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { Howl } from "howler";
import bet_buttons_sound from "../../assets/sounds/bet_buttons_sound.wav";
import {
  START_MULTIPLIER_NUMBER,
  MIN_CRUSH_BET,
  MIN_DEMO_BET_NUMBER,
  MAX_DEMO_BET_NUMBER,
  DEMO_MIN_BET_VALUE,
  MIN_AUTOPLAY_NUMBER,
  IGAME_ABI,
  DEMO_CURRENCY,
  NULL_ADDRESS,
  MAX_BIG_NUMBER,
  CRASH_BET_STEP_NUMBER,
  MAX_CRUSH_BET,
  MIN_CRUSH_VALUE,
  DEMO_DECIMALS,
  LIMBO_ID,
  ICONSOLE_ABI,
  LIMBO_GAME,
} from "../../assets/utilis/constants";

import Web3 from "web3";

import { parseGameResultLimbo } from "../../assets/utilis/utilis";

import "./Limbo.css";

function calculateMaxBet(maxPayout, crashBet, fee) {
  return maxPayout
    .mul(10000)
    .div(10000 - fee)
    .mul(100)
    .div(Number((crashBet * 100).toFixed(0)));
}

// get limbo
const useLimboGame = async (consoleAddress, tokenAddress, chainId) => {
  const { value, error } =
    useCall(
      consoleAddress && {
        contract: new ethers.Contract(consoleAddress, ICONSOLE_ABI),
        method: "getGameWithExtraData",
        args: [LIMBO_ID, tokenAddress],
      },
      { chainId }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  } else if (!value) {
    return undefined;
  }
  return {
    game: {
      id: value?.[0][0].id,
      paused: value?.[0][0].paused,
      name: value?.[0][0].name,
      date: value?.[0][0].date,
      impl: value?.[0][0].impl,
    },
    vault: value?.[0].vault,
    token: value?.[0].token,
    minWager: value?.[0].minWager,
    maxPayout: value?.[0].maxPayout,
    maxReservedAmount: value?.[0].maxReservedAmount,
    gameVault: {
      gameFee: {
        currentFee: value?.[0][6][0].currentFee,
        nextFee: value?.[0][6][0].nextFee,
        startTime: value?.[0][6][0].startTime,
      },
      isPresent: value?.[0][6].isPresent,
    },
  };
};

function Limbo({
  consoleAddress,
  wss,
  isPrevDemo,
  setPrevDemo,
  isGamePlaying,
  setGamePlaying,
  isSoundOn,
  displayLostMsg,
  displayWonMsg,
  displayDeclinedMsg,
  displayMadeMsg,
  displaySentMsg,
  displayTxDoneMsg,
  displayReduceBetMsg,
  displayIncreaseBetMsg,
  dismissMadeMsg,
  dismissSentMsg,
  dismissReduceBetMsg,
  dismissIncreaseBetMsg,
  user,
  switchNetwork,
  isSupportedNetwork,
  demoBalance,
  setDemoBalance,
  gasPerRoll,
  isDemo,
  demoCoinLogo,
  partnerReferralAddress,
  tokenAddress,
  isBlocked,
  setBlocked,
  preferredChainId,
  tokenDecimals,
  searchParams,
  isDemoSwitch,
  openWalletModal,
  externalAccount,
  account,
  active,
  sendToParent,
  txStatus,
  isExternalWalletConnected,
  isExternalAccountConnection,
  sendMagicTx,
  isMagicConnectorStateVar,
  preferredToken
}) {
  const animationRef = useRef();
  const [multiplier, setMultiplier] = useState(START_MULTIPLIER_NUMBER);
  const [crashBet, setCrashBet] = useState(MIN_CRUSH_BET.toFixed(2));
  const [isGameOn, setIsGameOn] = useState(false);
  const [minBet, setMinBet] = useState(ethers.BigNumber.from(0));
  const [maxBet, setMaxBet] = useState(ethers.BigNumber.from(0));
  const [checkBet, setCheckBet] = useState(true);
  const [betValue, setBetValue] = useState(DEMO_MIN_BET_VALUE);
  const [betValueWriten, setBetValueWriten] = useState(
    prettyValue(betValue, DEMO_DECIMALS)
  );
  const isAutoplay = true;
  const [autoplayAmount, setAutoplayAmount] = useState(MIN_AUTOPLAY_NUMBER);
  const [payout, setPayout] = useState(0);
  const [chance, setChance] = useState(0);
  const [isWin, setWin] = useState(false);
  const [isLost, setLost] = useState(false);
  const [isResult, setResult] = useState(null);
  const [isPreGameAnim, setPreGameAnim] = useState(false);
  const [count, setCount] = useState(1);
  // const [isFastFlip, setFastFlip] = useState(false);
  // const [isAuto, setAuto] = useState(false);
  const [isMaxBet, setIsMaxBet] = useState(false);
  const [isMinBet, setIsMinBet] = useState(false);
  const [isSufficientAllowance, setIsSufficientAllowance] = useState(false);
  const [gameAddress, setGameAddress] = useState(NULL_ADDRESS);
  const [vaultAddress, setVaultAddress] = useState(NULL_ADDRESS);

  const limboGame = useLimboGame(consoleAddress, tokenAddress, preferredChainId);
  const [limboGameObject, setLimboGameObject] = useState(null);
  const [limboAddress, setLimboAddress] = useState(null);
  const [txTypeInProgress, setTxTypeInProgress] = useState(null);

  // track games
  const [gameQueue, setGameQueue] = useState([]);
  const [gameQueueInit, setGameQueueInit] = useState([]);
  const [gameQueueInitOffset, setGameQueueInitOffset] = useState(0);

  const buttonsAudio = new Howl({
    src: [bet_buttons_sound],
  });

  const allowance = useTokenAllowance(
    tokenAddress,
    externalAccount ? externalAccount : account,
    vaultAddress,
    {
      refresh: "everyBlock",
      chainId: preferredChainId

    });

  const playFunction = useContractFunction(
    new ethers.Contract(gameAddress, new ethers.utils.Interface(IGAME_ABI)),
    "play"
  );
  const playFunctionState = playFunction.state;
  const playFunctionSend = playFunction.send;

  const tokenApproveFunction = useContractFunction(
    new ethers.Contract(tokenAddress, ERC20Interface),
    "approve"
  );
  const tokenApproveFunctionState = tokenApproveFunction.state;
  const tokenApproveFunctionSend = tokenApproveFunction.send;

  useEffect(() => {
    console.log(betValue);

    if (isDemo === isPrevDemo) return;

    if (isDemo) {
      if (DEMO_DECIMALS > tokenDecimals) {
        console.log(
          ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
        );
        setBetValue(
          betValue.mul(
            ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
          )
        );
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(
          betValue.div(
            ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)
          )
        );
      }
    } else {
      if (DEMO_DECIMALS > tokenDecimals) {
        setBetValue(
          betValue.div(
            ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
          )
        );
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(
          betValue.mul(
            ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)
          )
        );
      }
    }
    setPrevDemo(isDemo);
  }, [isDemo]);

  useEffect(() => {
    if (isPrevDemo || tokenDecimals === DEMO_DECIMALS) return;

    if (DEMO_DECIMALS > tokenDecimals) {
      setBetValue(
        betValue.div(
          ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
        )
      );
    } else if (DEMO_DECIMALS < tokenDecimals) {
      setBetValue(
        betValue.mul(
          ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)
        )
      );
    }
  }, [tokenDecimals]);

  // change chance and payout
  useEffect(() => {
    if (!crashBet) return;
    updateChanceAndPayout(crashBet);
  }, [crashBet]);

  const updateChanceAndPayout = async (crashBet) => {
    let chance;
    // chance

    let payout = Number(crashBet);
    if (limboGameObject) {
      chance =
        (10000 - getFee(limboGameObject.gameVault.gameFee)) /
        (crashBet * 10000);
    } else {
      chance = (10000 - 300) / (crashBet * 10000);
    }

    setChance((chance * 100).toFixed(2));
    setPayout(payout.toFixed(2));
  };

  useEffect(() => {
    if (user && (active || externalAccount) && !isPrevDemo) {
      updateMinBet(betValue);
      updateMaxPayout(crashBet, autoplayAmount, betValue); // also max bet
      updateGameData();
    } else if (isPrevDemo) {
      setMinBet(MIN_DEMO_BET_NUMBER);
      setIsMinBet(betValue.lt(MIN_DEMO_BET_NUMBER));
      setMaxBet(MAX_DEMO_BET_NUMBER);
      setIsMaxBet(betValue.gt(MAX_DEMO_BET_NUMBER));
    }
  }, [
    active,
    user,
    isPrevDemo,
    limboGameObject,
    limboGameObject,
    betValue,
    isAutoplay,
    autoplayAmount,
    crashBet,
  ]);

  const updateMinBet = async (betValue) => {
    if (!limboGameObject) return;
    let minBet = (await limboGame).minWager
      .mul(10000)
      .div(
        ethers.BigNumber.from(10000).sub(
          getFee(limboGameObject.gameVault.gameFee)
        )
      ); //.add(1000);
    setMinBet(minBet);
    setIsMinBet(betValue.lt(minBet));
  };

  const updateGameData = async () => {
    if (!limboGameObject) return;
    setGameAddress(limboGameObject.game.impl);
    setVaultAddress(limboGameObject.vault);
  };

  const updateMaxPayout = async (crashBet, autoplayAmount, betValue) => {
    if (!limboGameObject) return;
    let maxPayout = limboGameObject.maxPayout;
    updateMaxBet(crashBet, autoplayAmount, betValue, maxPayout);
  };

  const updateMaxBet = async (
    crashBet,
    autoplayAmount,
    betValue,
    maxPayout
  ) => {
    if (!limboGameObject) return;
    let maxBet = bigNumberMin([
      calculateMaxBet(
        limboGameObject.maxReservedAmount.div(autoplayAmount),
        crashBet,
        getFee(limboGameObject.gameVault.gameFee)
      ),
      calculateMaxBet(
        maxPayout,
        crashBet,
        getFee(limboGameObject.gameVault.gameFee)
      ),
      user.balance.div(autoplayAmount)
    ]);
    setMaxBet(maxBet);
    setIsMaxBet(betValue.gt(maxBet));
  };

  useEffect(() => {
    if (isGamePlaying) return;
    isMaxBet
      ? displayReduceBetMsg(
        prettyValue(maxBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals),
        isPrevDemo
      )
      : dismissReduceBetMsg();
  }, [isMaxBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    isMinBet
      ? displayIncreaseBetMsg(
        prettyValue(minBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals),
        isPrevDemo
      )
      : dismissIncreaseBetMsg();
  }, [isMinBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    dismissReduceBetMsg();
    dismissIncreaseBetMsg();
    setCheckBet(isMinBet || isMaxBet ? !checkBet : checkBet);
  }, [isPrevDemo, isGamePlaying]);

  useEffect(() => {
    if (isGamePlaying) return;
    if (!isPrevDemo && allowance && user)
      setIsSufficientAllowance(allowance.gt(betValue.mul(autoplayAmount)));
  }, [allowance, betValue, autoplayAmount, isPrevDemo, isGamePlaying, user]);

  useEffect(() => {
    if (txTypeInProgress === 'approveVault') {
      if (txStatus === 'Mining') {
        displaySentMsg();
      } else if (txStatus === 'Exception' || txStatus === 'Fail') {
        displayDeclinedMsg();
      }
      else if (txStatus === 'Success') {
        dismissSentMsg();
        displayTxDoneMsg();
      }
    } else if (txTypeInProgress === 'gameInProgress') {
      if (txStatus === 'Mining') {
        dismissMadeMsg();
        displaySentMsg();
        // setTxRes(res);
      } else if (txStatus === 'Exception' || txStatus === 'Fail') {
        setGamePlaying(false);
        sendToParent("setGamePlaying", { value: false });
        displayDeclineResult();
      }
    }
  }, [txStatus])

  useEffect(() => {
    console.log(tokenApproveFunctionState);
    if (tokenApproveFunctionState.status === "Mining") {
      displaySentMsg();
    } else if (
      tokenApproveFunctionState.status === "Exception" ||
      tokenApproveFunctionState.status === "Fail"
    ) {
      displayDeclinedMsg();
    } else if (tokenApproveFunctionState.status === "Success") {
      dismissSentMsg();
      displayTxDoneMsg();
    }
  }, [tokenApproveFunctionState]);

  useEffect(() => {
    console.log(playFunctionState);
    if (playFunctionState.status === "Mining") {
      dismissMadeMsg();
      displaySentMsg();
      // setTxRes(res);
    } else if (
      playFunctionState.status === "Exception" ||
      playFunctionState.status === "Fail"
    ) {
      setGamePlaying(false);
      displayDeclineResult();
    }
  }, [playFunctionState]);

  // game results tracking function
  async function onData(log, game) {
    let result;
    if (game === LIMBO_GAME) {
      let iface = new ethers.utils.Interface(IGAME_ABI);
      let events = iface.parseLog(log);
      console.log("events:", events);
      console.log("log:", log);

      result = parseGameResultLimbo(
        {
          user: events.args._user,
          sentValue: events.args._sentValue,
          autoroolAmount: events.args._autoroolAmount,
          amount: events.args._wager,
          gameData: events.args._gameData,
          payoutAmount: events.args._payoutAmount,
          randomValue: events.args._randomValue,
          token: events.args._token,
          event: log,
        },
        tokenDecimals,
        preferredChainId
      );
    }
    console.log(`${game} result:`, result);
    setGameQueueInit((prev) => {
      return [...prev, {result, game}];
    });
  }

  function checkGameQueue(result, game) {
    if ((externalAccount ? externalAccount : account) && result[0].address === (externalAccount ? externalAccount : account)) {
      dismissMadeMsg();
      dismissSentMsg();

      if (game === LIMBO_GAME)
        setGameQueue((prevArray) => [
          ...prevArray,
          {
            items: result,
            is_auto: result.length > 1,
          },
        ]);
    } else {
      for (let i = 0; i < result.length; i++) {
        sendToParent('newGameHistory', { ...result[i], target: result[i].target.toString(), random_value: (result[i].random_value * 100).toString() })
      }
    }
  }

  useEffect(() => {
    for (var i = gameQueueInitOffset; i < gameQueueInit.length; i++) {
      setGameQueueInitOffset(i + 1);
      checkGameQueue(gameQueueInit[i].result, gameQueueInit[i].game);
    }
  }, [gameQueueInit]);

  useEffect(() => {
    updateLimboGame(limboGame);
  }, [limboGame]);

  const updateLimboGame = async (limboGame) => {
    if ((await limboGame) && (await limboGame).game.impl !== limboAddress)
      setLimboAddress((await limboGame).game.impl);
    if (
      (await limboGame) &&
      !deepEqual(await limboGame, limboGameObject ? limboGameObject : {})
    )
      setLimboGameObject(await limboGame);
  };

  useEffect(() => {
    if (limboAddress && wss) subscribeLimbo();
  }, [limboAddress]);

  const subscribeLimbo = async () => {
    let web3 = new Web3(wss);
    web3.eth
      .subscribe(
        "logs",
        {
          address: limboAddress,
          topics: [
            new ethers.utils.Interface(IGAME_ABI).getEventTopic(
              "GameSessionPlayed"
            ),
            null,
            null,
          ],
        },
        function (error, result) {
          if (!error) console.log("limbo sub:", result);
        }
      )
      .on("connected", function (subscriptionId) {
        console.log({ subscriptionId });
      })
      .on("data", function (log) {
        onData(log, LIMBO_GAME);
      })
      .on("changed", function (log) { });
  };

  // crash bet
  function handleIncreaseBet() {
    const bet = Number(crashBet);
    const increasedBet = bet + CRASH_BET_STEP_NUMBER;
    const newBet = increasedBet <= MAX_CRUSH_BET ? increasedBet : MAX_CRUSH_BET;
    setCrashBet(newBet.toFixed(2));
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function handleReduceBet() {
    const bet = Number(crashBet);
    const reducedBet = bet - CRASH_BET_STEP_NUMBER;
    const newBet = reducedBet >= MIN_CRUSH_BET ? reducedBet : MIN_CRUSH_BET;
    setCrashBet(newBet.toFixed(2));
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function handleBetChange(evt) {
    const value = evt.target.value
      .replace(/[^.\d]/g, "")
      .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
    if (value <= MAX_CRUSH_BET) setCrashBet(value);
  }

  function checkBetChange(evt) {
    const value = evt.target.value;
    let newBet;
    if (
      value &&
      !isNaN(Number(value)) &&
      Number(value) >= MIN_CRUSH_BET &&
      Number(value) <= MAX_CRUSH_BET
    ) {
      newBet = Number(value);
    } else if (
      value &&
      !isNaN(Number(value)) &&
      Number(value) >= MAX_CRUSH_BET
    ) {
      newBet = MAX_CRUSH_BET;
    } else {
      newBet = MIN_CRUSH_BET;
    }
    setCrashBet(newBet.toFixed(2));
  }

  const playSound = (audio) => {
    if (isSoundOn) {
      audio.play();
    }
  };

  // display game result
  function displayGameResult(result) {
    const payout_real = result.payout_real_amount;
    const payout = result.payout_amount;
    const random = result.random_value;

    startAnimation({ random, payout_real, payout, isDemo: false }).then(() => {
      if (payout_real.isZero()) {
        displayLostMsg(true);
        setLost(true);
      }
      sendToParent('newGameHistory', { ...result, target: result.target.toString(), random_value: (result.random_value * 100).toString() })

      setPreGameAnim(false);
      setResult(false);
      setTimeout(() => {
        setBlocked(false);
        setIsGameOn(false);
        setGamePlaying(false);
        setWin(false);
        setLost(false);
        setMultiplier(START_MULTIPLIER_NUMBER);
        sendToParent("setGamePlaying", { value: false });
      }, 2000);
    });
  }

  function displayAUTOGameResult(result) {
    async function showRes(res, index) {
      console.log({ index, res });
      const payout_real = res.payout_real_amount;
      const payout = res.payout_amount;
      const random = res.random_value;
      const delay = index !== 0 ? 600 : 0;

      await startAnimation({
        random,
        payout_real,
        payout,
        isFast: result.length > 1,
        isDemo: false,
        delay,
      }).then(() => {
        if (payout_real.isZero()) {
          displayLostMsg(true);
          setLost(true);
        }
        sendToParent('newGameHistory', { ...res, target: res.target.toString(), random_value: (res.random_value * 100).toString() })

        if (index + 1 === result.length) {
          setPreGameAnim(false);
          setResult(false);
          setTimeout(() => {
            setBlocked(false);
            setIsGameOn(false);
            setGamePlaying(false);
            setWin(false);
            setLost(false);
            setMultiplier(START_MULTIPLIER_NUMBER);
            sendToParent("setGamePlaying", { value: false });
          }, 2000);
        }
      });
    }

    result.reduce(async (a, res, index) => {
      // Wait for the previous item to finish processing
      await a;
      // Process this item
      await showRes(res, index);
    }, Promise.resolve());
  }

  // display transaction declined results
  function displayDeclineResult() {
    dismissMadeMsg();
    dismissSentMsg();
    displayDeclinedMsg();
    setWin(false);
    setLost(false);
    setBlocked(false);
    setIsGameOn(false);
    setGamePlaying(false);
    setPreGameAnim(false);
    setMultiplier(START_MULTIPLIER_NUMBER);
    setCount(1);
  }

  const allowTokenTx = async () => {
    if (!limboGameObject || !vaultAddress) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      ERC20Interface
    )
    const data = gameContract.interface.encodeFunctionData('approve', [
      vaultAddress,
      MAX_BIG_NUMBER
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: tokenAddress, data, value: 0 });
      setTxTypeInProgress('approveVault');
    } else {
      if (!isMagicConnectorStateVar) {
        tokenApproveFunctionSend(
          vaultAddress,
          MAX_BIG_NUMBER
        )
      } else {
        sendMagicTx({ from: externalAccount, to: tokenAddress, data, value: 0 });
        setTxTypeInProgress('approveVault');
      }
    }
  }

  // request to smart contract
  const rollPlaySendTx = async () => {
    if (!limboGameObject || !(await gasPerRoll)) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      new ethers.utils.Interface(IGAME_ABI)
    )
    const data = gameContract.interface.encodeFunctionData('play', [
      tokenAddress,
      partnerReferralAddress,
      autoplayAmount,
      betValue.mul(autoplayAmount),
      ethers.utils.defaultAbiCoder.encode(
        ["uint256"],
        [Number((crashBet * 100).toFixed(0))]
      ),
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
      sendToParent("setGamePlaying", { value: true });
      setTxTypeInProgress('gameInProgress');
    } else {
      if (!isMagicConnectorStateVar) {
        playFunctionSend(
          tokenAddress,
          partnerReferralAddress,
          autoplayAmount,
          betValue.mul(autoplayAmount),
          ethers.utils.defaultAbiCoder.encode(
            ["uint256"],
            [Number((crashBet * 100).toFixed(0))]
          ),
          { value: await gasPerRoll }
        )
      } else {
        sendMagicTx({ from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
        setTxTypeInProgress('gameInProgress');
      }
    }
  };

  function allowToken() {
    allowTokenTx().catch(() => {
      displayDeclinedMsg();
    });
  }

  // game animation
  const startAnimation = ({
    random,
    payout,
    payout_real,
    isDemo,
    isFast = false,
    delay = 0,
  }) => {
    return new Promise(function (resolve, reject) {
      dismissSentMsg();
      let isWinShown = false;
      let startTime;
      let acceleration = isFast ? 0.00001 : 0.000001; // Initial acceleration factor

      setTimeout(() => {
        setWin(false);
        setLost(false);
        setMultiplier(START_MULTIPLIER_NUMBER);

        const animate = (timestamp) => {
          if (!startTime) {
            startTime = timestamp;
          }

          const elapsedTime = timestamp - startTime;
          setIsGameOn(true);
          acceleration += isFast ? 0.000003 : 0.0000005;
          const newMultiplier = 1 + acceleration * elapsedTime;
          setMultiplier(newMultiplier);

          if (!isWinShown && newMultiplier >= crashBet) {
            if (isDemo) {
              displayWonMsg(payout + ` ${DEMO_CURRENCY}`, isFast);
              setDemoBalance((prevValue) => prevValue.add(payout_real));
            } else {
              displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, isFast);
            }
            setWin(true);
            isWinShown = true;
          }

          if (newMultiplier >= random) {
            setMultiplier(random);
            cancelAnimationFrame(animationRef.current);
            setIsGameOn(false);
            resolve();
            return;
          }

          animationRef.current = requestAnimationFrame(animate);
        };

        animationRef.current = requestAnimationFrame(animate);
      }, delay);
    });
  };

  // pre game animation
  useEffect(() => {
    let interval;

    if (isPreGameAnim) {
      interval = setInterval(() => {
        const stringNumber = multiplier.toFixed(2);
        const integerPart = Number(stringNumber.slice(0, 1));
        const decimalPart = Number(stringNumber.slice(2, 3));
        const centesimalPart = Number(stringNumber.slice(-1));

        let newIntegerPart = integerPart;
        let newDecimalPart = decimalPart;
        let newCentesimalPart = centesimalPart + 1;

        switch (true) {
          case count % 6 === 0:
            newIntegerPart += 1;
            newDecimalPart += 1;
            break;
          case count % 3 === 0:
            newIntegerPart += 1;
            break;
          case count % 2 === 0:
            newDecimalPart += 1;
            break;
          default:
            break;
        }

        newDecimalPart = newDecimalPart >= 10 ? 0 : newDecimalPart;
        newCentesimalPart = newCentesimalPart >= 10 ? 0 : newCentesimalPart;

        const newMultiplier = Number(
          `${newIntegerPart}.${newDecimalPart}${newCentesimalPart}`
        );
        setMultiplier(newMultiplier);
        setCount((prevVal) => prevVal + 1);

        if (newMultiplier >= 9.99) {
          setMultiplier(START_MULTIPLIER_NUMBER);
          setPreGameAnim(false);
          setCount(1);
          checkIsResult();
          clearInterval(interval);
        }
      }, 150);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isPreGameAnim, multiplier, count]);

  useEffect(() => {
    console.log({ gameQueue, isGamePlaying, isResult });
    if (!isResult) setResult(gameQueue.length > 0);
  }, [gameQueue]);

  // if result show main animation
  function checkIsResult() {
    if (isResult) {
      if (gameQueue.length > 0) {
        if (isGamePlaying) {
          if (gameQueue.length === 1) {
            if (gameQueue[0].is_auto) {
              setGamePlaying(true);
              displayAUTOGameResult(gameQueue[0].items);
              setGameQueue((prevArr) =>
                prevArr.filter(
                  (item) =>
                    item.items[0].transaction_hash !==
                    gameQueue[0].items[0].transaction_hash
                )
              );
            } else {
              setGamePlaying(true);
              displayGameResult(gameQueue[0].items[0]);
              setGameQueue((prevArr) =>
                prevArr.filter(
                  (item) =>
                    item.items[0].transaction_hash !==
                    gameQueue[0].items[0].transaction_hash
                )
              );
            }
          }
        }
      }
    } else {
      setPreGameAnim(true);
    }
  }

  // start game
  const crash = () => {
    if (user && !isDemo) {
      setGamePlaying(true);
      setWin(false);
      setLost(false);
      setResult(false);
      setBlocked(true);
      setPreGameAnim(true);
      setIsGameOn(true);
      displayMadeMsg();

      rollPlaySendTx().catch(() => {
        setGamePlaying(false);
        displayDeclineResult();
      });
    } else {
      const autoplayArray = Array(autoplayAmount).join(".").split(".");

      async function showRes(index) {
        let minCrashBet_ = MIN_CRUSH_VALUE * 100;
        let maxCrashBet_ = MAX_CRUSH_BET * 100;
        const baseMod_ = maxCrashBet_;
        const randVal_ = Math.random();
        const H_ = (randVal_ * maxCrashBet_) % baseMod_;
        let randomMultiplier = Number(
          ((0.97 * maxCrashBet_ * 100) / (maxCrashBet_ - H_)).toFixed(0)
        );
        randomMultiplier =
          randomMultiplier > minCrashBet_
            ? randomMultiplier < maxCrashBet_
              ? randomMultiplier
              : maxCrashBet_
            : minCrashBet_;
        randomMultiplier = Number((randomMultiplier / 100).toFixed(2));
        const delay = index !== 0 ? 600 : 0;
        const payoutReal = betValue
          .mul(Number((crashBet * 100).toFixed(0)))
          .div(100);

        await startAnimation({
          random: randomMultiplier,
          isFast: autoplayArray.length > 1,
          isDemo: true,
          delay,
          payout_real: payoutReal,
          payout: prettyValue(payoutReal, DEMO_DECIMALS),
        }).then(() => {
          if (crashBet > randomMultiplier) {
            displayLostMsg(true);
            setLost(true);
          }

          if (index + 1 === autoplayArray.length) {
            setTimeout(() => {
              setWin(false);
              setLost(false);
              setMultiplier(START_MULTIPLIER_NUMBER);
              setBlocked(false);
              setGamePlaying(false);
            }, 2000);
          }
        });
      }

      displaySentMsg();
      setDemoBalance((prevValue) =>
        prevValue.sub(betValue.mul(autoplayArray.length))
      );
      setBlocked(true);

      autoplayArray.reduce(async (a, res, index) => {
        await a;
        // Process this item
        await showRes(index);
        // Wait for the previous item to finish processing
      }, Promise.resolve());
    }
  };

  function handlePlayResultsInQueue() {
    let itemsArray = [];
    gameQueue.forEach((element) => {
      element.items.forEach((item) => {
        itemsArray = [...itemsArray, item];
      });
    });
    let hashesArray = gameQueue.map((item) => {
      return item.items[0].transaction_hash;
    });

    setGameQueue((prevArr) =>
      prevArr.filter(
        (item) => hashesArray.indexOf(item.items[0].transaction_hash) < 0
      )
    );

    dismissMadeMsg();
    dismissSentMsg();
    setBlocked(true);
    setGamePlaying(true);
    setWin(false);
    setLost(false);
    displayAUTOGameResult(itemsArray);
  }

  useEffect(() => {
    let root = document.documentElement;
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      console.log(param, value);

      switch (param) {
        case "--box-border-color":
        case "--box-bg-color":
          root.style.setProperty(param, value);
          break;

        default:
          break;
      }
    }
  }, [searchParams]);

  return (
    <section
      className={`limbo ${!isGamePlaying && gameQueue.length > 0 ? "limbo_with-tx-queue" : ""
        }`}
    >
      {!isGamePlaying && gameQueue.length > 0 ? (
        <div className="limbo__tx-in-queue">
          <p className="limbo__tx-in-queue-text">
            You have {gameQueue.length} unviewed{" "}
            {gameQueue.length > 1 ? "results" : "result"}!
          </p>
          <button
            className="limbo__tx-in-queue-btn"
            type="button"
            onClick={handlePlayResultsInQueue}
          >
            See {gameQueue.length > 1 ? "results" : "result"}
          </button>
        </div>
      ) : null}

      <div className="limbo__game-block">
        <div
          className={`limbo__result-block ${!isGamePlaying && gameQueue.length > 0
            ? "limbo__result-block_with-tx-queue"
            : ""
            }`}
        >
          {!isGamePlaying && gameQueue.length > 0 ? (
            <button
              className="limbo__tx-in-queue-btn limbo__tx-in-queue-btn_mobile"
              type="button"
              onClick={handlePlayResultsInQueue}
            >
              See {gameQueue.length}{" "}
              {gameQueue.length > 1 ? "results" : "result"}
            </button>
          ) : null}

          <div className="limbo__crash-block">
            <CrashGame {...{ multiplier, isWin, isLost }} />
          </div>
        </div>

        <div
          className={`limbo__bet-block ${isBlocked ? "limbo__bet-block_disabled" : ""
            }`}
        >
          <p className="limbo__bet-title">Target Coefficient</p>
          <div className="limbo__crash-bet">
            <button
              className={`limbo__bet-btn ${Number(crashBet) <= MIN_CRUSH_BET
                ? "limbo__bet-btn_disabled"
                : ""
                }`}
              type="button"
              aria-label="Reduce the target coefficient"
              onClick={handleReduceBet}
            >
              -
            </button>
            <div className="limbo__bet-input-box">
              <input
                className="limbo__bet-input"
                id="coefficient"
                name="coefficient"
                type="text"
                // inputMode="numeric"
                autoComplete="off"
                value={crashBet || ""}
                onChange={handleBetChange}
                onBlur={checkBetChange}
              />
            </div>
            <button
              className={`limbo__bet-btn ${Number(crashBet) >= MAX_CRUSH_BET
                ? "limbo__bet-btn_disabled"
                : ""
                }`}
              type="button"
              aria-label="Increase the target coefficient"
              onClick={handleIncreaseBet}
            >
              +
            </button>
          </div>
        </div>

        <div className="limbo__options-block">
          {user && !minBet ? (
            <div className="limbo__options-block-preloader">
              <MiniPreloader />
            </div>
          ) : (
            <GameOptions
              btnText="Roll"
              onClick={crash}
              rolling={isGameOn}
              bet={betValue}
              setBet={setBetValue}
              minMaxBet={{ min: minBet, max: maxBet }}
              queue={gameQueue}
              autoplayText="Number of Games"
              {...{
                preferredToken,
                active,
                chance,
                payout,
                isSoundOn,
                isBlocked,
                user,
                autoplayAmount,
                setAutoplayAmount,
                switchNetwork,
                isSupportedNetwork,
                isAutoplay,
                demoBalance,
                isMaxBet,
                isMinBet,
                isSufficientAllowance,
                allowToken,
                isDemo,
                demoCoinLogo,
                preferredChainId,
                tokenDecimals,
                betValueWriten,
                setBetValueWriten,
                isDemoSwitch,
                openWalletModal,
                externalAccount,
                sendToParent,
                isExternalWalletConnected,
                isExternalAccountConnection
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default Limbo;
