import "./CrashValue.css";

function CrashValue({ value, bet, isWin, isLost }) {
  const formattedValue = value < 100 ? value.toFixed(2) : value.toFixed(1);

  return (
    <div className="crash-value">
      <div
        className={`crash-value__multiplier-box ${
          isWin ? "crash-value__multiplier-box_type_win" : ""
        } ${isLost ? "crash-value__multiplier-box_type_lost" : ""}`}
      >
        <p className="crash-value__multiplier">× {formattedValue}</p>
      </div>
      <div
        className={`crash-value__result-box ${
          isWin || isLost ? "crash-value__result-box_visible" : ""
        } ${isWin ? "crash-value__result-box_type_win" : ""} ${
          isLost ? "crash-value__result-box_type_lost" : ""
        }`}
      >
        <p className="crash-value__result">
          {isWin ? `Cashed out at ${bet}×` : isLost ? "Crash!" : ""}
        </p>
      </div>
    </div>
  );
}

export default CrashValue;
