import { useEffect, useRef, useState } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceDot,
} from "recharts";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import { MAX_CRUSH_BET } from "../../../assets/utilis/constants";
import { GraphStarIcon } from "../../../assets/utilis/icons";
import "./Graph.css";

const AreaFilter = (
  <filter
    id="areaFilter"
    x="0"
    y="0"
    width="707"
    height="435.533"
    filterUnits="userSpaceOnUse"
    colorInterpolationFilters="sRGB"
  >
    <feFlood floodOpacity="0" result="BackgroundImageFix" />
    <feBlend
      mode="normal"
      in="SourceGraphic"
      in2="BackgroundImageFix"
      result="shape"
    />
    <feGaussianBlur
      stdDeviation="22"
      result="effect1_foregroundBlur_131_6096"
    />
  </filter>
);

const purpleGradient = (
  <linearGradient
    id="purpleGradient"
    x1="353.5"
    y1="44"
    x2="353.5"
    y2="391.533"
    gradientUnits="userSpaceOnUse"
  >
    <stop stopColor="var(--crash-game-color)" stopOpacity="0.71" />
    <stop offset="1" stopColor="var(--crash-game-color)" stopOpacity="0" />
  </linearGradient>
);

// const orangeGradient = (
//   <linearGradient
//     id="orangeGradient"
//     x1="601.5"
//     y1="135"
//     x2="663"
//     y2="321.5"
//     gradientUnits="userSpaceOnUse"
//   >
//     <stop stopColor="var(--crash-gradient-color)" />
//     <stop offset="1" stopColor="var(--crash-gradient-color)" stopOpacity="0" />
//   </linearGradient>
// );

function Graph({ data, isLost, isPreGameAnim }) {
  const graphRef = useRef();
  const { width } = useWindowSize();
  const [padding, setPadding] = useState(0);
  const referencePoint = data[data.length - 1];
  const xMax = Math.max(...data.map((item) => item.time));
  const yMax = Math.max(...data.map((item) => item.multiplier)) * 1.3;
  const yAxisMax = yMax < MAX_CRUSH_BET + 5 ? yMax : MAX_CRUSH_BET + 5;
  const adjustedData = [...data];

  if (isPreGameAnim) {
    const fakeDataLength = 120 - data.length;
    for (let i = 1; i <= fakeDataLength; i++) {
      adjustedData.push({ time: xMax + i, multiplier: null });
    }
  } else {
    for (let i = 1; i <= 20; i++) {
      adjustedData.push({ time: xMax + i * 50, multiplier: null });
    }
  }

  useEffect(() => {
    if (!graphRef) return;

    const offsetWidth = graphRef.current.offsetWidth;
    const newPadding = (5 * offsetWidth) / 100;
    setPadding(newPadding);
  }, [graphRef, width]);

  const RenderCustomizedDot = (props) => {
    const { cx, cy } = props;
    return GraphStarIcon({
      x: cx - 10,
      y: cy - 7,
      fill: isLost ? "var(--crash-lose-color)" : "var(--crash-game-color)",
      fillClassName: "graph__reference-icon-fill",
    });
  };

  // const CustomYTick = ({ x, y, payload }) => {
  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       <text
  //         x={8}
  //         y={8}
  //         dy={9}
  //         textAnchor="end"
  //         fontSize="14px"
  //         opacity={0.3}
  //         fill="var(--text-color)"
  //       >
  //         {payload.value.toFixed(2)}
  //       </text>
  //     </g>
  //   );
  // };

  return (
    <div className="graph" ref={graphRef}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={1} height={1} data={adjustedData}>
          <CartesianGrid
            strokeDasharray="5 0"
            vertical={false}
            height={1}
            stroke="var(--crash-border-color)"
            strokeOpacity={0.1}
            horizontalCoordinatesGenerator={(props) => [props.height]}
          />
          <XAxis
            dataKey="time"
            hide={true}
            domain={[0, "auto"]}
            padding={{ right: padding }}
          />
          <YAxis
            hide={true}
            domain={isPreGameAnim ? [1, 3] : [1, yAxisMax]}
            // orientation="right"
            // axisLine={false}
            // tickLine={false}
            // tickCount={6}
            // // tick={{ fontSize: "14px", opacity: 0.3, fill: "var(--text-color)" }}
            // // tickFormatter={(value) => value.toFixed(2)}
            // mirror={true}
            // tick={<CustomYTick />}
          />
          <Line
            type="monotone"
            dataKey="multiplier"
            stroke="var(--crash-game-color)"
            strokeWidth="4px"
            strokeLinecap="round"
            filter="drop-shadow(0px 2px 4px rgba(201, 46, 255, 0.30))"
            dot={false}
          />
          <ReferenceDot
            x={referencePoint.time}
            y={referencePoint.multiplier}
            isFront={true}
            shape={RenderCustomizedDot}
          />
          {/* <Area
            type="monotone"
            dataKey="multiplier"
            stroke="none"
            fill="url(#orangeGradient)"
            filter="url(#areaFilter)"
            opacity={0.2}
          />
          <defs>
            {AreaFilter}
            {orangeGradient}
          </defs> */}
          {!isPreGameAnim ? (
            <Area
              type="monotone"
              dataKey="multiplier"
              stroke="none"
              fill="url(#purpleGradient)"
              filter="url(#areaFilter)"
              animationDuration={10000}
              isAnimationActive={true}
            />
          ) : null}
          <defs>
            {AreaFilter}
            {purpleGradient}
          </defs>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Graph;
