import { MAIN_URL } from "../utilis/constants";

class MainApi {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${MAIN_URL}/users/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getUser() {
    return fetch(`${MAIN_URL}/users/get-me`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getUser,
        params: {},
      })
    );
  }

  getNewUuid(account) {
    let params = {};
    if (account) params.address = account;
    return fetch(`${MAIN_URL}/users/new_uuid?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  auth({ address, signature, uuid }) {
    return fetch(`${MAIN_URL}/users/auth`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        address,
        signature,
        uuid,
      }),
    }).then(this._checkResponse);
  }

  logout() {
    return fetch(`${MAIN_URL}/users/logout`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.logout,
        params: {},
      })
    );
  }

  networksAvailable() {
    return fetch(`${MAIN_URL}/networks/available`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
