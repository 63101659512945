import { useEffect, useState } from 'react';
import { ParaliqIcon } from '../../assets/utilis/icons';
import './Loading.css';

function Loading() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const totalDuration = 5000; // 5 seconds
        const step = 0.1;
        const intervalDuration = 13; // 50 milliseconds
        const stepsInInterval = (step / 100) * totalDuration / intervalDuration;

        const id = setInterval(() => {
            setProgress(prevProgress => {
                const newProgress = prevProgress + stepsInInterval;
                if (newProgress >= 100) {
                    clearInterval(id);
                    return 100;
                }
                return newProgress;
            });
        }, intervalDuration);

        return () => {
            clearInterval(id);
        };
    }, []);




    return (
        <div className='loading'>
            <ParaliqIcon mainClassName={'loading__logo'} />
            <div className='loading__progress-box'>
                <div
                    style={{
                        width: `${progress}%`,
                    }}
                    className='loading__progress'
                ></div>
            </div>

            <p className='loading__perent'>{`${Math.round(progress)}%`}</p>
        </div>
    );
}

export default Loading