import { useState, useEffect } from "react";
import moment from "moment";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import "./SevensCoin.css";

// coin images
import startFront from "../../../assets/images/lucky-sevens/start-blue.png";
import startBack from "../../../assets/images/lucky-sevens/start-yellow.png";
import continueAnimation from "../../../assets/images/lucky-sevens/continue.png";
import stopFront from "../../../assets/images/lucky-sevens/stop-blue.png";
import stopBack from "../../../assets/images/lucky-sevens/stop-yellow.png";
import flipFront from "../../../assets/images/lucky-sevens/flip-blue-to-yellow.png";
import flipBack from "../../../assets/images/lucky-sevens/flip-yellow-to-blue.png";
import frontCoin from "../../../assets/images/lucky-sevens/front.png";
import backCoin from "../../../assets/images/lucky-sevens/back.png";

function SevensCoin({
  isFliping,
  isStopFliping,
  isFrontTop,
  isFrontWin,
  isLoaded,
  isFastFlip,
  isAnimEnd,
  isReturnAnim,
  isAuto,
  time,
}) {
  const [isContinue, setContinue] = useState(false);
  const [isSideChange, setSideChange] = useState(false);
  const [isOpacity, setOpacity] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [isTop, setIsTop] = useState(false);
  const images = {
    frontCoin: frontCoin,
    backCoin: backCoin,
    startFront: startFront,
    startBack: startBack,
    continueAnimation: continueAnimation,
    stopFront: stopFront,
    stopBack: stopBack,
    flipFront: flipFront,
    flipBack: flipBack,
  };

  useEffect(() => {
    if (!Boolean(time) || !isFliping || isContinue) return;

    const now = moment();
    const timer = setInterval(() => {
      if (Math.abs(time.diff(now)) >= 3970 && isFliping && !isContinue) {
        const diff = Math.abs(time.diff(now)) % 50;
        if ((diff <= 40 || 4000 - diff <= 40) && !isFastFlip) setContinue(true);
      }

      setTimerValue(timerValue + 1);
      clearInterval(timer);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValue, time]);

  useEffect(() => {
    setContinue(false);
  }, [isFliping, isStopFliping]);

  useEffect(() => {
    if (isAuto) return;

    setSideChange(true);
    setIsTop(isFrontTop);
    setTimeout(() => {
      setSideChange(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFrontTop]);

  useEffect(() => {
    setSideChange(false);
  }, []);

  useEffect(() => {
    if (!isAnimEnd) return;

    setOpacity(true);
    setTimeout(() => {
      setOpacity(false);
    }, 700);
  }, [isAnimEnd]);

  return (
    <>
      {isLoaded ? (
        <div className="sevens-coin">
          <img
            className={`sevens-coin__single-image ${
              (!isFliping &&
                !isContinue &&
                !isStopFliping &&
                !isAnimEnd &&
                !isReturnAnim &&
                !isSideChange &&
                isTop) ||
              (isReturnAnim && isFrontWin && isTop)
                ? "sevens-coin__single-image_visible"
                : ""
            }`}
            src={images.frontCoin}
            alt=""
            key="start-blue-single"
          />

          <img
            className={`sevens-coin__single-image ${
              (!isFliping &&
                !isContinue &&
                !isStopFliping &&
                !isAnimEnd &&
                !isReturnAnim &&
                !isSideChange &&
                !isTop) ||
              (isReturnAnim && !isFrontWin && !isTop)
                ? "sevens-coin__single-image_visible"
                : ""
            }`}
            src={images.backCoin}
            alt=""
            key="start-yellow-single"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_start ${
              isFliping && !isContinue && isFrontTop
                ? "sevens-coin__animation_visible"
                : ""
            } ${isFastFlip ? "sevens-coin__animation_type_fast" : ""}`}
            src={images.startFront}
            alt=""
            key="start-blue"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_start ${
              isFliping && !isContinue && !isFrontTop
                ? "sevens-coin__animation_visible"
                : ""
            } ${isFastFlip ? "sevens-coin__animation_type_fast" : ""}`}
            src={images.startBack}
            alt=""
            key="start-yellow"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_continue ${
              isContinue && !isStopFliping
                ? "sevens-coin__animation_visible"
                : ""
            }`}
            src={images.continueAnimation}
            alt=""
            key="continue"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_stop ${
              isStopFliping && isFrontWin
                ? "sevens-coin__animation_visible"
                : ""
            } ${isFastFlip ? "sevens-coin__animation_type_fast" : ""}`}
            src={images.stopFront}
            alt=""
            key="stop-blue"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_stop ${
              isStopFliping && !isFrontWin
                ? "sevens-coin__animation_visible"
                : ""
            } ${isFastFlip ? "sevens-coin__animation_type_fast" : ""}`}
            src={images.stopBack}
            alt=""
            key="stop-yellow"
          />

          <img
            className={`sevens-coin__single-image ${
              isAnimEnd && isFrontWin ? "sevens-coin__single-image_visible" : ""
            } ${
              isAnimEnd && isFrontWin && isOpacity
                ? "sevens-coin__single-image_type_to-opacity"
                : ""
            } ${
              isAnimEnd && isFrontWin && !isOpacity
                ? "sevens-coin__single-image_type_from-opacity"
                : ""
            }`}
            src={images.frontCoin}
            alt=""
            key="stop-blue-single"
          />

          <img
            className={`sevens-coin__single-image ${
              isAnimEnd && !isFrontWin
                ? "sevens-coin__single-image_visible"
                : ""
            } ${
              isAnimEnd && !isFrontWin && isOpacity
                ? "sevens-coin__single-image_type_to-opacity"
                : ""
            } ${
              isAnimEnd && !isFrontWin && !isOpacity
                ? "sevens-coin__single-image_type_from-opacity"
                : ""
            }`}
            src={images.backCoin}
            alt=""
            key="stop-yellow-single"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_flip ${
              (isReturnAnim && isFrontWin && !isFrontTop) ||
              (isSideChange && !isFrontTop)
                ? "sevens-coin__animation_visible"
                : ""
            }`}
            src={images.flipFront}
            alt=""
            key="flip-blue-to-yellow"
          />

          <img
            className={`sevens-coin__animation sevens-coin__animation_type_flip ${
              (isReturnAnim && !isFrontWin && isFrontTop) ||
              (isSideChange && isFrontTop)
                ? "sevens-coin__animation_visible"
                : ""
            }`}
            src={images.flipBack}
            alt=""
            key="flip-yellow-to-blue"
          />
        </div>
      ) : (
        <MiniPreloader />
      )}
    </>
  );
}

export default SevensCoin;
