import './ChargerValue.css';

function ChargerValue({ value, isLost, isPreGameAnim }) {
  const formattedValue = value.toFixed();

  return (
    <div className="charger-value">
      <p
        className={`charger-value__multiplier ${
          isLost ? 'charger-value__multiplier_type_lost' : ''
        } ${isPreGameAnim ? 'charger-value__multiplier_type_pregame' : ''}`}
      >
        {formattedValue}%
      </p>
    </div>
  );
}

export default ChargerValue;
