/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import {
  useContractFunction,
  useTokenAllowance,
  ERC20Interface,
  useCall,
} from "@usedapp/core";
import { ethers } from "ethers";
import {
  bigNumberMin,
  deepEqual,
  getFee,
  prettyValue,
} from "../../assets/utilis/utilis";
import Web3 from "web3";

import Spinner from "./Spinner/Spinner";
import GameOptions from "../GameOptions/GameOptions";
import MiniPreloader from "../MiniPreloader/MiniPreloader";

import { Howl } from "howler";
import bet_buttons_sound from "../../assets/sounds/bet_buttons_sound.wav";
import wheel_game_sound from "../../assets/sounds/wheel_game_sound.wav";
import wheel_start_sound from "../../assets/sounds/wheel_start_sound.wav";
import wheel_stop_sound from "../../assets/sounds/wheel_stop_sound.wav";

import { parseGameResultLuckyWheel } from "../../assets/utilis/utilis";
import {
  MIN_DEMO_BET_NUMBER,
  MAX_DEMO_BET_NUMBER,
  DEMO_MIN_BET_VALUE,
  MIN_AUTOPLAY_NUMBER,
  IGAME_ABI,
  DEMO_CURRENCY,
  NULL_ADDRESS,
  MAX_BIG_NUMBER,
  DEMO_DECIMALS,
  WHEEL_ID,
  ICONSOLE_ABI,
  WHEEL_GAME,
  SAFE_GAME_MODE,
  RISKY_GAME_MODE,
  DEGEN_GAME_MODE,
  SAFE_WHEEL_SLICES_LIST,
  RISKY_WHEEL_SLICES_LIST,
  DEGEN_WHEEL_SLICES_LIST,
  WHEEL_MODES_CHAIN,
} from "../../assets/utilis/constants";
import "./LuckyWheel.css";

function calculateMaxBet(maxPayout, crashBet, fee) {
  return maxPayout
    .mul(10000)
    .div(10000 - fee)
    .mul(100)
    .div(Number((crashBet * 100).toFixed(0)));
}

// get game
const useWheelGame = async (consoleAddress, tokenAddress, chainId) => {
  const { value, error } =
    useCall(
      consoleAddress && {
        contract: new ethers.Contract(consoleAddress, ICONSOLE_ABI),
        method: "getGameWithExtraData",
        args: [WHEEL_ID, tokenAddress],
      },
      { chainId }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  } else if (!value) {
    return undefined;
  }
  return {
    game: {
      id: value?.[0][0].id,
      paused: value?.[0][0].paused,
      name: value?.[0][0].name,
      date: value?.[0][0].date,
      impl: value?.[0][0].impl,
    },
    vault: value?.[0].vault,
    token: value?.[0].token,
    minWager: value?.[0].minWager,
    maxPayout: value?.[0].maxPayout,
    maxReservedAmount: value?.[0].maxReservedAmount,
    gameVault: {
      gameFee: {
        currentFee: value?.[0][6][0].currentFee,
        nextFee: value?.[0][6][0].nextFee,
        startTime: value?.[0][6][0].startTime,
      },
      isPresent: value?.[0][6].isPresent,
    },
  };
};

function LuckyWheel({
  consoleAddress,
  wss,
  isPrevDemo,
  setPrevDemo,
  isSoundOn,
  displayLostMsg,
  displayWonMsg,
  displayDeclinedMsg,
  displayMadeMsg,
  displaySentMsg,
  displayTxDoneMsg,
  displayReduceBetMsg,
  displayIncreaseBetMsg,
  dismissMadeMsg,
  dismissSentMsg,
  dismissReduceBetMsg,
  dismissIncreaseBetMsg,
  user,
  switchNetwork,
  isSupportedNetwork,
  demoBalance,
  setDemoBalance,
  playGameSound,
  stopGameAudio,
  fadeGameAudio,
  muteGameAudio,
  unmuteGameAudio,
  playMuteGameAudio,
  isGamePlaying,
  setGamePlaying,
  gasPerRoll,
  isDemo,
  demoCoinLogo,
  partnerReferralAddress,
  tokenAddress,
  isBlocked,
  setBlocked,
  preferredChainId,
  tokenDecimals,
  searchParams,
  isDemoSwitch,
  openWalletModal,
  externalAccount,
  account,
  active,
  sendToParent,
  txStatus,
  isExternalWalletConnected,
  isExternalAccountConnection,
  sendMagicTx,
  isMagicConnectorStateVar,
  preferredToken
}) {
  const [minBet, setMinBet] = useState(ethers.BigNumber.from(0));
  const [maxBet, setMaxBet] = useState(ethers.BigNumber.from(0));
  const [checkBet, setCheckBet] = useState(true);
  const [betValue, setBetValue] = useState(DEMO_MIN_BET_VALUE);
  const [betValueWriten, setBetValueWriten] = useState(
    prettyValue(betValue, DEMO_DECIMALS)
  );
  const isAutoplay = true;
  const [autoplayAmount, setAutoplayAmount] = useState(MIN_AUTOPLAY_NUMBER);
  const [payout, setPayout] = useState(0);
  const [chance, setChance] = useState(0);
  const [isSpin, setSpin] = useState(false);
  const [isSpinStarting, setSpinStarting] = useState(false);
  const [isSpinEnding, setSpinEnding] = useState(false);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [startDeg, setStartDeg] = useState(0);
  const [prizePosition, setPrizePosition] = useState(undefined);
  const [gameMode, setGameMode] = useState(SAFE_GAME_MODE);
  const [gameSlices, setGameSlices] = useState(SAFE_WHEEL_SLICES_LIST);
  const [isFastSpin, setFastSpin] = useState(false);
  const [isMaxBet, setIsMaxBet] = useState(false);
  const [isMinBet, setIsMinBet] = useState(false);
  const [isSufficientAllowance, setIsSufficientAllowance] = useState(false);
  const [gameAddress, setGameAddress] = useState(NULL_ADDRESS);
  const [vaultAddress, setVaultAddress] = useState(NULL_ADDRESS);

  const wheelGame = useWheelGame(consoleAddress, tokenAddress, preferredChainId);
  const [wheelGameObject, setWheelGameObject] = useState(null);
  const [wheelAddress, setWheelAddress] = useState(null);
  const [gameQueue, setGameQueue] = useState([]);
  const [gameQueueInit, setGameQueueInit] = useState([]);
  const [gameQueueInitOffset, setGameQueueInitOffset] = useState(0);
  const [txTypeInProgress, setTxTypeInProgress] = useState(null);

  // set wheel game sounds
  const buttonsAudio = useMemo(() => new Audio(bet_buttons_sound), []);
  const [isAudioStartEnding, setAudioStartEnding] = useState(false);
  const [isGameAudioPlaying, setGameAudioPlaying] = useState({
    start: false,
    game: false,
    stop: false,
  });
  const [gameAudioId, setGameAudioId] = useState({
    start: 0,
    game: 0,
    stop: 0,
  });
  const [gameAudio, setGameAudio] = useState({
    start: null,
    game: null,
    stop: null,
  });

  const allowance = useTokenAllowance(
    tokenAddress,
    externalAccount ? externalAccount : account,
    vaultAddress,
    {
      refresh: "everyBlock",
      chainId: preferredChainId
    }
  );

  useEffect(() => {
    const audio = new Howl({
      src: [wheel_game_sound],
      onplay: function (id) {
        setGameAudioId((prevId) => ({ ...prevId, game: id }));
        setGameAudioPlaying((prevState) => ({ ...prevState, game: true }));
        audio.loop(true, id);
      },
      onfade: function (id) {
        setGameAudioPlaying((prevState) => ({ ...prevState, game: false }));
        audio.loop(false, id);
        setAudioStartEnding(false);
      },
      onstop: function (id) {
        setGameAudioPlaying((prevState) => ({ ...prevState, game: false }));
        audio.loop(false, id);
        setAudioStartEnding(false);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio((prevAudio) => ({ ...prevAudio, game: audio }));
  }, []);

  useEffect(() => {
    const audio = new Howl({
      src: [wheel_start_sound],
      onplay: function (id) {
        setGameAudioId((prevId) => ({ ...prevId, start: id }));
        setGameAudioPlaying((prevState) => ({ ...prevState, start: true }));
        setAudioStartEnding(false);
      },
      onfade: function () {
        setGameAudioPlaying((prevState) => ({
          ...prevState,
          start: false,
        }));
      },
      onend: function () {
        setAudioStartEnding(true);
        setGameAudioPlaying((prevState) => ({
          ...prevState,
          start: false,
        }));
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio((prevAudio) => ({ ...prevAudio, start: audio }));
  }, []);

  useEffect(() => {
    const audio = new Howl({
      src: [wheel_stop_sound],
      onplay: function (id) {
        setGameAudioId((prevId) => ({ ...prevId, stop: id }));
        setGameAudioPlaying((prevState) => ({ ...prevState, stop: true }));
      },
      onfade: function () {
        setGameAudioPlaying((prevState) => ({ ...prevState, stop: false }));
      },
      onend: function () {
        setGameAudioPlaying((prevState) => ({ ...prevState, stop: false }));
        setAudioStartEnding(false);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio((prevAudio) => ({ ...prevAudio, stop: audio }));
  }, []);

  // control games sounds
  useEffect(() => {
    if (gameAudio.game === null || gameAudio.start === null) return;
    const isGameOn = isSpinStarting || isSpin;

    if (isSpinStarting || isSpin) {
      isAudioStartEnding
        ? playGameSound(gameAudio.game, isGameAudioPlaying.game, isGameOn)
        : isSoundOn
        ? playGameSound(
            gameAudio.start,
            isGameAudioPlaying.start,
            isSpinStarting,
            isFastSpin,
            2
          )
        : playMuteGameAudio(gameAudio.start, isFastSpin, 2);
    } else {
      stopGameAudio(gameAudio.game, gameAudioId.game);
    }
  }, [isSpinStarting, isSpin, isAudioStartEnding]);

  useEffect(() => {
    if (gameAudio.stop === null) return;

    if (isSpinEnding) {
      isSoundOn
        ? playGameSound(
            gameAudio.stop,
            isGameAudioPlaying.stop,
            isSpinEnding,
            isFastSpin,
            2.67
          )
        : playMuteGameAudio(gameAudio.stop, isFastSpin, 2.67);
    }
  }, [isSpinEnding]);

  useEffect(() => {
    if (gameAudio.game === null) return;

    if (isSoundOn) {
      if (isSpinStarting) unmuteGameAudio(gameAudio.start, gameAudioId.start);
      if (isSpin)
        playGameSound(gameAudio.game, isGameAudioPlaying.game, isSpin);
      if (isSpinEnding) unmuteGameAudio(gameAudio.stop, gameAudioId.stop);
    } else {
      if (isGameAudioPlaying.start)
        fadeGameAudio(gameAudio.start, gameAudioId.start);
      if (isGameAudioPlaying.game)
        fadeGameAudio(gameAudio.game, gameAudioId.game);
      if (isGameAudioPlaying.stop)
        muteGameAudio(gameAudio.stop, gameAudioId.stop);
    }
  }, [isSoundOn]);

  const playFunction = useContractFunction(
    new ethers.Contract(gameAddress, new ethers.utils.Interface(IGAME_ABI)),
    "play"
  );
  const playFunctionState = playFunction.state;
  const playFunctionSend = playFunction.send;

  const tokenApproveFunction = useContractFunction(
    new ethers.Contract(tokenAddress, ERC20Interface),
    "approve"
  );
  const tokenApproveFunctionState = tokenApproveFunction.state;
  const tokenApproveFunctionSend = tokenApproveFunction.send;

  useEffect(() => {
    if (isDemo === isPrevDemo) return;

    if (isDemo) {
      if (DEMO_DECIMALS > tokenDecimals) {
        setBetValue(
          betValue.mul(
            ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
          )
        );
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(
          betValue.div(
            ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)
          )
        );
      }
    } else {
      if (DEMO_DECIMALS > tokenDecimals) {
        setBetValue(
          betValue.div(
            ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
          )
        );
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(
          betValue.mul(
            ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)
          )
        );
      }
    }
    setPrevDemo(isDemo);
  }, [isDemo]);

  useEffect(() => {
    if (isPrevDemo || tokenDecimals === DEMO_DECIMALS) return;

    if (DEMO_DECIMALS > tokenDecimals) {
      setBetValue(
        betValue.div(
          ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)
        )
      );
    } else if (DEMO_DECIMALS < tokenDecimals) {
      setBetValue(
        betValue.mul(
          ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)
        )
      );
    }
  }, [tokenDecimals]);

  // change chance and payout
  useEffect(() => {
    updateChanceAndPayout();
  }, [gameMode]);

  const updateChanceAndPayout = async () => {
    let chance;
    let payout;

    const gameSliceValues = Object.values(gameSlices);
    chance =
      (gameSliceValues.length - gameSliceValues.filter((x) => x === 0).length) /
      gameSliceValues.length;
    payout = Math.max(...gameSliceValues);

    setChance((chance * 100).toFixed(2));
    setPayout(payout.toFixed(2));
  };

  useEffect(() => {
    if (user && (active || externalAccount) && !isPrevDemo) {
      updateMinBet(betValue);
      updateMaxPayout(gameMode, autoplayAmount, betValue); // also max bet
      updateGameData();
    } else if (isPrevDemo) {
      setMinBet(MIN_DEMO_BET_NUMBER);
      setIsMinBet(betValue.lt(MIN_DEMO_BET_NUMBER));
      setMaxBet(MAX_DEMO_BET_NUMBER);
      setIsMaxBet(betValue.gt(MAX_DEMO_BET_NUMBER));
    }
  }, [
    active,
    user,
    isPrevDemo,
    wheelGameObject,
    betValue,
    isAutoplay,
    autoplayAmount,
    gameMode,
  ]);

  const updateMinBet = async (betValue) => {
    if (!wheelGameObject) return;
    let minBet = wheelGameObject.minWager
      .mul(10000)
      .div(
        ethers.BigNumber.from(10000).sub(
          getFee(wheelGameObject.gameVault.gameFee)
        )
      ); //.add(1000);;
    setMinBet(minBet);
    setIsMinBet(betValue.lt(minBet));
  };

  const updateGameData = async () => {
    if (!wheelGameObject) return;
    setGameAddress(wheelGameObject.game.impl);
    setVaultAddress(wheelGameObject.vault);
  };

  const updateMaxPayout = async (gameMode, autoplayAmount, betValue) => {
    if (!wheelGameObject) return;
    let maxPayout = wheelGameObject.maxPayout;
    updateMaxBet(gameMode, autoplayAmount, betValue, maxPayout);
  };

  const updateMaxBet = async (
    gameMode,
    autoplayAmount,
    betValue,
    maxPayout
  ) => {
    if (!wheelGameObject) return;
    const gameSliceValues = Object.values(gameSlices);
    let maxBet = bigNumberMin([
      calculateMaxBet(
        wheelGameObject.maxReservedAmount.div(autoplayAmount),
        ethers.BigNumber.from(Math.max(...gameSliceValues).toFixed(0)),
        getFee(wheelGameObject.gameVault.gameFee)
      ),
      calculateMaxBet(
        maxPayout,
        ethers.BigNumber.from(Math.max(...gameSliceValues).toFixed(0)),
        getFee(wheelGameObject.gameVault.gameFee)
      ),
      user.balance.div(autoplayAmount)
    ]);
    setMaxBet(maxBet);
    setIsMaxBet(betValue.gt(maxBet));
  };

  useEffect(() => {
    if (isGamePlaying) return;
    isMaxBet
      ? displayReduceBetMsg(
          prettyValue(maxBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals),
          isPrevDemo
        )
      : dismissReduceBetMsg();
  }, [isMaxBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    isMinBet
      ? displayIncreaseBetMsg(
          prettyValue(minBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals),
          isPrevDemo
        )
      : dismissIncreaseBetMsg();
  }, [isMinBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    dismissReduceBetMsg();
    dismissIncreaseBetMsg();
    setCheckBet(isMinBet || isMaxBet ? !checkBet : checkBet);
  }, [isPrevDemo, isGamePlaying]);

  useEffect(() => {
    if (isGamePlaying) return;
    if (!isPrevDemo && allowance && user)
      setIsSufficientAllowance(allowance.gt(betValue.mul(autoplayAmount)));
  }, [allowance, betValue, autoplayAmount, isPrevDemo, isGamePlaying, user]);

  useEffect(() => {
    if (txTypeInProgress === "approveVault") {
      if (txStatus === "Mining") {
        displaySentMsg();
      } else if (txStatus === "Exception" || txStatus === "Fail") {
        displayDeclinedMsg();
      } else if (txStatus === "Success") {
        dismissSentMsg();
        displayTxDoneMsg();
      }
    } else if (txTypeInProgress === "gameInProgress") {
      if (txStatus === "Mining") {
        dismissMadeMsg();
        displaySentMsg();
        // setTxRes(res);
      } else if (txStatus === "Exception" || txStatus === "Fail") {
        setGamePlaying(false);
        sendToParent("setGamePlaying", { value: false });
        displayDeclineResult();
      }
    }
  }, [txStatus]);

  useEffect(() => {
    console.log(tokenApproveFunctionState);
    if (tokenApproveFunctionState.status === "Mining") {
      displaySentMsg();
    } else if (
      tokenApproveFunctionState.status === "Exception" ||
      tokenApproveFunctionState.status === "Fail"
    ) {
      displayDeclinedMsg();
    } else if (tokenApproveFunctionState.status === "Success") {
      dismissSentMsg();
      displayTxDoneMsg();
    }
  }, [tokenApproveFunctionState]);

  useEffect(() => {
    console.log(playFunctionState);
    if (playFunctionState.status === "Mining") {
      dismissMadeMsg();
      displaySentMsg();
      // setTxRes(res);
    } else if (
      playFunctionState.status === "Exception" ||
      playFunctionState.status === "Fail"
    ) {
      setGamePlaying(false);
      displayDeclineResult();
    }
  }, [playFunctionState]);

  const playSound = (audio) => {
    if (isSoundOn) {
      audio.play();
    }
  };

  // game results tracking function
  async function onData(log, game) {
    let result;
    if (game === WHEEL_GAME) {
      let iface = new ethers.utils.Interface(IGAME_ABI);
      let events = iface.parseLog(log);
      console.log("events:", events);
      console.log("log:", log);

      result = parseGameResultLuckyWheel(
        {
          user: events.args._user,
          sentValue: events.args._sentValue,
          autoroolAmount: events.args._autoroolAmount,
          amount: events.args._wager,
          gameData: events.args._gameData,
          payoutAmount: events.args._payoutAmount,
          randomValue: events.args._randomValue,
          token: events.args._token,
          event: log,
        },
        tokenDecimals, preferredChainId
      );
    }
    console.log(`${game} result:`, result);
    setGameQueueInit((prev) => {
      return [...prev, {result, game}];
    });
  }

  function checkGameQueue(result, game) {
    if (
      (externalAccount ? externalAccount : account) &&
      result[0].address === (externalAccount ? externalAccount : account)
    ) {
      dismissMadeMsg();
      dismissSentMsg();

      if (game === WHEEL_GAME)
        setGameQueue((prevArray) => [
          ...prevArray,
          {
            items: result,
            is_auto: result.length > 1,
          },
        ]);
    } else {
      for (let i = 0; i < result.length; i++) {
        sendToParent("newGameHistory", {
          ...result[i],
          mode: result[i].mode.toString(),
          slots_amount: result[i].slots_amount.toString(),
          random_value: (result[i].random_value * 100),
        });
      }
    }
  }

  useEffect(() => {
    for (var i = gameQueueInitOffset; i < gameQueueInit.length; i++) {
      setGameQueueInitOffset(i + 1);
      checkGameQueue(gameQueueInit[i].result, gameQueueInit[i].game);
    }
  }, [gameQueueInit]);

  // track wheel games
  useEffect(() => {
    updateWheelGame(wheelGame);
  }, [wheelGame]);

  const updateWheelGame = async (wheelGame) => {
    if ((await wheelGame) && (await wheelGame).game.impl !== wheelAddress)
      setWheelAddress((await wheelGame).game.impl);
    if (
      (await wheelGame) &&
      !deepEqual(await wheelGame, wheelGameObject ? wheelGameObject : {})
    )
      setWheelGameObject(await wheelGame);
  };

  useEffect(() => {
    if (wheelAddress && wss) subscribeWheel();
  }, [wheelAddress]);

  const subscribeWheel = async () => {
    let web3 = new Web3(wss);
    web3.eth
      .subscribe(
        "logs",
        {
          address: wheelAddress,
          topics: [
            new ethers.utils.Interface(IGAME_ABI).getEventTopic(
              "GameSessionPlayed"
            ),
            null,
            null,
          ],
        },
        function (error, result) {
          if (!error) console.log("game sub:", result);
        }
      )
      .on("connected", function (subscriptionId) {
        console.log({ subscriptionId });
      })
      .on("data", function (log) {
        onData(log, WHEEL_GAME);
      })
      .on("changed", function (log) {});
  };

  // handle game mode select
  function handleModeClick(value) {
    switch (true) {
      case value === RISKY_GAME_MODE:
        setGameSlices(RISKY_WHEEL_SLICES_LIST);
        break;
      case value === DEGEN_GAME_MODE:
        setGameSlices(DEGEN_WHEEL_SLICES_LIST);
        break;
      default:
        setGameSlices(SAFE_WHEEL_SLICES_LIST);
    }

    setGameMode(value);
    playSound(buttonsAudio);
  }

  // set game result when transaction is approved
  function displayGameResult(result) {
    const payout_real = result.payout_real_amount;
    const payout = result.payout_amount;
    const prizePosition = calculatePrizePozition(result.random_value);
    setSpin(false);
    setSpinEnding(true);
    setPrizePosition(prizePosition);
    updateAnimationNumbers(prizePosition);

    setTimeout(() => {
      setSpinEnding(false);
      if (payout_real.isZero()) {
        displayLostMsg(false);
      } else {
        displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, false);
      }
      sendToParent("newGameHistory", {
        ...result,
        mode: result.mode.toString(),
        slots_amount: result.slots_amount.toString(),
        random_value: (result.random_value * 100)
      });

      setTimeout(() => {
        setGamePlaying(false);
        setBlocked(false);
        sendToParent("setGamePlaying", { value: false });
      }, 1000);
    }, 4000);
  }

  function displayAUTOGameResult(result) {
    function timer(ms) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
        }, ms);
      });
    }

    async function showRes(res, index) {
      if (index !== 0) await timer(2200);

      // console.log({ index, res });
      const payout_real = res.payout_real_amount;
      const payout = res.payout_amount;
      const prizePosition = calculatePrizePozition(res.random_value);
      setFastSpin(true);
      setSpin(false);
      setSpinStarting(false);
      setSpinEnding(true);
      setPrizePosition(prizePosition);
      updateAnimationNumbers(prizePosition);

      setTimeout(() => {
        setSpinEnding(false);
        if (payout_real.isZero()) {
          displayLostMsg(false);
        } else {
          displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, false);
        }
        sendToParent("newGameHistory", {
          ...res,
          mode: res.mode.toString(),
          slots_amount: res.slots_amount.toString(),
          random_value: (res.random_value * 100).toString(),
        });

        if (index + 1 !== result.length) {
          setTimeout(() => {
            setSpinStarting(true);
          }, 200);
        } else {
          setTimeout(() => {
            setFastSpin(false);
            setGamePlaying(false);
            setBlocked(false);
            sendToParent("setGamePlaying", { value: false });
          }, 1000);
        }
      }, 1500);
    }

    result.reduce(async (a, res, index) => {
      // Wait for the previous item to finish processing
      await a;
      // Process this item
      await showRes(res, index);
    }, Promise.resolve());
  }

  // set transaction declined results
  function displayDeclineResult() {
    dismissMadeMsg();
    dismissSentMsg();
    displayDeclinedMsg();
    setRotateDeg(360);
    setStartDeg(0);
    setSpin(false);
    setSpinStarting(false);
    setSpinEnding(true);

    setTimeout(() => {
      setSpinEnding(false);
      setBlocked(false);
      setGamePlaying(false);
    }, 4000);
  }

  const allowTokenTx = async () => {
    if (!wheelGameObject || !vaultAddress) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      ERC20Interface
    )
    const data = gameContract.interface.encodeFunctionData('approve', [
      vaultAddress,
      MAX_BIG_NUMBER
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: tokenAddress, data, value: 0 });
      setTxTypeInProgress('approveVault');
    } else {
      if (!isMagicConnectorStateVar) {
        tokenApproveFunctionSend(
          vaultAddress,
          MAX_BIG_NUMBER
        )
      } else {
        sendMagicTx({ from: externalAccount, to: tokenAddress, data, value: 0 });
        setTxTypeInProgress('approveVault');
      }
    }
  }

  // request to smart contract
  const rollPlaySendTx = async () => {
    if (!wheelGame || !(await gasPerRoll)) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      new ethers.utils.Interface(IGAME_ABI)
    );
    const data = gameContract.interface.encodeFunctionData("play", [
      tokenAddress,
      partnerReferralAddress,
      autoplayAmount,
      betValue.mul(autoplayAmount),
      ethers.utils.defaultAbiCoder.encode(
        ["uint8", "uint8"],
        [
          WHEEL_MODES_CHAIN[gameMode], // mode
          20, // slots
        ]
      ),
    ]);

    if (externalAccount) {
      sendToParent("sendTx", {
        from: externalAccount,
        to: gameAddress,
        data,
        value: await gasPerRoll,
      });
      sendToParent("setGamePlaying", { value: true });
      setTxTypeInProgress("gameInProgress");
    } else {
      if (!isMagicConnectorStateVar) {
        playFunctionSend(
          tokenAddress,
          partnerReferralAddress,
          autoplayAmount,
          betValue.mul(autoplayAmount),
          ethers.utils.defaultAbiCoder.encode(
            ["uint8", "uint8"],
            [
              WHEEL_MODES_CHAIN[gameMode], // mode
              20, // slots
            ]
          ),
          { value: await gasPerRoll }
        );
      } else {
        sendMagicTx({ from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
        setTxTypeInProgress('gameInProgress');
      }
    }
  };

  function allowToken() {
    allowTokenTx().catch(() => {
      displayDeclinedMsg();
    });
  }

  // start game
  const spin = () => {
    if (user && !isDemo) {
      setGamePlaying(true);
      setBlocked(true);
      setSpinStarting(true);
      displayMadeMsg();

      setTimeout(() => {
        setSpin(true);
        setSpinStarting(false);
      }, 1000);

      rollPlaySendTx().catch(() => {
        setGamePlaying(false);
        displayDeclineResult();
      });
    } else {
      const autoplayArray = Array(autoplayAmount).join(".").split(".");
      function timer(ms) {
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve();
          }, ms);
        });
      }

      async function showRes(index) {
        if (index !== 0) await timer(3200);

        const arrLength = Object.values(gameSlices).length;
        const prizePosition = Math.floor(Math.random() * arrLength);
        const payoutReal = betValue
          .mul(
            ethers.BigNumber.from(
              Number(gameSlices[prizePosition] * 100).toFixed(0)
            )
          )
          .div(100);

        setFastSpin(index + 1 !== autoplayArray.length);
        setSpinStarting(true);
        setSpin(false);
        setSpinEnding(false);

        setTimeout(
          () => {
            setSpin(true);
            setSpinStarting(false);
            setPrizePosition(prizePosition);
            updateAnimationNumbers(prizePosition);

            setTimeout(
              () => {
                dismissSentMsg();
                setSpinEnding(true);
                setSpin(false);

                setTimeout(
                  () => {
                    setSpinEnding(false);
                    if (payoutReal.isZero()) {
                      displayLostMsg(index + 1 !== autoplayArray.length);
                    } else {
                      displayWonMsg(
                        prettyValue(payoutReal, DEMO_DECIMALS) +
                          ` ${DEMO_CURRENCY}`,
                        index + 1 !== autoplayArray.length
                      );
                      setDemoBalance((prevValue) => prevValue.add(payoutReal));
                    }

                    if (index + 1 !== autoplayArray.length) {
                      setTimeout(() => {
                        setSpinStarting(true);
                      }, 700);
                    } else {
                      setTimeout(() => {
                        setFastSpin(false);
                        setGamePlaying(false);
                        setBlocked(false);
                      }, 1000);
                    }
                  },
                  index + 1 !== autoplayArray.length ? 1500 : 4000
                );
              },
              autoplayArray.length > 1 ? 500 : 1500
            );
          },
          index + 1 !== autoplayArray.length ? 500 : 1000
        );
      }

      displaySentMsg();
      setDemoBalance((prevValue) =>
        prevValue.sub(betValue.mul(autoplayArray.length))
      );
      setBlocked(true);
      setGamePlaying(true);

      autoplayArray.reduce(async (a, res, index) => {
        await a;
        // Process this item
        await showRes(index);
        // Wait for the previous item to finish processing
      }, Promise.resolve());
    }
  };

  function calculatePrizePozition(multiplayer) {
    const gameSliceKeys = Object.keys(gameSlices);
    const keysArr = gameSliceKeys.filter(
      (key) => gameSlices[key] === multiplayer
    );

    if (keysArr.length === 1) {
      return keysArr[0];
    } else {
      const index = Math.floor(Math.random() * keysArr.length);
      return keysArr[index];
    }
  }

  // set finale animation degree
  function updateAnimationNumbers(prizePosition) {
    const arrLength = Object.values(gameSlices).length;
    const sliceDeg = 360 / arrLength;
    const offset = sliceDeg * prizePosition;
    const spinValue =
      (prizePosition < arrLength / 2 ? 1 : 2) * 360 - offset + sliceDeg / 2;
    const newStart = spinValue < 360 ? spinValue : spinValue - 360;
    setRotateDeg(spinValue);
    setStartDeg(newStart);
  }

  useEffect(() => {
    // console.log({ gameQueue, isGamePlaying });
    if (gameQueue.length > 0) {
      if (isGamePlaying) {
        if (gameQueue.length === 1) {
          dismissSentMsg();
          setBlocked(true);
          setGamePlaying(true);

          if (gameQueue[0].is_auto) {
            displayAUTOGameResult(gameQueue[0].items);
          } else {
            displayGameResult(gameQueue[0].items[0]);
          }

          setGameQueue((prevArr) =>
            prevArr.filter(
              (item) =>
                item.items[0].transaction_hash !==
                gameQueue[0].items[0].transaction_hash
            )
          );
        }
      }
    }
  }, [gameQueue, isGamePlaying]);

  function handlePlayResultsInQueue() {
    let itemsArray = [];
    gameQueue.forEach((element) => {
      element.items.forEach((item) => {
        itemsArray = [...itemsArray, item];
      });
    });

    let hashesArray = gameQueue.map((item) => {
      return item.items[0].transaction_hash;
    });

    setGameQueue((prevArr) =>
      prevArr.filter(
        (item) => hashesArray.indexOf(item.items[0].transaction_hash) < 0
      )
    );

    dismissMadeMsg();
    dismissSentMsg();
    setBlocked(true);
    setGamePlaying(true);
    setFastSpin(true);
    setSpinStarting(true);
    setTimeout(() => {
      displayAUTOGameResult(itemsArray);
    }, 500);
  }

  useEffect(() => {
    let root = document.documentElement;
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      console.log(param, value);

      switch (param) {
        case "--box-border-color":
        case "--box-bg-color":
          root.style.setProperty(param, value);
          break;

        default:
          break;
      }
    }
  }, [searchParams]);

  return (
    <section
      className={`wheel ${
        !isGamePlaying && gameQueue.length > 0 ? "wheel_with-tx-queue" : ""
      }`}
    >
      {!isGamePlaying && gameQueue.length > 0 ? (
        <div className="wheel__tx-in-queue">
          <p className="wheel__tx-in-queue-text">
            You have {gameQueue.length} unviewed{" "}
            {gameQueue.length > 1 ? "results" : "result"}!
          </p>
          <button
            className="wheel__tx-in-queue-btn"
            type="button"
            onClick={handlePlayResultsInQueue}
          >
            See {gameQueue.length > 1 ? "results" : "result"}
          </button>
        </div>
      ) : (
        <></>
      )}

      <div className="wheel__game-block">
        <div
          className={`wheel__result-block ${
            !isGamePlaying && gameQueue.length > 0
              ? "wheel__result-block_with-tx-queue"
              : ""
          }`}
        >
          {!isGamePlaying && gameQueue.length > 0 ? (
            <button
              className="wheel__tx-in-queue-btn wheel__tx-in-queue-btn_mobile"
              type="button"
              onClick={handlePlayResultsInQueue}
            >
              See {gameQueue.length}{" "}
              {gameQueue.length > 1 ? "results" : "result"}
            </button>
          ) : (
            <></>
          )}

          <div className="wheel__spinner">
            <Spinner
              spinning={isSpin}
              starting={isSpinStarting}
              stoping={isSpinEnding}
              {...{
                rotateDeg,
                startDeg,
                prizePosition,
                isFastSpin,
                gameMode,
                isGamePlaying,
              }}
            />
          </div>
        </div>

        <div
          className={`wheel__bet-block ${
            isBlocked ? "wheel__bet-block_disabled" : ""
          }`}
        >
          <div className="wheel__block-item">
            <p className="wheel__block-title">Game Mode</p>
            <div className="wheel__mode-btns-box">
              <button
                className={`wheel__mode-btn ${
                  gameMode === SAFE_GAME_MODE ? "wheel__mode-btn_selected" : ""
                }`}
                type="button"
                onClick={() => handleModeClick(SAFE_GAME_MODE)}
              >
                <p className="wheel__mode-btn-text">Safe</p>
              </button>
              <button
                className={`wheel__mode-btn ${
                  gameMode === RISKY_GAME_MODE ? "wheel__mode-btn_selected" : ""
                }`}
                type="button"
                onClick={() => handleModeClick(RISKY_GAME_MODE)}
              >
                <p className="wheel__mode-btn-text">Risky</p>
              </button>
              <button
                className={`wheel__mode-btn ${
                  gameMode === DEGEN_GAME_MODE ? "wheel__mode-btn_selected" : ""
                }`}
                type="button"
                onClick={() => handleModeClick(DEGEN_GAME_MODE)}
              >
                <p className="wheel__mode-btn-text">Degen</p>
              </button>
            </div>
          </div>
        </div>

        <div className="wheel__options-block">
          {user && !minBet ? (
            <div className="wheel__options-block-preloader">
              <MiniPreloader />
            </div>
          ) : (
            <GameOptions
              btnText="Spin"
              onClick={spin}
              rolling={isSpin || isSpinStarting || isSpinEnding}
              bet={betValue}
              setBet={setBetValue}
              minMaxBet={{ min: minBet, max: maxBet }}
              queue={gameQueue}
              autoplayText="Autoplay"
              payoutText="Max Payout"
              {...{
                preferredToken,
                active,
                chance,
                payout,
                isSoundOn,
                isBlocked,
                user,
                autoplayAmount,
                setAutoplayAmount,
                switchNetwork,
                isSupportedNetwork,
                isAutoplay,
                demoBalance,
                isMaxBet,
                isMinBet,
                isSufficientAllowance,
                allowToken,
                isDemo,
                demoCoinLogo,
                preferredChainId,
                tokenDecimals,
                betValueWriten,
                setBetValueWriten,
                isDemoSwitch,
                openWalletModal,
                externalAccount,
                sendToParent,
                isExternalWalletConnected,
                isExternalAccountConnection,
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default LuckyWheel;
