/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useContractFunction, useTokenAllowance, ERC20Interface, useCall } from '@usedapp/core';
import { ethers } from "ethers";
import { bigNumberMin, deepEqual, factorial, getFee, prettyValue } from "../../assets/utilis/utilis";
import moment from "moment";

import CoinBox from "./CoinBox/CoinBox";
import GameOptions from "../GameOptions/GameOptions";
import MiniPreloader from "../MiniPreloader/MiniPreloader";

import { Howl } from "howler";
import bet_slider_sound from "../../assets/sounds/bet_slider_sound.wav";
import bet_buttons_sound from "../../assets/sounds/bet_buttons_sound.wav";

import heads from "../../assets/images/coin-flip/heads-icon.png";
import tails from "../../assets/images/coin-flip/tails-icon.png";

import coinflip_game_sound from "../../assets/sounds/coin_game_sound.wav";
import coinflip_start_sound from "../../assets/sounds/coin_start_sound.wav";
import coinflip_stop_sound from "../../assets/sounds/coin_stop_sound.wav";

// coin-flip images
import startBlue from "../../assets/images/coin-flip/start-blue-coin.png";
import startYellow from "../../assets/images/coin-flip/start-yellow-coin.png";
import continueSpinning from "../../assets/images/coin-flip/continue-animation.png";
import stopBlue from "../../assets/images/coin-flip/stop-blue-coin.png";
import stopYellow from "../../assets/images/coin-flip/stop-yellow-coin.png";
import flipBlue from "../../assets/images/coin-flip/fast-flip-blue-to-yellow.png";
import flipYellow from "../../assets/images/coin-flip/fast-flip-yellow-to-blue.png";

import Web3 from "web3";

import {
  parseGameResultCoinFlip,
} from "../../assets/utilis/utilis";

import {
  MIN_COINS_NUMBER,
  MAX_COINS_NUMBER,
  MIN_DEMO_BET_NUMBER,
  MAX_DEMO_BET_NUMBER,
  DEMO_MIN_BET_VALUE,
  MIN_AUTOPLAY_NUMBER,
  IGAME_ABI,
  DEMO_CURRENCY,
  NULL_ADDRESS,
  MAX_BIG_NUMBER,
  DEMO_DECIMALS,
  COIN_FLIP_ID,
  ICONSOLE_ABI,
  COIN_FLIP_GAME
} from "../../assets/utilis/constants";

import "./CoinFlip.css";

function calculateMaxBet(
  maxPayout,
  totalCoins,
  winCoins,
  fee
) {
  let chance = ethers.BigNumber.from(0);
  for (let ii = totalCoins; ii >= winCoins; ii--) {
    chance = chance.add(
      factorial(ethers.BigNumber.from(totalCoins)).div(
        (
          factorial(ethers.BigNumber.from(ii)).mul(factorial(ethers.BigNumber.from(totalCoins - ii)))
        )
      )
    );
  }
  return maxPayout.mul(10000).div(10000 - fee).mul(chance).div(ethers.BigNumber.from(2).pow(totalCoins));
}

// get mega dice
const useCoinFlipGame = async (consoleAddress, tokenAddress, chainId) => {
  const { value, error } = useCall(
    consoleAddress && {
      contract: new ethers.Contract(consoleAddress, ICONSOLE_ABI),
      method: 'getGameWithExtraData',
      args: [COIN_FLIP_ID, tokenAddress]
    },
    { chainId }) ?? {}
  if (error) {
    console.error(error.message)
    return undefined
  }
  else if (!value) {
    return undefined
  }
  return {
    game: {
      id: value?.[0][0].id,
      paused: value?.[0][0].paused,
      name: value?.[0][0].name,
      date: value?.[0][0].date,
      impl: value?.[0][0].impl,
    },
    vault: value?.[0].vault,
    token: value?.[0].token,
    minWager: value?.[0].minWager,
    maxPayout: value?.[0].maxPayout,
    maxReservedAmount: value?.[0].maxReservedAmount,
    gameVault: {
      gameFee: {
        currentFee: value?.[0][6][0].currentFee,
        nextFee: value?.[0][6][0].nextFee,
        startTime: value?.[0][6][0].startTime,
      },
      isPresent: value?.[0][6].isPresent
    }
  }
}

function CoinFlip({
  consoleAddress,
  wss,
  isPrevDemo,
  setPrevDemo,
  isSoundOn,
  displayLostMsg,
  displayWonMsg,
  displayDeclinedMsg,
  displayMadeMsg,
  displaySentMsg,
  displayTxDoneMsg,
  displayReduceBetMsg,
  displayIncreaseBetMsg,
  dismissMadeMsg,
  dismissSentMsg,
  dismissReduceBetMsg,
  dismissIncreaseBetMsg,
  user,
  switchNetwork,
  isSupportedNetwork,
  demoBalance,
  setDemoBalance,
  playGameSound,
  stopGameAudio,
  fadeGameAudio,
  muteGameAudio,
  unmuteGameAudio,
  playMuteGameAudio,
  isGamePlaying,
  setGamePlaying,
  gasPerRoll,
  isDemo,
  demoCoinLogo,
  partnerReferralAddress,
  tokenAddress,
  isBlocked,
  setBlocked,
  preferredChainId,
  tokenDecimals,
  searchParams,
  isDemoSwitch,
  openWalletModal,
  externalAccount,
  account,
  active,
  sendToParent,
  txStatus,
  isExternalWalletConnected,
  isExternalAccountConnection,
  sendMagicTx,
  isMagicConnectorStateVar,
  preferredToken
}) {
  const sliderAudio = new Howl({
    src: [bet_slider_sound],
  });
  const buttonsAudio = new Howl({
    src: [bet_buttons_sound],
  });

  const [totalCoins, setTotalCoins] = useState(
    localStorage.getItem("totalCoins") !== null
      ? JSON.parse(localStorage.getItem("totalCoins"))
      : 1
  );
  const [totalValue, setTotalValue] = useState(
    localStorage.getItem("totalCoins") !== null
      ? JSON.parse(localStorage.getItem("totalCoins"))
      : 1
  );
  const [winCoins, setWinCoins] = useState(
    localStorage.getItem("winCoins") !== null
      ? JSON.parse(localStorage.getItem("winCoins"))
      : 1
  );
  const [winValue, setWinValue] = useState(
    localStorage.getItem("winCoins") !== null
      ? JSON.parse(localStorage.getItem("winCoins"))
      : 1
  );
  const [isBlueTop, setBlueTop] = useState(
    localStorage.getItem("isBlueTop") !== null
      ? JSON.parse(localStorage.getItem("isBlueTop"))
      : true
  );

  const [minBet, setMinBet] = useState(ethers.BigNumber.from(0));
  const [maxBet, setMaxBet] = useState(ethers.BigNumber.from(0));
  const [checkBet, setCheckBet] = useState(true);
  const [betValue, setBetValue] = useState(DEMO_MIN_BET_VALUE);
  const [betValueWriten, setBetValueWriten] = useState(prettyValue(betValue, DEMO_DECIMALS));
  const isAutoplay = true;
  const [autoplayAmount, setAutoplayAmount] = useState(MIN_AUTOPLAY_NUMBER);
  const [payout, setPayout] = useState(0);
  const [chance, setChance] = useState(0);
  const [isFliping, setFliping] = useState(false);
  const [isStopFliping, setStopFliping] = useState(false);
  const [isFastFlip, setFastFlip] = useState(false);
  const [isAnimEnd, setAnimEnd] = useState(false);
  const [isReturnAnim, setReturnAnim] = useState(false);
  const [isToggleAnim, setToggleAnim] = useState(false);
  const [isToggleSideDisabled, setToggleSideDisabled] = useState(false);
  const [isBlueWin, setBlueWin] = useState(false);
  const [isResult, setResult] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [isAuto, setAuto] = useState(false);
  const [time, setTime] = useState(0);
  const icons = { heads: heads, tails: tails };
  const [isMaxBet, setIsMaxBet] = useState(false);
  const [isMinBet, setIsMinBet] = useState(false);
  const [isSufficientAllowance, setIsSufficientAllowance] = useState(false);
  const [gameAddress, setGameAddress] = useState(NULL_ADDRESS)
  const [vaultAddress, setVaultAddress] = useState(NULL_ADDRESS)

  const coinFlipGame = useCoinFlipGame(consoleAddress, tokenAddress, preferredChainId);
  const [coinFlipGameObject, setCoinFlipGameObject] = useState(null);
  const [coinFlipAddress, setCoinFlipAddress] = useState(null);
  const [txTypeInProgress, setTxTypeInProgress] = useState(null);

  // load images for coin flip animation
  const [isCoinImagesLoaded, setCoinImagesLoaded] = useState(false);

  // set coin flip game sounds
  const [isAudioStartEnding, setAudioStartEnding] = useState(false);
  const [isGameAudioPlaying, setGameAudioPlaying] = useState({
    start: false,
    game: false,
    stop: false,
  });
  const [gameAudioId, setGameAudioId] = useState({
    start: 0,
    game: 0,
    stop: 0,
  });
  const [gameAudio, setGameAudio] = useState({
    start: null,
    game: null,
    stop: null,
  });

  // track games
  const [gameQueue, setGameQueue] = useState([]);
  const [gameQueueInit, setGameQueueInit] = useState([]);
  const [gameQueueInitOffset, setGameQueueInitOffset] = useState(0);

  const allowance = useTokenAllowance(
    tokenAddress,
    externalAccount ? externalAccount : account,
    vaultAddress,
    {
      refresh: "everyBlock",
      chainId: preferredChainId
    }
  )

  useEffect(() => {
    const audio = new Howl({
      src: [coinflip_game_sound],
      onplay: function (id) {
        setGameAudioId((prevId) => ({ ...prevId, game: id }));
        setGameAudioPlaying((prevState) => ({ ...prevState, game: true }));
        audio.loop(true, id);
      },
      onfade: function (id) {
        setGameAudioPlaying((prevState) => ({ ...prevState, game: false }));
        audio.loop(false, id);
      },
      onstop: function (id) {
        setGameAudioPlaying((prevState) => ({ ...prevState, game: false }));
        audio.loop(false, id);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio((prevAudio) => ({ ...prevAudio, game: audio }));
  }, []);

  useEffect(() => {
    const audio = new Howl({
      src: [coinflip_start_sound],
      onplay: function (id) {
        setGameAudioId((prevId) => ({ ...prevId, start: id }));
        setGameAudioPlaying((prevState) => ({ ...prevState, start: true }));
        setAudioStartEnding(false);
      },
      onfade: function () {
        setGameAudioPlaying((prevState) => ({
          ...prevState,
          start: false,
        }));
      },
      onend: function () {
        setAudioStartEnding(true);
        setGameAudioPlaying((prevState) => ({
          ...prevState,
          start: false,
        }));
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio((prevAudio) => ({ ...prevAudio, start: audio }));
  }, []);

  useEffect(() => {
    const audio = new Howl({
      src: [coinflip_stop_sound],
      onplay: function (id) {
        setGameAudioId((prevId) => ({ ...prevId, stop: id }));
        setGameAudioPlaying((prevState) => ({ ...prevState, stop: true }));
      },
      onfade: function () {
        setGameAudioPlaying((prevState) => ({ ...prevState, stop: false }));
      },
      onend: function () {
        setGameAudioPlaying((prevState) => ({ ...prevState, stop: false }));
        setAudioStartEnding(false);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio((prevAudio) => ({ ...prevAudio, stop: audio }));
  }, []);

  useEffect(() => {
    const coinImagesArr = [
      startBlue,
      startYellow,
      continueSpinning,
      stopBlue,
      stopYellow,
      flipBlue,
      flipYellow,
    ];

    Promise.all(coinImagesArr.map((img) => loadImage(img)))
      .then(() => {
        // console.log("coin images loaded");
        setCoinImagesLoaded(true);
      })
      .catch(() => console.error("could not load coin images"));

  }, []);

  const loadImage = (path) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(path);
      };
      img.onerror = () => reject();

      img.src = path;
    });

  const playFunction = useContractFunction(
    new ethers.Contract(
      gameAddress,
      new ethers.utils.Interface(IGAME_ABI)
    ),
    'play'
  )
  const playFunctionState = playFunction.state;
  const playFunctionSend = playFunction.send;

  const tokenApproveFunction = useContractFunction(
    new ethers.Contract(
      tokenAddress,
      ERC20Interface
    ),
    'approve'
  )
  const tokenApproveFunctionState = tokenApproveFunction.state;
  const tokenApproveFunctionSend = tokenApproveFunction.send;

  useEffect(() => {

    if (isDemo === isPrevDemo) return;

    if (isDemo) {
      if (DEMO_DECIMALS > tokenDecimals) {
        setBetValue(betValue.mul(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)))
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(betValue.div(ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)))
      }
    } else {
      if (DEMO_DECIMALS > tokenDecimals) {
        setBetValue(betValue.div(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)))
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(betValue.mul(ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)))
      }
    }
    setPrevDemo(isDemo);

  }, [isDemo])

  useEffect(() => {

    if (isPrevDemo || tokenDecimals === DEMO_DECIMALS) return;

    if (DEMO_DECIMALS > tokenDecimals) {
      setBetValue(betValue.div(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)))
    } else if (DEMO_DECIMALS < tokenDecimals) {
      setBetValue(betValue.mul(ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)))
    }

  }, [tokenDecimals])

  // change chance and payout
  useEffect(() => {
    updateChanceAndPayout();
  }, [totalCoins, winCoins]);

  const updateChanceAndPayout = async () => {
    let chance = 0;

    function factorial(n) {
      return n > 1 ? n * factorial(n - 1) : 1;
    }

    for (let i = totalCoins; i >= winCoins; i--) {
      const c =
        factorial(totalCoins) / (factorial(i) * factorial(totalCoins - i));
      chance += c * Math.pow(0.5, totalCoins);
    }

    let payout;
    if (coinFlipGameObject) {
      payout = (1 / chance) * (10000 - getFee(coinFlipGameObject.gameVault.gameFee)) / 10000
    } else {
      payout = (1 / chance) * (10000 - 300) / 10000
    }

    setChance((chance * 100).toFixed(2));
    setPayout(payout.toFixed(2));
  }

  useEffect(() => {
    if (user && (active || externalAccount) && !isPrevDemo) {
      updateMinBet(betValue);
      updateMaxPayout(totalCoins, winCoins, autoplayAmount, betValue); // also max bet
      updateGameData();
    } else if (isPrevDemo) {
      setMinBet(MIN_DEMO_BET_NUMBER);
      setIsMinBet(betValue.lt(MIN_DEMO_BET_NUMBER));
      setMaxBet(MAX_DEMO_BET_NUMBER);
      setIsMaxBet(betValue.gt(MAX_DEMO_BET_NUMBER));
    }
  }, [active, user, isPrevDemo, coinFlipGameObject, betValue, isAutoplay, autoplayAmount, totalCoins, winCoins]);

  const updateMinBet = async (betValue) => {
    if (!coinFlipGameObject) return;
    let minBet = coinFlipGameObject.minWager.mul(10000).div(ethers.BigNumber.from(10000).sub(getFee(coinFlipGameObject.gameVault.gameFee))); //.add(1000);;
    setMinBet(minBet);
    setIsMinBet(betValue.lt(minBet));
  }

  const updateGameData = async () => {
    if (!coinFlipGameObject) return;
    setGameAddress(coinFlipGameObject.game.impl)
    setVaultAddress(coinFlipGameObject.vault)
  }

  const updateMaxPayout = async (totalCoins, winCoins, autoplayAmount, betValue) => {
    if (!coinFlipGameObject) return;
    let maxPayout = coinFlipGameObject.maxPayout;
    updateMaxBet(totalCoins, winCoins, autoplayAmount, betValue, maxPayout)
  }

  const updateMaxBet = async (totalCoins, winCoins, autoplayAmount, betValue, maxPayout) => {
    if (!coinFlipGameObject) return;
    let maxBet = bigNumberMin(
      [
        calculateMaxBet(
          coinFlipGameObject.maxReservedAmount.div(autoplayAmount), totalCoins, winCoins, getFee(coinFlipGameObject.gameVault.gameFee)
        ),
        calculateMaxBet(
          maxPayout, totalCoins, winCoins, getFee(coinFlipGameObject.gameVault.gameFee)
        ),
        user.balance.div(autoplayAmount)
      ]
    );
    setMaxBet(maxBet)
    setIsMaxBet(betValue.gt(maxBet));
  }

  useEffect(() => {
    if (isGamePlaying) return;
    isMaxBet
      ? displayReduceBetMsg(prettyValue(maxBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals), isPrevDemo)
      : dismissReduceBetMsg();
  }, [isMaxBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    isMinBet
      ? displayIncreaseBetMsg(prettyValue(minBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals), isPrevDemo)
      : dismissIncreaseBetMsg();
  }, [isMinBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    dismissReduceBetMsg();
    dismissIncreaseBetMsg();
    setCheckBet((isMinBet || isMaxBet) ? !checkBet : checkBet);
  }, [isPrevDemo, isGamePlaying])

  useEffect(() => {
    if (isGamePlaying) return;
    if (!isPrevDemo && allowance && user) setIsSufficientAllowance(allowance.gt(betValue.mul(autoplayAmount)));
  }, [allowance, betValue, autoplayAmount, isPrevDemo, isGamePlaying, user]);

  // control games sounds
  useEffect(() => {
    if (gameAudio.game === null || gameAudio.start === null) return;

    if (isFliping) {
      isAudioStartEnding
        ? playGameSound(gameAudio.game, isGameAudioPlaying.game, isFliping)
        : isSoundOn
          ? playGameSound(
            gameAudio.start,
            isGameAudioPlaying.start,
            isFliping,
            isFastFlip,
            2
          )
          : playMuteGameAudio(gameAudio.start, isFastFlip, 2);
    } else {
      stopGameAudio(gameAudio.game, gameAudioId.game);
    }
  }, [isFliping, isAudioStartEnding]);

  useEffect(() => {
    if (gameAudio.stop === null) return;

    if (isStopFliping) {
      isSoundOn
        ? playGameSound(
          gameAudio.stop,
          isGameAudioPlaying.stop,
          isStopFliping,
          isFastFlip,
          4
        )
        : playMuteGameAudio(gameAudio.stop, isFastFlip, 4);
    }
  }, [isStopFliping]);

  useEffect(() => {
    if (gameAudio.game === null) return;

    if (isSoundOn) {
      if (isFliping && isAudioStartEnding)
        playGameSound(gameAudio.game, isGameAudioPlaying.game, isFliping);
      if (isFliping && !isAudioStartEnding)
        unmuteGameAudio(gameAudio.start, gameAudioId.start);
      if (isStopFliping) unmuteGameAudio(gameAudio.stop, gameAudioId.stop);
    } else {
      if (isGameAudioPlaying.start)
        muteGameAudio(gameAudio.start, gameAudioId.start);
      if (isGameAudioPlaying.game)
        fadeGameAudio(gameAudio.game, gameAudioId.game);
      if (isGameAudioPlaying.stop)
        muteGameAudio(gameAudio.stop, gameAudioId.stop);
    }
  }, [isSoundOn]);

  useEffect(() => {
    if (txTypeInProgress === 'approveVault') {
      if (txStatus === 'Mining') {
        displaySentMsg();
      } else if (txStatus === 'Exception' || txStatus === 'Fail') {
        displayDeclinedMsg();
      }
      else if (txStatus === 'Success') {
        dismissSentMsg();
        displayTxDoneMsg();
      }
    } else if (txTypeInProgress === 'gameInProgress') {
      if (txStatus === 'Mining') {
        dismissMadeMsg();
        displaySentMsg();
        // setTxRes(res);
      } else if (txStatus === 'Exception' || txStatus === 'Fail') {
        setGamePlaying(false);
        sendToParent("setGamePlaying", { value: false });
        displayDeclineResult();
      }
    }
  }, [txStatus])

  useEffect(() => {
    console.log(tokenApproveFunctionState)
    if (tokenApproveFunctionState.status === 'Mining') {
      displaySentMsg();
    } else if (tokenApproveFunctionState.status === 'Exception' || tokenApproveFunctionState.status === 'Fail') {
      displayDeclinedMsg();
    }
    else if (tokenApproveFunctionState.status === 'Success') {
      dismissSentMsg();
      displayTxDoneMsg();
    }
  }, [tokenApproveFunctionState])

  useEffect(() => {
    console.log(playFunctionState)
    if (playFunctionState.status === 'Mining') {
      dismissMadeMsg();
      displaySentMsg();
      // setTxRes(res);
    } else if (playFunctionState.status === 'Exception' || playFunctionState.status === 'Fail') {
      setGamePlaying(false);
      displayDeclineResult();
    }
  }, [playFunctionState])

  const playSound = (audio) => {
    if (isSoundOn) {
      audio.play();
    }
  };

  // game results tracking function
  async function onData(log, game) {
    let result;
    if (game === COIN_FLIP_GAME) {
      let iface = new ethers.utils.Interface(IGAME_ABI);
      let events = iface.parseLog(log);
      console.log("events:", events);
      console.log("log:", log);

      result = parseGameResultCoinFlip({
        user: events.args._user,
        sentValue: events.args._sentValue,
        autoroolAmount: events.args._autoroolAmount,
        amount: events.args._wager,
        gameData: events.args._gameData,
        payoutAmount: events.args._payoutAmount,
        randomValue: events.args._randomValue,
        token: events.args._token,
        event: log,
      }, tokenDecimals, preferredChainId);
    }
    console.log(`${game} result:`, result);
    setGameQueueInit((prev) => {
      return [...prev, {result, game}];
    });
  }

  function checkGameQueue(result, game) {
    if ((externalAccount ? externalAccount : account) && result[0].address === (externalAccount ? externalAccount : account)) {
      dismissMadeMsg();
      dismissSentMsg();

      if (game === COIN_FLIP_GAME)
        setGameQueue((prevArray) => [
          ...prevArray,
          {
            items: result,
            is_auto: result.length > 1,
          },
        ]);
    } else {
      for (let i = 0; i < result.length; i++) {
        sendToParent('newGameHistory', {
          ...result[i],
          coins_to_win: result[i].coins_to_win.toString(),
          coins_total: result[i].coins_total.toString(),
          random_value: result[i].random_value.toString()
        })
      }
    }
  }

  useEffect(() => {
    for (var i = gameQueueInitOffset; i < gameQueueInit.length; i++) {
      setGameQueueInitOffset(i + 1);
      checkGameQueue(gameQueueInit[i].result, gameQueueInit[i].game);
    }
  }, [gameQueueInit]);

  useEffect(() => {
    updateCoinFlipGame(coinFlipGame);
  }, [coinFlipGame]);

  const updateCoinFlipGame = async (coinFlipGame) => {
    if ((await coinFlipGame) && (await coinFlipGame).game.impl !== coinFlipAddress) setCoinFlipAddress((await coinFlipGame).game.impl);
    if ((await coinFlipGame) && !deepEqual((await coinFlipGame), coinFlipGameObject ? coinFlipGameObject : {})) setCoinFlipGameObject((await coinFlipGame))
  }

  useEffect(() => {
    if (coinFlipAddress && wss) subscribeCoinFlip();
  }, [coinFlipAddress])

  const subscribeCoinFlip = async () => {
    let web3 = new Web3(wss);
    web3.eth.subscribe(
      "logs",
      {
        address: coinFlipAddress,
        topics: [
          new ethers.utils.Interface(IGAME_ABI).getEventTopic('GameSessionPlayed'),
          null,
          null,
        ],
      },
      function (error, result) {
        if (!error) console.log("game sub:", result);
      }
    ).on("connected", function (subscriptionId) {
      console.log({ subscriptionId });
    })
      .on("data", function (log) {
        onData(log, COIN_FLIP_GAME);
      })
      .on("changed", function (log) { });;
  };

  // handle coins inputs change
  function handleCoinsChange(evt) {
    const input = evt.target;
    const name = input.name;
    let value = input.value.replace(/[^0-9]/g, "");

    switch (name) {
      case "total":
        setTotalValue(value);
        break;
      case "win":
        setWinValue(value);
        break;
      default:
        return null;
    }
  }

  // check coins value on input blur
  function handleCoinsBlur(evt) {
    const input = evt.target;
    const name = input.name;
    let value = input.value;
    value =
      Number(value) < MIN_COINS_NUMBER
        ? MIN_COINS_NUMBER
        : Number(value) > MAX_COINS_NUMBER
          ? MAX_COINS_NUMBER
          : Number(value);
    let min = MIN_COINS_NUMBER;

    switch (name) {
      case "total":
        setTotalCoins(value);
        setTotalValue(value);
        localStorage.setItem("totalCoins", value);

        if (winCoins > value) {
          setWinCoins(value);
          setWinValue(value);
          localStorage.setItem("winCoins", value);
        }
        if (value >= 6 && winCoins < 2) {
          setWinCoins(MIN_COINS_NUMBER + 1);
          setWinValue(MIN_COINS_NUMBER + 1);
          localStorage.setItem("winCoins", MIN_COINS_NUMBER + 1);
        }
        if (value >= 9 && winCoins < 3) {
          setWinCoins(MIN_COINS_NUMBER + 2);
          setWinValue(MIN_COINS_NUMBER + 2);
          localStorage.setItem("winCoins", MIN_COINS_NUMBER + 2);
        }
        break;
      case "win":
        value = Number(value) > totalCoins ? totalCoins : Number(value);
        if (totalCoins >= 6) min = MIN_COINS_NUMBER + 1;
        if (totalCoins >= 9) min = MIN_COINS_NUMBER + 2;

        const newVal = value >= min ? value : min;
        setWinCoins(newVal);
        setWinValue(newVal);
        localStorage.setItem("winCoins", newVal);
        break;
      default:
        return null;
    }

    playSound(sliderAudio);
  }

  // handle coins slider change
  function handleSliderChange(evt) {
    const input = evt.target;
    const name = input.name;
    let value = input.value;
    let min = MIN_COINS_NUMBER;
    sliderAudio.volume(0.65);

    switch (name) {
      case "total":
        setTotalCoins(value);
        setTotalValue(value);
        localStorage.setItem("totalCoins", value);

        if (winCoins > value) {
          setWinCoins(value);
          setWinValue(value);
          localStorage.setItem("winCoins", value);
        }
        if (value >= 6 && winCoins < 2) {
          setWinCoins(MIN_COINS_NUMBER + 1);
          setWinValue(MIN_COINS_NUMBER + 1);
          localStorage.setItem("winCoins", MIN_COINS_NUMBER + 1);
        }
        if (value >= 9 && winCoins < 3) {
          setWinCoins(MIN_COINS_NUMBER + 2);
          setWinValue(MIN_COINS_NUMBER + 2);
          localStorage.setItem("winCoins", MIN_COINS_NUMBER + 2);
        }

        if (value !== totalCoins) playSound(sliderAudio);
        break;
      case "win":
        value = value > totalCoins ? totalCoins : value;
        if (totalCoins >= 6) min = MIN_COINS_NUMBER + 1;
        if (totalCoins >= 9) min = MIN_COINS_NUMBER + 2;

        const newVal = value >= min ? value : min;
        setWinCoins(newVal);
        setWinValue(newVal);
        localStorage.setItem("winCoins", newVal);

        if (value <= totalCoins && value !== winCoins && value >= min)
          playSound(sliderAudio);
        break;
      default:
        return null;
    }
  }

  // handle winning side click
  function handleSideClick() {
    setBlueTop(!isBlueTop);
    localStorage.setItem("isBlueTop", !isBlueTop);

    setToggleAnim(true);
    setToggleSideDisabled(true);
    setTimeout(() => {
      setToggleAnim(false);
      setToggleSideDisabled(false);
    }, 500);

    playSound(buttonsAudio);
  }

  // set game result when transaction is approved
  function displayGameResult(result) {
    const payout_real = result.payout_real_amount;
    const payout = result.payout_amount;
    setBlueWin(result.res);
    setFliping(false);
    setStopFliping(true);
    setResult(false);
    setTime(0);

    setTimeout(() => {
      setStopFliping(false);
      setAnimEnd(true);
      if (payout_real.isZero()) {
        displayLostMsg(false);
      } else {
        displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, false);
      }
      sendToParent('newGameHistory', {
        ...result,
        coins_to_win: result.coins_to_win.toString(),
        coins_total: result.coins_total.toString(),
        random_value: result.random_value.toString()
      })
    }, 4000);

    setTimeout(() => {
      setAnimEnd(false);
      setReturnAnim(true);
    }, 5000);

    setTimeout(() => {
      setBlocked(false);
      setGamePlaying(false);
      setReturnAnim(false);
      sendToParent("setGamePlaying", { value: false });
    }, 5500);
  }

  function displayAUTOGameResult(result) {
    function timer(ms) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
        }, ms);
      });
    }

    async function showRes(res, index) {
      if (index !== 0) await timer(2200);

      console.log({ index, res });
      const payout_real = res.payout_real_amount;
      const payout = res.payout_amount;
      setBlueWin(res.res);
      setFliping(false);
      setStopFliping(true);
      setAuto(true);
      setResult(false);
      setTime(0);

      setTimeout(() => {

        if (payout_real.isZero()) {
          displayLostMsg(true);
        } else {
          displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, true);
        }
        sendToParent('newGameHistory', {
          ...res,
          coins_to_win: res.coins_to_win.toString(),
          coins_total: res.coins_total.toString(),
          random_value: res.random_value.toString()
        })

        if (index + 1 !== result.length) {
          setTimeout(() => {
            setFliping(true);
            setStopFliping(false);
          }, 200);
        } else {
          setStopFliping(false);
          setFastFlip(false);
          setAnimEnd(true);

          setTimeout(() => {
            setAnimEnd(false);
            setReturnAnim(true);
            setAuto(false);
          }, 1000);

          setTimeout(() => {
            setBlocked(false);
            setGamePlaying(false);
            sendToParent("setGamePlaying", { value: false });
            setReturnAnim(false);
          }, 1500);
        }
      }, 1000);
    }

    result.reduce(async (a, res, index) => {
      // Wait for the previous item to finish processing
      await a;
      // Process this item
      await showRes(res, index);
    }, Promise.resolve());
  }

  // set transaction declined results
  function displayDeclineResult() {
    setBlueWin(
      Array(winCoins)
        .fill(isBlueTop)
        .concat(Array(totalCoins - winCoins).fill(!isBlueTop))
    );
    dismissMadeMsg();
    dismissSentMsg();
    displayDeclinedMsg();
    setFliping(false);
    setStopFliping(true);
    setTime(0);

    setTimeout(() => {
      setStopFliping(false);
      setBlocked(false);
      setGamePlaying(false);
    }, 4000);
  }

  const allowTokenTx = async () => {
    if (!coinFlipGameObject || !vaultAddress) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      ERC20Interface
    )
    const data = gameContract.interface.encodeFunctionData('approve', [
      vaultAddress,
      MAX_BIG_NUMBER
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: tokenAddress, data, value: 0 });
      setTxTypeInProgress('approveVault');
    } else {
      if (!isMagicConnectorStateVar) {
        tokenApproveFunctionSend(
          vaultAddress,
          MAX_BIG_NUMBER
        )
      } else {
        sendMagicTx({ from: externalAccount, to: tokenAddress, data, value: 0 });
        setTxTypeInProgress('approveVault');
      }
    }
  }

  // request to smart contract
  const rollPlaySendTx = async () => {
    if (!(await coinFlipGame) || !(await gasPerRoll)) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      new ethers.utils.Interface(IGAME_ABI)
    )
    const data = gameContract.interface.encodeFunctionData('play', [
      tokenAddress,
      partnerReferralAddress,
      autoplayAmount,
      betValue.mul(autoplayAmount),
      ethers.utils.defaultAbiCoder.encode(["bool", "uint256", "uint256"], [isBlueTop, totalCoins, winCoins]),
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
      sendToParent("setGamePlaying", { value: true });
      setTxTypeInProgress('gameInProgress');
    } else {
      if (!isMagicConnectorStateVar) {
        playFunctionSend(
          tokenAddress,
          partnerReferralAddress,
          autoplayAmount,
          betValue.mul(autoplayAmount),
          ethers.utils.defaultAbiCoder.encode(["bool", "uint256", "uint256"], [isBlueTop, totalCoins, winCoins]),
          { value: await gasPerRoll }
        )
      } else {
        sendMagicTx({ from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
        setTxTypeInProgress('gameInProgress');
      }
    }
  };

  function allowToken() {
    allowTokenTx()
      .catch(() => {
        displayDeclinedMsg();
      });
  }

  // start game
  const flip = () => {
    if (user && !isDemo) {
      setGamePlaying(true);
      setTime(moment());
      setFliping(true);
      setBlocked(true);
      displayMadeMsg();

      rollPlaySendTx()
        .catch(() => {
          setGamePlaying(false);
          displayDeclineResult();
        });
    } else {
      const autoplayArray = Array(autoplayAmount).join(".").split(".");
      function timer(ms) {
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve();
          }, ms);
        });
      }

      async function showRes(index) {
        if (index !== 0) await timer(2200);
        let winning = ethers.BigNumber.from(0);
        let wonCoins = 0;
        let res = [];
        for (let ii = 0; ii < totalCoins; ii++) {
          // (18439 % 10) / 1
          // (18439 % 100) / 10
          // (18439 % 1000) / 100
          // (18439 % 100) / 10
          // (18439 % 100) / 10

          // 1 = true; 0 = false
          let randomValue = Math.round(Math.random()) === 1;
          res.push(randomValue);
          if (randomValue === isBlueTop) {
            wonCoins++;
          }
        }

        if (wonCoins >= winCoins) {
          let chance = ethers.BigNumber.from(0);
          for (let ii = totalCoins; ii >= winCoins; ii--) {
            chance = chance.add(
              factorial(ethers.BigNumber.from(totalCoins)).div(
                (
                  factorial(ethers.BigNumber.from(ii)).mul(factorial(ethers.BigNumber.from(totalCoins - ii)))
                )
              )
            );
          }
          winning = betValue.mul(ethers.BigNumber.from(2).pow(totalCoins)).div(chance).mul(10000 - 300).div(10000)
        }

        setFliping(true);
        setStopFliping(false);
        setFastFlip(true);

        setTimeout(() => {
          dismissSentMsg();
          setFliping(false);
          setStopFliping(true);
          setAuto(true);
          setBlueWin(res);

          setTimeout(() => {
            if (winning.isZero()) {
              displayLostMsg(index + 1 !== autoplayArray.length);
            } else {
              displayWonMsg(prettyValue(winning, DEMO_DECIMALS) + ` ${DEMO_CURRENCY}`, index + 1 !== autoplayArray.length);
              setDemoBalance((prevValue) => prevValue.add(winning));
            }

            if (index + 1 === autoplayArray.length) {
              setTimeout(() => {
                setStopFliping(false);
                setFastFlip(false);
                setAnimEnd(true);
              }, 10);

              setTimeout(() => {
                setAnimEnd(false);
                setReturnAnim(true);
                setAuto(false);
              }, 1000);

              setTimeout(() => {
                setBlocked(false);
                setReturnAnim(false);
              }, 1500);
            }
          }, 1000);
        }, 1000);
      }

      displaySentMsg();
      setDemoBalance(
        (prevValue) => prevValue.sub(betValue.mul(autoplayArray.length))
      );
      setBlocked(true);

      autoplayArray.reduce(async (a, res, index) => {
        await a;
        // Process this item
        await showRes(index);
        // Wait for the previous item to finish processing
      }, Promise.resolve());
    }
  };

  // check results every 2s
  useEffect(() => {
    if (!Boolean(time)) return;

    const now = moment();
    const diff = Math.abs(time.diff(now)) % 2000;

    const timer = setInterval(() => {
      if (diff <= 30 || 2000 - diff <= 30) {
        if (isResult) {
          if (gameQueue.length > 0) {
            if (isGamePlaying) {
              if (gameQueue.length === 1) {
                dismissSentMsg();

                if (gameQueue[0].is_auto) {
                  setFastFlip(true);
                  displayAUTOGameResult(gameQueue[0].items);
                  setGameQueue((prevArr) =>
                    prevArr.filter(
                      (item) =>
                        item.items[0].transaction_hash !==
                        gameQueue[0].items[0].transaction_hash
                    )
                  );
                } else {
                  displayGameResult(gameQueue[0].items[0]);
                  setGameQueue((prevArr) =>
                    prevArr.filter(
                      (item) =>
                        item.items[0].transaction_hash !==
                        gameQueue[0].items[0].transaction_hash
                    )
                  );
                }
              }
            }
          }
        }
      }

      setTimerValue(timerValue + 1);
      clearInterval(timer);
    }, 100);
  }, [timerValue, time]);

  useEffect(() => {
    console.log({ gameQueue, isGamePlaying });
    if (!isResult) setResult(gameQueue.length > 0);
  }, [gameQueue]);

  function handlePlayResultsInQueue() {
    let itemsArray = [];
    gameQueue.forEach((element) => {
      element.items.forEach((item) => {
        itemsArray = [...itemsArray, item];
      });
    });

    let hashesArray = gameQueue.map((item) => {
      return item.items[0].transaction_hash;
    });

    setGameQueue((prevArr) =>
      prevArr.filter(
        (item) => hashesArray.indexOf(item.items[0].transaction_hash) < 0
      )
    );

    const total = itemsArray[0].coins_total;
    const win = itemsArray[0].coins_to_win;
    const side = itemsArray[0].winning_side;
    setTotalCoins(total);
    setTotalValue(total);
    setWinCoins(win);
    setWinValue(win);
    setBlueTop(side);
    localStorage.setItem("totalCoins", total);
    localStorage.setItem("winCoins", win);
    localStorage.setItem("isBlueTop", side);

    dismissMadeMsg();
    dismissSentMsg();
    setBlocked(true);
    setGamePlaying(true);
    setFastFlip(true);
    setFliping(true);
    setStopFliping(false);
    displayAUTOGameResult(itemsArray);
  }

  useEffect(() => {
    let root = document.documentElement;
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      console.log(param, value);

      switch (param) {
        case '--box-border-color':
        case '--box-bg-color':
          root.style.setProperty(param, value);
          break;

        default:
          break;
      }
    }
  }, [searchParams])

  return (
    <section
      className={`flip ${!isGamePlaying && gameQueue.length > 0 ? "flip_with-tx-queue" : ""
        }`}
    >
      {!isGamePlaying && gameQueue.length > 0 ? (
        <div className="flip__tx-in-queue">
          <p className="flip__tx-in-queue-text">
            You have {gameQueue.length} unviewed{" "}
            {gameQueue.length > 1 ? "results" : "result"}!
          </p>
          <button
            className="flip__tx-in-queue-btn"
            type="button"
            onClick={handlePlayResultsInQueue}
          >
            See {gameQueue.length > 1 ? "results" : "result"}
          </button>
        </div>
      ) : (
        <></>
      )}

      <div className="flip__game-block">
        <div
          className={`flip__result-block ${!isGamePlaying && gameQueue.length > 0
            ? "flip__result-block_with-tx-queue"
            : ""
            }`}
        >
          {!isGamePlaying && gameQueue.length > 0 ? (
            <button
              className="flip__tx-in-queue-btn flip__tx-in-queue-btn_mobile"
              type="button"
              onClick={handlePlayResultsInQueue}
            >
              See {gameQueue.length}{" "}
              {gameQueue.length > 1 ? "results" : "result"}
            </button>
          ) : (
            <></>
          )}

          <div
            className={`flip__coins-box ${totalCoins > 5 ? "flip__coins-box_type_multiple" : ""
              }`}
          >
            {Array(
              totalCoins >= MIN_COINS_NUMBER ? totalCoins : MIN_COINS_NUMBER
            )
              .fill("")
              .map((item, i) => (
                <CoinBox
                  key={`coin-image-${i}`}
                  isLoaded={true}
                  isBlueTop={
                    !isAuto
                      ? i + 1 <= winCoins
                        ? isBlueTop
                        : !isBlueTop
                      : isBlueWin[i]
                  }
                  isBlueWin={isBlueWin[i]}
                  isBlue={isBlueTop}
                  index={i}
                  {...{
                    isFliping,
                    isStopFliping,
                    totalCoins,
                    isFastFlip,
                    isAnimEnd,
                    isReturnAnim,
                    isToggleAnim,
                    isAuto,
                    time,
                  }}
                />
              ))}
          </div>
        </div>

        <div
          className={`flip__bet-block ${isBlocked || !isCoinImagesLoaded
            ? "flip__bet-block_disabled"
            : ""
            }`}
        >
          <div className="flip__block-item">
            <p className="flip__block-title">Coins total</p>
            <input
              className="flip__coins-input"
              id="total"
              name="total"
              type="text"
              inputMode="numeric"
              autoComplete="off"
              value={totalValue}
              onChange={handleCoinsChange}
              onBlur={handleCoinsBlur}
            />
            <Slider
              className="flip__coins-slider"
              aria-label="Coins total"
              name="total"
              min={MIN_COINS_NUMBER}
              max={MAX_COINS_NUMBER}
              step={1}
              defaultValue={MIN_COINS_NUMBER}
              value={totalCoins}
              onChange={handleSliderChange}
            />
          </div>

          <div className="flip__block-item">
            <p className="flip__block-title">Winning side</p>
            <button
              className={`flip__side-btns-box ${isToggleSideDisabled ? "flip__side-btns-box_disabled" : ""
                }`}
              type="button"
              aria-label="Toggle coin winning side"
              onClick={handleSideClick}
            >
              <div
                className={`flip__side-btn ${isBlueTop ? "flip__side-btn_selected" : ""
                  }`}
              >
                <img
                  className="flip__btn-image"
                  src={icons.heads}
                  alt="Head of coin icon"
                />
              </div>
              <div
                className={`flip__side-btn ${!isBlueTop ? "flip__side-btn_selected" : ""
                  }`}
              >
                <img
                  className="flip__btn-image"
                  src={icons.tails}
                  alt="Tail of coin icon"
                />
              </div>
            </button>
          </div>

          <div className="flip__block-item">
            <p className="flip__block-title">Coins to&nbsp;win</p>
            <input
              className="flip__coins-input"
              id="win"
              name="win"
              type="text"
              inputMode="numeric"
              autoComplete="off"
              value={winValue}
              onChange={handleCoinsChange}
              onBlur={handleCoinsBlur}
            />
            <Slider
              className="flip__coins-slider"
              aria-label="Coins to win"
              name="win"
              min={MIN_COINS_NUMBER}
              max={MAX_COINS_NUMBER}
              step={1}
              defaultValue={MIN_COINS_NUMBER}
              value={winCoins}
              onChange={handleSliderChange}
            />
          </div>
        </div>

        <div className="flip__options-block">
          {user && !minBet ? (
            <div className="flip__options-block-preloader">
              <MiniPreloader />
            </div>
          ) : (
            <GameOptions
              btnText="Flip"
              onClick={flip}
              rolling={isFliping}
              bet={betValue}
              setBet={setBetValue}
              minMaxBet={{ min: minBet, max: maxBet }}
              queue={gameQueue}
              autoplayText="Number of Games"
              {...{
                preferredToken,
                active,
                chance,
                payout,
                isSoundOn,
                isBlocked,
                user,
                autoplayAmount,
                setAutoplayAmount,
                switchNetwork,
                isSupportedNetwork,
                isAutoplay,
                demoBalance,
                isMaxBet,
                isMinBet,
                isSufficientAllowance,
                allowToken,
                isDemo,
                demoCoinLogo,
                preferredChainId,
                tokenDecimals,
                betValueWriten,
                setBetValueWriten,
                isDemoSwitch,
                openWalletModal,
                externalAccount,
                sendToParent,
                isExternalWalletConnected,
                isExternalAccountConnection
              }}
            />
          )}
        </div>
      </div>

    </section>
  );
}

export default CoinFlip;
