import { useEffect, useState } from 'react';
import charger from '../../../assets/images/charger/charger.webp';
import './ChargerGame.css';

const SEGMENTS_COUNT = 6;
const SEGMENT_ANIM_INTERVAL = 200;
const SEGMENT_ANIM_INTERVAL_FAST = 150;

function ChargerGame({
  isPreGameAnim,
  isGameAnim,
  isLastGame,
  isWin,
  isLost,
  isGameOn,
  isFastGame,
}) {
  const [isWireActive, setWireActive] = useState(false);
  const [activeSegments, setActiveSegments] = useState(0);
  const isFastGameEnd = isGameOn
    ? isGameOn && !isWin && !isLost
    : isGameOn && (!isWin || !isLost);
  const isNormalGameEnd = !isGameAnim && !isWin && !isLost;
  const isGameEnd = isFastGame ? isFastGameEnd : isNormalGameEnd;

  useEffect(() => {
    let interval;
    if (isGameAnim) {
      interval = setInterval(
        () => {
          setActiveSegments((prev) => (prev + 1) % 7);
        },
        isFastGame ? SEGMENT_ANIM_INTERVAL_FAST : SEGMENT_ANIM_INTERVAL
      );
    }
    if (isGameEnd) {
      setActiveSegments(0);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isGameAnim, isGameEnd, isFastGame]);

  useEffect(() => {
    if (!isPreGameAnim) {
      setWireActive(true);
    }
    if (isFastGame ? isGameEnd && !isGameAnim : isGameEnd) {
      setWireActive(false);
    }
  }, [isGameEnd, isGameAnim, isFastGame, isPreGameAnim]);

  return (
    <div
      className={`charger-game ${
        isGameAnim ? 'charger-game_type_animate' : ''
      } ${isLastGame ? 'charger-game_type_last' : ''} ${
        isFastGame ? 'charger-game_type_fast' : ''
      }`}
    >
      <img className="charger-game__img" src={charger} alt="Charger box" />

      <div className="charger-game__card-slot">
        <svg
          className="charger-game__card-slot-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="10"
          viewBox="0 0 128 10"
          fill="none"
        >
          <path
            className={`charger-game__card-slot-icon-fill ${
              isLost ? 'charger-game__card-slot-icon-fill_type_lost' : ''
            }`}
            d="M124.797 9.61416H2.68858C0.58958 9.61416 -0.697547 7.33815 0.40146 5.57229L2.47076 2.21714C3.32224 0.843692 4.83709 0 6.46085 0H121.025C122.659 0 124.163 0.843692 125.015 2.21714L127.084 5.57229C128.183 7.34796 126.896 9.61416 124.797 9.61416Z"
          />
        </svg>
        <div className="charger-game__card-container">
          <div
            className={`charger-game__card ${
              isLost ? 'charger-game__card_type_lost' : ''
            } ${isWin || isLost ? 'charger-game__card_visible' : ''}`}
          >
            <div
              className={`charger-game__card-content ${
                isLost ? 'charger-game__card-content_type_lost' : ''
              }`}
            >
              <p
                className={`charger-game__card-text ${
                  isLost ? 'charger-game__card-text_type_lost' : ''
                }`}
              >
                {isWin ? 'You Win' : isLost ? 'Loss' : ''}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`charger-game__battery ${
          isLost ? 'charger-game__battery_type_lost' : ''
        }`}
      >
        <ul className="charger-game__battery-container">
          {Array(SEGMENTS_COUNT)
            .fill('')
            .map((_, i) => (
              <li
                className={`charger-game__battery-segment ${
                  isLost ? 'charger-game__battery-segment_type_lost' : ''
                } ${
                  isPreGameAnim
                    ? 'charger-game__battery-segment_type_pre-game-anim'
                    : ''
                } ${
                  SEGMENTS_COUNT - 1 - i < activeSegments
                    ? 'charger-game__battery-segment_type_active'
                    : ''
                } ${
                  isLost && SEGMENTS_COUNT - 1 - i < activeSegments
                    ? 'charger-game__battery-segment_type_active-lost'
                    : ''
                }`}
                key={i}
              />
            ))}
        </ul>
      </div>

      <svg
        className="charger-game__wire"
        width="413"
        height="331"
        viewBox="0 0 413 331"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="charger-game__wire-shadow"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M394.567 87.9825C385.469 94.1854 375.003 94.1588 363.259 91.4409C351.796 88.788 337.448 83.1569 319.39 76.0695L223.489 38.4324C211.533 33.7399 203.025 30.4084 195.995 28.3628C189.111 26.3595 184.151 25.7513 179.385 26.2512C174.619 26.7512 169.894 28.3755 163.575 31.7638C157.123 35.2237 149.492 40.2483 138.769 47.32L54.5459 102.867C40.6633 112.022 30.9323 118.462 24.3689 124.264C17.9628 129.927 15.6336 134.085 15.1112 138.38C14.5888 142.674 15.8531 147.269 20.7145 154.303C25.6953 161.51 33.5986 170.094 44.881 182.311L171.953 319.912L160.933 330.088L33.4556 192.049C22.6728 180.373 14.0313 171.016 8.37477 162.831C2.52812 154.371 -0.932931 146.053 0.220945 136.568C1.37482 127.083 6.72942 119.837 14.4338 113.026C21.8877 106.436 32.5206 99.4239 45.7883 90.6739L130.809 34.6014C141.167 27.7698 149.363 22.3644 156.486 18.5445C163.811 14.6167 170.456 12.1056 177.82 11.3331C185.184 10.5606 192.206 11.6379 200.186 13.9602C207.947 16.2186 217.086 19.8055 228.637 24.3387L324.263 61.8681C343.065 69.2472 356.384 74.4534 366.641 76.8271C376.745 79.1655 382.115 78.3177 386.117 75.5889C420.942 68.5 394.567 87.9825 394.567 87.9825Z"
          fill="#0B0024"
        />
        <path
          className={`charger-game__wire-base-fill ${
            isPreGameAnim ? 'charger-game__wire-base-fill_type_pregame' : ''
          } ${isWireActive ? 'charger-game__wire-base-fill_type_game' : ''} ${
            isWireActive && isLost
              ? 'charger-game__wire-base-fill_type_lost'
              : ''
          }`}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M412.943 0V9.64896C412.943 29.0483 412.943 44.4615 411.225 56.1015C409.464 68.0269 405.665 77.7795 396.567 83.9824C387.469 90.1854 377.003 90.1588 365.259 87.4409C353.796 84.788 339.448 79.1569 321.39 72.0695L225.489 34.4323C213.533 29.7398 205.025 26.4083 197.995 24.3628C191.111 22.3595 186.151 21.7512 181.385 22.2512C176.619 22.7512 171.894 24.3754 165.575 27.7638C159.123 31.2236 151.492 36.2483 140.769 43.32L56.5459 98.8666C42.6633 108.022 32.9323 114.462 26.3689 120.264C19.9628 125.927 17.6336 130.085 17.1112 134.379C16.5888 138.674 17.8531 143.269 22.7145 150.303C27.6953 157.51 35.5986 166.094 46.881 178.311L173.953 315.912L162.933 326.088L35.4556 188.049C24.6728 176.373 16.0313 167.016 10.3748 158.831C4.52812 150.371 1.06707 142.053 2.22094 132.568C3.37482 123.083 8.72942 115.837 16.4338 109.026C23.8877 102.436 34.5206 95.4239 47.7883 86.6739L132.809 30.6013C143.167 23.7698 151.363 18.3643 158.486 14.5445C165.811 10.6166 172.456 8.10559 179.82 7.33307C187.184 6.56055 194.206 7.6379 202.186 9.96019C209.947 12.2186 219.086 15.8054 230.637 20.3386L326.263 57.8681C345.065 65.2472 358.384 70.4533 368.641 72.8271C378.745 75.1655 384.115 74.3176 388.117 71.5889C392.12 68.8601 394.871 64.1707 396.385 53.9109C397.923 43.4957 397.943 29.1954 397.943 8.99705V0H412.943Z"
          fill="#163149"
        />
        <mask
          id="mask0_743_1374"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="0"
          width="411"
          height="327"
        >
          <path
            className="charger-game__wire-fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M412.943 0V9.64896C412.943 29.0483 412.943 44.4615 411.225 56.1015C409.464 68.0269 405.665 77.7795 396.567 83.9824C387.469 90.1854 377.003 90.1588 365.259 87.4409C353.796 84.788 339.448 79.1569 321.39 72.0695L225.489 34.4323C213.533 29.7398 205.025 26.4083 197.995 24.3628C191.111 22.3595 186.151 21.7512 181.385 22.2512C176.619 22.7512 171.894 24.3754 165.575 27.7638C159.123 31.2236 151.492 36.2483 140.769 43.32L56.5459 98.8666C42.6633 108.022 32.9323 114.462 26.3689 120.264C19.9628 125.927 17.6336 130.085 17.1112 134.379C16.5888 138.674 17.8531 143.269 22.7145 150.303C27.6953 157.51 35.5986 166.094 46.881 178.311L173.953 315.912L162.933 326.088L35.4556 188.049C24.6728 176.373 16.0313 167.016 10.3748 158.831C4.52812 150.371 1.06707 142.053 2.22094 132.568C3.37482 123.083 8.72942 115.837 16.4338 109.026C23.8877 102.436 34.5206 95.4239 47.7883 86.6739L132.809 30.6013C143.167 23.7698 151.363 18.3643 158.486 14.5445C165.811 10.6166 172.456 8.10559 179.82 7.33307C187.184 6.56055 194.206 7.6379 202.186 9.96019C209.947 12.2186 219.086 15.8054 230.637 20.3386L326.263 57.8681C345.065 65.2472 358.384 70.4533 368.641 72.8271C378.745 75.1655 384.115 74.3176 388.117 71.5889C392.12 68.8601 394.871 64.1707 396.385 53.9109C397.923 43.4957 397.943 29.1954 397.943 8.99705V0H412.943Z"
            fill="#2E6D96"
          />
        </mask>
        <g mask="url(#mask0_743_1374)">
          <path
            className="charger-game__charger-shadow"
            opacity="0.5"
            d="M398 0H413V28C413 28 410.5 29 405.5 29C400.5 29 398 28 398 28V0Z"
            fill="#0E1E31"
          />
        </g>
      </svg>
    </div>
  );
}

export default ChargerGame;
