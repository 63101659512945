import { ethers } from "ethers";

import { Mumbai } from '@usedapp/core';

export const MAIN_URL = "https://app-games.paraliq.com";

export const FRONTEND_IFRAME_URL = "https://games.paraliq.com";

export const COIN_FLIP_GAME = "coinflip";

export const MEGA_DICE_GAME = "megadice";

export const LIMBO_GAME = "limbo";

export const WHEEL_GAME = "luckyWheel";

export const DEMO_CURRENCY = "DEMO";
export const USDT_CURRENCY = "USDT";
export const ZONE_CURRENCY = "ZONE";

export const NUMBERS_ARR = [6, 7, 8, 9, 0, 1, 2, 3, 4, 5];

export const MIN_BET_MEGA_DICE = 4;

export const MAX_BET_MEGA_DICE = 97;

export const START_BET_MEGA_DICE = 50.0;

export const DEFAULT_CHAIN_ID = Mumbai.chainId;

export const DEFAULT_CONSOLE_ADDRESS = "0xc3F9CdFf2Dfa1Ef8746D255d725b67470e2f9399"; /// connected to Mumbai Chain

export const DEFAULT_USDT_ADDRESS = "0x8c68ED92BEC67c770b649C64e077f2F0D35F88fB"; /// connected to Mumbai Chain

export const DEFAULT_FRONTEND_REFERRAL = "0x95b96372ef88305056ac7f56c57a0c388c57d728";

export const DEFAULT_WSS = "wss://polygon-mumbai.g.alchemy.com/v2/3C8T71LWlEHjHGG3TvGwqmO1mrRX3PgY";

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const MEGA_DICE_ID = 1;

export const COIN_FLIP_ID = 2;

export const LIMBO_ID = 3;

export const WHEEL_ID = 4;

export const IGAME_ABI = [
  // funcs
  "function play(address _token, address _frontendReferral, uint8 _autoRollAmount, uint256 _wager, bytes memory _gameData) external payable {}",
  "function refundGame(bytes32 _requestId) external",
  "function getVault() external view returns (address)",
  "event GameSessionPlayed(address indexed _user, uint256 indexed _gameId, string _gameName, address _frontendReferral, uint256 _startTime, uint256 _wager, address _token, uint256 _payoutAmount, uint256 _sentValue, uint8 _autoRollAmount, bytes _gameData, uint256[] _randomValue)"
]

export const ICONSOLE_ABI = [
  // funcs
  "function getGameWithExtraData(uint256 _id, address _token) external view returns (tuple(tuple(uint256 id, bool paused, string name, uint256 date, address impl), address vault, address token, uint256 minWager, uint256 maxPayout, uint256 maxReservedAmount, tuple(tuple(uint256 currentFee, uint256 nextFee, uint256 startTime), bool isPresent)) memory)",
  "function getGasPerRoll() external view returns (uint256)",
]

export const IVAULT_ABI = [
  //funcs
  "function getMaxPayout(address _game, address _token) external view returns (uint256 maxWager_)",
]

export const IERC20_METADATA_ABI = [
  // funcs
  "function decimals() external view returns (uint8)",
]

export const MIN_COINS_NUMBER = 1;

export const MAX_COINS_NUMBER = 10;

export const METAMASK_CONNECTOR = "injected";

export const WALLET_CONNECTOR = "walletConnect";

export const COINBASE_CONNECTOR = "coinbaseWallet";

export const MIN_AUTOPLAY_NUMBER = 1;

export const MAX_AUTOPLAY_NUMBER = 50;

export const DEMO_DECIMALS = 18;

export const DEMO_DECIMAL = ethers.BigNumber.from(10).pow(DEMO_DECIMALS);

export const MAX_BIG_NUMBER = ethers.BigNumber.from("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")

export const INIT_DEMO_BALANCE = ethers.BigNumber.from(1000).mul(DEMO_DECIMAL);

export const MIN_DEMO_BET_NUMBER = ethers.BigNumber.from(1).mul(DEMO_DECIMAL).div(10);

export const MAX_DEMO_BET_NUMBER = ethers.BigNumber.from(1000).mul(DEMO_DECIMAL);

export const DEMO_MIN_BET_VALUE = ethers.BigNumber.from(10).mul(DEMO_DECIMAL);

export const START_MULTIPLIER_NUMBER = 1

export const MIN_CRUSH_VALUE = 1

export const MIN_CRUSH_BET = 1.01

export const MAX_CRUSH_BET = 100

export const CRASH_BET_STEP_NUMBER = 0.01

export const TOTAL_SEVENS_COINS = 3

export const MIN_SHUTTLE_BET = 1.1

export const SHUTTLE_BET_STEP_NUMBER = 0.1

export const SAFE_GAME_MODE = 'safe'

export const RISKY_GAME_MODE = 'risky'

export const DEGEN_GAME_MODE = 'degen'

export const SAFE_WHEEL_SLICES_LIST = {
  0: 0,
  1: 2,
  2: 1.1,
  3: 1.1,
  4: 1.1,
  5: 0,
  6: 1.1,
  7: 1.1,
  8: 1.1,
  9: 1.1,
  10: 0,
  11: 2,
  12: 1.1,
  13: 1.1,
  14: 1.1,
  15: 0,
  16: 1.1,
  17: 1.1,
  18: 1.1,
  19: 1.1,
};

export const RISKY_WHEEL_SLICES_LIST = {
  0: 0,
  1: 3,
  2: 0,
  3: 1.8,
  4: 0,
  5: 2,
  6: 0,
  7: 2,
  8: 0,
  9: 2,
  10: 0,
  11: 1.3,
  12: 0,
  13: 2,
  14: 0,
  15: 2,
  16: 0,
  17: 2,
  18: 0,
  19: 1.3,
};

export const DEGEN_WHEEL_SLICES_LIST = {
  0: 0,
  1: 19.4,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
  13: 0,
  14: 0,
  15: 0,
  16: 0,
  17: 0,
  18: 0,
  19: 0,
};

export const WHEEL_MODES_CHAIN = {
  [SAFE_GAME_MODE]: 0,
  [RISKY_GAME_MODE]: 1,
  [DEGEN_GAME_MODE]: 2
}

export const START_CHARGER_NUMBER = 0
export const MIN_CHARGER_BET = 2;
export const CHARGER_BET_STEP_NUMBER = 1
