import "./ShuttleValue.css";

function ShuttleValue({ value, isWin, isLost }) {
  const formattedValue = value.toFixed(1);

  return (
    <div className="shuttle-value">
      <p
        className={`shuttle-value__multiplier ${
          isWin ? "shuttle-value__multiplier_type_win" : ""
        } ${isLost ? "shuttle-value__multiplier_type_lost" : ""}`}
      >
        × {formattedValue}
      </p>
    </div>
  );
}

export default ShuttleValue;
