import shuttle from "../../../assets/images/shuttle/shuttle-icon.png";
import flame from "../../../assets/images/shuttle/shuttle-flame-icon.png";
import "./ShuttleGame.css";

function ShuttleGame({ isPreGameAnim, isGameAnim, traceLength, isLastGame }) {
  return (
    <div
      className={`shuttle-game ${
        isGameAnim ? "shuttle-game_type_animate" : ""
      } ${isLastGame ? "shuttle-game_type_last" : ""}`}
    >
      <div
        className={`shuttle-game__img-box ${
          isGameAnim ? "shuttle-game__img-box_type_animate" : ""
        }`}
      >
        <img className="shuttle-game__img" src={shuttle} alt="" />
        <img
          className={`shuttle-game__flame ${
            isPreGameAnim ? "shuttle-game__flame_type_preanimate" : ""
          } ${isGameAnim ? "shuttle-game__flame_type_animate" : ""}`}
          src={flame}
          alt=""
        />
        <div
          className={`shuttle-game__trace ${
            isGameAnim ? "shuttle-game__trace_visible" : ""
          }`}
          style={{ "--shuttle-trace-length": `${traceLength}px` }}
        />
      </div>
    </div>
  );
}

export default ShuttleGame;
