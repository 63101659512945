import "./ToastMsg.css";

function ToastMsg({ msg, startMsg, endMsg, span, win, text }) {
  return (
    <div className="toast">
      {span ? (
        <p className="toast__message">
          {startMsg} <span className="toast__message_type_lost">{span}</span>{" "}
          {endMsg}
        </p>
      ) : win ? (
        <div className="toast__win-msg-box">
          <p className="toast__message">{msg}</p>
          <p className="toast__message toast__message_type_win">{win}</p>
        </div>
      ) : (
        <p className="toast__message">{msg}</p>
      )}

      {text ? <p className="toast__message-text">{text}</p> : null}
    </div>
  );
}

export default ToastMsg;
