import {
  DEMO_CURRENCY,
  USDT_CURRENCY, ZONE_CURRENCY,
} from "../utilis/constants";
import usdt  from "../images/currency/tether-usdt-logo.png"
import zone  from "../images/currency/alienzone-currency.webp"
import demo from "../images/currency/jellybet-currency.png";
import { Mumbai, Arbitrum, Polygon } from "@usedapp/core";

export function useIcons() {

  function setCurrencyIconByTokenName(token) {
    switch (token) {
      case USDT_CURRENCY.toLowerCase():
        return usdt; /// not right decission
      case ZONE_CURRENCY.toLowerCase():
        return zone; /// not right decission
      default:
        return demo;
    }
  }

  return {
    setCurrencyIconByTokenName,
  };
}
