//wallets icons
import walletConnect from "../images/currency/wallet-connect.png";
import metamask from "../images/currency/metamask.png";
import magic from "../images/currency/magic.png";
import coinbase from "../images/currency/coinbase.png";
import moment from "moment-timezone";
import {
  METAMASK_CONNECTOR,
  WALLET_CONNECTOR,
  COINBASE_CONNECTOR,
  COIN_FLIP_GAME,
  LIMBO_GAME,
  MEGA_DICE_GAME,
  WHEEL_GAME,
} from "./constants";
import { ethers } from "ethers";
import { Arbitrum, Mumbai, Polygon } from "@usedapp/core";

export const walletsList = [
  {
    connector: "injected",
    icon: metamask,
    title: "Metamask",
    soon: false,
    isMagic: false
  },
  {
    connector: "walletConnect",
    icon: walletConnect,
    title: "Wallet Connect",
    soon: false,
    isMagic: false
  },
  {
    connector: "coinbaseWallet",
    icon: coinbase,
    title: "Coinbase",
    soon: false,
    isMagic: false
  },
  {
    connector: "magicWallet",
    icon: magic,
    title: "Magic",
    soon: false,
    isMagic: true
  },
];

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const toHexStr = (str) => {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
};

export function factorial(n) {
  return n.gt(1) ? n.mul(factorial(n.sub(1))) : ethers.BigNumber.from(1);
}

export function prettyValue(n, decimals) {
  let decimal = ethers.BigNumber.from(1).mul(ethers.BigNumber.from(10).pow(decimals))
  return n.lt(decimal)
    ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(5))
    : n.lt(decimal.mul(10))
      ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(4))
      : n.lt(decimal.mul(100))
        ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(3))
        : n.lt(decimal.mul(1000))
          ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(2))
          : Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(1));
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}


function setNetworkName(chainId) {
  switch (chainId) {
    case Polygon.chainId:
      return "polygon";
    case Mumbai.chainId:
      return "polygonMumbai";
    case Arbitrum.chainId:
      return "arbitrumOne";
    default:
      return null;
  }
}

// parse game response coinflip
export function parseGameResultCoinFlip(result, decimals, chainId) {
  const games = [];
  // eslint-disable-next-line array-callback-return
  result.randomValue.map((item) => {
    let winning = ethers.BigNumber.from(0);
    let wonCoins = 0;
    let gameData = ethers.utils.defaultAbiCoder.decode(["bool", "uint256", "uint256"], result.gameData)
    const winningSide = gameData[0];
    const coinsTotal = gameData[1];  // number
    const coinsToWin = gameData[2];  // number
    const amount = result.amount;
    const sentValue = result.sentValue;
    const rand = Number(item.toString().slice(-12));
    let res = [];

    for (let ii = 0; ii < coinsTotal; ii++) {
      // (18439 % 10) / 1
      // (18439 % 100) / 10
      // (18439 % 1000) / 100
      // (18439 % 100) / 10
      // (18439 % 100) / 10

      // 1 = true; 0 = false
      let randomValue =
        Math.floor((rand % 10 ** (ii + 1)) / 10 ** ii) % 2 === 1;
      res.push(randomValue);
      if (randomValue === winningSide) {
        wonCoins++;
      }
    }

    if (wonCoins >= coinsToWin) {
      let chance = ethers.BigNumber.from(0);
      for (let ii = coinsTotal; ii >= coinsToWin; ii--) {
        chance = chance.add(
          factorial(ethers.BigNumber.from(coinsTotal)).div(
            (
              factorial(ethers.BigNumber.from(ii)).mul(factorial(ethers.BigNumber.from(coinsTotal - ii)))
            )
          )
        );
      }
      winning = amount.div(result.randomValue.length).mul(ethers.BigNumber.from(2).pow(coinsTotal)).div(chance)
    }

    const gameResult = {
      game_type: COIN_FLIP_GAME,
      chainId: chainId,
      address: result.user,
      transaction_hash: result.event.transactionHash,
      sent_value: prettyValue(sentValue.div(result.randomValue.length), decimals),
      bet_amount: prettyValue(amount.div(result.randomValue.length), decimals),
      coins_to_win: coinsToWin,
      coins_total: coinsTotal,
      winning_side: winningSide,
      payout_amount: prettyValue(winning, decimals),
      payout_real_amount: winning,
      random_value: wonCoins,
      res: res,
      utc_date: moment.utc().format(),
      new: true,
      network: setNetworkName(chainId),
      token: result.token
    };
    games.push(gameResult);
  });
  console.log("game result:", games);
  return games;
}

// parse game response megadice
export function parseGameResultMegaDice(result, decimals, chainId) {
  const games = [];
  // eslint-disable-next-line array-callback-return
  result.randomValue.map((item) => {
    let winning = ethers.BigNumber.from(0);
    let outcome = ethers.BigNumber.from(0);

    let gameData = ethers.utils.defaultAbiCoder.decode(["bool", "uint256"], result.gameData)

    const up = gameData[0]; 
    const position = gameData[1];

    const amount = result.amount;

    const sentValue = result.sentValue;
    const randomValue = item.mod(10000);

    if (
      (!up && randomValue.lt(position)) ||
      (up && randomValue.gt(position))
    ) {
      outcome = up
        ? ethers.BigNumber.from(10000).sub(position)
        : position;
      winning = amount.div(result.randomValue.length).mul(10000).div(outcome);
    }
    
    const gameResult = {
      game_type: MEGA_DICE_GAME,
      chainId: chainId,
      address: result.user,
      transaction_hash: result.event.transactionHash,
      sent_value: prettyValue(sentValue.div(result.randomValue.length), decimals),
      bet_amount: prettyValue(amount.div(result.randomValue.length), decimals),
      up: up,
      position: Number(position),
      payout_amount: prettyValue(winning, decimals),
      payout_real_amount: winning,
      random_value: randomValue,
      utc_date: moment.utc().format(),
      new: true,
      network: setNetworkName(chainId),
      token: result.token
    };
    games.push(gameResult);
  });
  console.log("game result:", games);
  return games;
}

// parse game response limbo
export function parseGameResultLimbo(result, decimals, chainId) {
  const games = [];
  // eslint-disable-next-line array-callback-return
  result.randomValue.map((item) => {
    let winning = ethers.BigNumber.from(0);
    let gameData = ethers.utils.defaultAbiCoder.decode(["uint256"], result.gameData)
    const target = gameData[0];
    const amount = result.sentValue;
    const sentValue = result.sentValue;
    const rand = item;

    if (rand.gte(target)) {
      winning = amount.div(result.randomValue.length).mul(target).div(100);
    }

    const gameResult = {
      game_type: LIMBO_GAME,
      chainId: chainId,
      address: result.user,
      transaction_hash: result.event.transactionHash,
      sent_value: prettyValue(sentValue.div(result.randomValue.length), decimals),
      bet_amount: prettyValue(amount.div(result.randomValue.length), decimals),
      target: target,
      payout_amount: prettyValue(winning, decimals),
      payout_real_amount: winning,
      random_value: Number((Number(rand) / 100).toFixed(2)),
      utc_date: moment.utc().format(),
      new: true,
      network: setNetworkName(chainId),
      token: result.token
    };
    games.push(gameResult);
  });
  console.log("game result:", games);
  return games;
}

// parse game response limbo
export function parseGameResultLuckyWheel(result, decimals, chainId) {
  const games = [];
  // eslint-disable-next-line array-callback-return
  result.randomValue.map((item) => {
    let winning = ethers.BigNumber.from(0);
    let gameData = ethers.utils.defaultAbiCoder.decode(["uint8", "uint8"], result.gameData)
    const mode = gameData[0];
    const slotsAmount = gameData[1];
    const amount = result.sentValue;
    const sentValue = result.sentValue;
    const rand = item;

    if (rand.gte(ethers.BigNumber.from(0))) {
      winning = amount.div(result.randomValue.length).mul(rand).div(100);
    }

    const gameResult = {
      game_type: WHEEL_GAME,
      chainId: chainId,
      address: result.user,
      transaction_hash: result.event.transactionHash,
      sent_value: prettyValue(sentValue.div(result.randomValue.length), decimals),
      bet_amount: prettyValue(amount.div(result.randomValue.length), decimals),
      mode: mode,
      slots_amount: slotsAmount,
      payout_amount: prettyValue(winning, decimals),
      payout_real_amount: winning,
      random_value: Number((Number(rand) / 100).toFixed(2)),
      utc_date: moment.utc().format(),
      new: true,
      network: setNetworkName(chainId),
      token: result.token
    };
    games.push(gameResult);
  });
  console.log("game result:", games);
  return games;
}

export function strip(number) {
  return parseFloat(number.toPrecision(12));
}

export function getWallet(connector) {
  if (connector?.connector?.name === 'Metamask') return METAMASK_CONNECTOR;
  if (connector?.connector?.name === 'WalletConnectV2') return WALLET_CONNECTOR;
  if (connector?.connector?.name === 'CoinbaseWallet') return COINBASE_CONNECTOR;
  return "Unknown";
}

export function getFee(gameFee) {
  if (!gameFee.nextFee.isZero()) {
    if (moment.utc().unix >= Number(gameFee.startTime) && !gameFee.startTime.isZero()) {
      return gameFee.nextFee
    }
  }
  return gameFee.currentFee
}

export function bigNumberMin(bigNumbersArray) {
  return bigNumbersArray.reduce((currentMin, currentValue) => {
    return currentValue.lt(currentMin) ? currentValue : currentMin;
  }, bigNumbersArray[0]);
}

export function bigNumberMax(bigNumbersArray) {
  return bigNumbersArray.reduce((currentMax, currentValue) => {
    return currentValue.gt(currentMax) ? currentValue : currentMax;
  }, bigNumbersArray[0]);
}
