/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useContractFunction, useTokenAllowance, ERC20Interface, useCall } from '@usedapp/core';
import { ethers } from "ethers";
import { bigNumberMin, deepEqual, getFee, prettyValue } from "../../assets/utilis/utilis";

import ResultNumber from "./ResultNumber/ResultNumber";
import GameOptions from "../GameOptions/GameOptions";
import MiniPreloader from "../MiniPreloader/MiniPreloader";

import { Howl } from "howler";
import bet_slider_sound from "../../assets/sounds/bet_slider_sound2.wav";
import bet_buttons_sound from "../../assets/sounds/bet_buttons_sound.wav";

import megadice_game_sound from "../../assets/sounds/cube_game_sound.wav";

import { ARROW_BTN_ICON } from "../../assets/utilis/icons";
import {
  NUMBERS_ARR,
  MIN_BET_MEGA_DICE,
  MAX_BET_MEGA_DICE,
  START_BET_MEGA_DICE,
  MIN_DEMO_BET_NUMBER,
  MAX_DEMO_BET_NUMBER,
  DEMO_MIN_BET_VALUE,
  MIN_AUTOPLAY_NUMBER,
  IGAME_ABI,
  DEMO_CURRENCY,
  NULL_ADDRESS,
  MAX_BIG_NUMBER,
  DEMO_DECIMALS,
  MEGA_DICE_ID,
  ICONSOLE_ABI,
  MEGA_DICE_GAME
} from "../../assets/utilis/constants";

import Web3 from "web3";

import {
  parseGameResultMegaDice,
} from "../../assets/utilis/utilis";

import "./MegaDice.css";

function outcome(up, position) {
  if (up) {
    return 10000 - position;
  } else {
    return position;
  }
}

function calculateMaxBet(
  maxPayout,
  up,
  position,
  fee
) {
  return maxPayout.mul(10000).div(10000 - fee).mul(outcome(up, Number((position * 100).toFixed(0)))).div(10000);
}

// get mega dice
const useMegaDiceGame = async (consoleAddress, tokenAddress, chainId) => {
  const { value, error } = useCall(
    consoleAddress && {
      contract: new ethers.Contract(consoleAddress, ICONSOLE_ABI),
      method: 'getGameWithExtraData',
      args: [MEGA_DICE_ID, tokenAddress]
    },
    { chainId }) ?? {}
  if (error) {
    console.error(error.message)
    return undefined
  }
  else if (!value) {
    return undefined
  }
  return {
    game: {
      id: value?.[0][0].id,
      paused: value?.[0][0].paused,
      name: value?.[0][0].name,
      date: value?.[0][0].date,
      impl: value?.[0][0].impl,
    },
    vault: value?.[0].vault,
    token: value?.[0].token,
    minWager: value?.[0].minWager,
    maxPayout: value?.[0].maxPayout,
    maxReservedAmount: value?.[0].maxReservedAmount,
    gameVault: {
      gameFee: {
        currentFee: value?.[0][6][0].currentFee,
        nextFee: value?.[0][6][0].nextFee,
        startTime: value?.[0][6][0].startTime,
      },
      isPresent: value?.[0][6].isPresent
    }
  }
}

function MegaDice({
  consoleAddress,
  wss,
  isPrevDemo,
  setPrevDemo,
  isSoundOn,
  displayLostMsg,
  displayWonMsg,
  displayDeclinedMsg,
  displayMadeMsg,
  displaySentMsg,
  displayTxDoneMsg,
  displayReduceBetMsg,
  displayIncreaseBetMsg,
  dismissMadeMsg,
  dismissSentMsg,
  dismissReduceBetMsg,
  dismissIncreaseBetMsg,
  user,
  switchNetwork,
  isSupportedNetwork,
  demoBalance,
  setDemoBalance,
  stopGameAudio,
  playGameSound,
  isGamePlaying,
  setGamePlaying,
  gasPerRoll,
  isDemo,
  demoCoinLogo,
  partnerReferralAddress,
  tokenAddress,
  isBlocked,
  setBlocked,
  preferredChainId,
  tokenDecimals,
  searchParams,
  isDemoSwitch,
  openWalletModal,
  externalAccount,
  account,
  active,
  sendToParent,
  txStatus,
  isExternalWalletConnected,
  isExternalAccountConnection,
  sendMagicTx,
  isMagicConnectorStateVar,
  preferredToken
}) {
  // eslint-disable-next-line no-unused-vars

  const sliderAudio = new Howl({
    src: [bet_slider_sound],
  });
  const buttonsAudio = new Howl({
    src: [bet_buttons_sound],
  });

  const [position, setPostion] = useState(START_BET_MEGA_DICE);
  const [betValue, setBetValue] = useState(DEMO_MIN_BET_VALUE);
  const [betValueWriten, setBetValueWriten] = useState(prettyValue(betValue, DEMO_DECIMALS));
  const [up, setUp] = useState(true);
  const isAutoplay = true;
  const [autoplayAmount, setAutoplayAmount] = useState(MIN_AUTOPLAY_NUMBER);
  const [payout, setPayout] = useState(0);
  const [chance, setChance] = useState(0);
  const [spin, setSpin] = useState(false);
  const [minBet, setMinBet] = useState(ethers.BigNumber.from(0));
  const [maxBet, setMaxBet] = useState(ethers.BigNumber.from(0));
  const [checkBet, setCheckBet] = useState(true);
  const [isWin, setWin] = useState(false);
  const [isLost, setLost] = useState(false);
  const [isFast, setFast] = useState(false);
  const [isMaxBet, setIsMaxBet] = useState(false);
  const [isMinBet, setIsMinBet] = useState(false);
  const [isSufficientAllowance, setIsSufficientAllowance] = useState(false);
  const [gameAddress, setGameAddress] = useState(NULL_ADDRESS)
  const [vaultAddress, setVaultAddress] = useState(NULL_ADDRESS)

  const megaDiceGame = useMegaDiceGame(consoleAddress, tokenAddress, preferredChainId);
  const [megaDiceGameObject, setMegaDiceGameObject] = useState(null);
  const [megaDiceAddress, setMegaDiceAddress] = useState(null);
  const [txTypeInProgress, setTxTypeInProgress] = useState(null);

  // set mega dice game sounds
  const [isGameAudioPlaying, setGameAudioPlaying] = useState(false);
  const [gameAudioId, setGameAudioId] = useState(0);
  const [gameAudio, setGameAudio] = useState(null);

  // track games
  const [gameQueue, setGameQueue] = useState([]);
  const [gameQueueInit, setGameQueueInit] = useState([]);
  const [gameQueueInitOffset, setGameQueueInitOffset] = useState(0);

  // number slots
  const [slot1, setSlot1] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });
  const [slot2, setSlot2] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });
  const [slot3, setSlot3] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });
  const [slot4, setSlot4] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });

  const allowance = useTokenAllowance(
    tokenAddress,
    externalAccount ? externalAccount : account,
    vaultAddress,
    {
      refresh: "everyBlock",
      chainId: preferredChainId
    }
  )

  useEffect(() => {
    const audio = new Howl({
      src: [megadice_game_sound],
      onplay: function (id) {
        setGameAudioId(id);
        setGameAudioPlaying(true);
        audio.loop(true, id);
      },
      onfade: function (id) {
        setGameAudioPlaying(false);
        audio.loop(false, id);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio(audio);
  }, []);

  const playFunction = useContractFunction(
    new ethers.Contract(
      gameAddress,
      new ethers.utils.Interface(IGAME_ABI)
    ),
    'play'
  )
  const playFunctionState = playFunction.state;
  const playFunctionSend = playFunction.send;

  const tokenApproveFunction = useContractFunction(
    new ethers.Contract(
      tokenAddress,
      ERC20Interface
    ),
    'approve'
  )
  const tokenApproveFunctionState = tokenApproveFunction.state;
  const tokenApproveFunctionSend = tokenApproveFunction.send;

  // set number spinning state
  useEffect(() => {
    setSpin(slot1.rolling || slot2.rolling || slot3.rolling || slot4.rolling);
  }, [slot1.rolling, slot2.rolling, slot3.rolling, slot4.rolling]);

  // change chance and payout
  useEffect(() => {
    updateChanceAndPayout();
  }, [position, up]);

  useEffect(() => {
    console.log(betValue)

    if (isDemo === isPrevDemo) return;

    if (isDemo) {
      if (DEMO_DECIMALS > tokenDecimals) {
        console.log(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals))
        setBetValue(betValue.mul(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)))
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(betValue.div(ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)))
      }
    } else {
      if (DEMO_DECIMALS > tokenDecimals) {
        setBetValue(betValue.div(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)))
      } else if (DEMO_DECIMALS < tokenDecimals) {
        setBetValue(betValue.mul(ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)))
      }
    }
    setPrevDemo(isDemo);

  }, [isDemo])

  useEffect(() => {

    if (isPrevDemo || tokenDecimals === DEMO_DECIMALS) return;

    if (DEMO_DECIMALS > tokenDecimals) {
      setBetValue(betValue.div(ethers.BigNumber.from(10).pow(DEMO_DECIMALS - tokenDecimals)))
    } else if (DEMO_DECIMALS < tokenDecimals) {
      setBetValue(betValue.mul(ethers.BigNumber.from(10).pow(tokenDecimals - DEMO_DECIMALS)))
    }

  }, [tokenDecimals])

  const updateChanceAndPayout = async () => {
    const chance =
      ((!up ? position : 100 - position) * 100) /
      10000;
    let payout;
    if (megaDiceGameObject) {
      payout = (1 / chance) * (10000 - getFee(megaDiceGameObject.gameVault.gameFee)) / 10000
    } else {
      payout = (1 / chance) * (10000 - 300) / 10000
    }
    setChance((chance * 100).toFixed(2));
    setPayout(payout.toFixed(2));
  }

  useEffect(() => {
    if (user && (active || externalAccount) && !isPrevDemo) {
      updateMinBet(betValue);
      updateMaxPayout(up, position, autoplayAmount, betValue); // also max bet
      updateGameData();
    } else if (isPrevDemo) {
      setMinBet(MIN_DEMO_BET_NUMBER);
      setIsMinBet(betValue.lt(MIN_DEMO_BET_NUMBER));
      setMaxBet(MAX_DEMO_BET_NUMBER);
      setIsMaxBet(betValue.gt(MAX_DEMO_BET_NUMBER));
    }
  }, [active, user, isPrevDemo, megaDiceGameObject, betValue, isAutoplay, autoplayAmount, position, up]);

  const updateMinBet = async (betValue) => {
    if (!megaDiceGameObject) return;
    let minBet = megaDiceGameObject.minWager.mul(10000).div(ethers.BigNumber.from(10000).sub(getFee(megaDiceGameObject.gameVault.gameFee))); //.add(1000);
    setMinBet(minBet);
    setIsMinBet(betValue.lt(minBet));
  }


  const updateGameData = async () => {
    if (!megaDiceGameObject) return;
    setGameAddress(megaDiceGameObject.game.impl)
    setVaultAddress(megaDiceGameObject.vault)
  }

  const updateMaxPayout = async (up, position, autoplayAmount, betValue) => {
    if (!megaDiceGameObject) return;
    let maxPayout = megaDiceGameObject.maxPayout;
    updateMaxBet(up, position, autoplayAmount, betValue, maxPayout)
  }

  const updateMaxBet = async (up, position, autoplayAmount, betValue, maxPayout) => {
    if (!megaDiceGameObject) return;
    let maxBet = bigNumberMin(
      [
        calculateMaxBet(
          megaDiceGameObject.maxReservedAmount.div(autoplayAmount), up, position, getFee(megaDiceGameObject.gameVault.gameFee)
        ),
        calculateMaxBet(
          maxPayout, up, position, getFee(megaDiceGameObject.gameVault.gameFee)
        ),
        user.balance.div(autoplayAmount)
      ]
    );
    setMaxBet(maxBet)
    setIsMaxBet(betValue.gt(maxBet));
  }

  useEffect(() => {
    if (isGamePlaying) return;
    isMaxBet
      ? displayReduceBetMsg(prettyValue(maxBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals), isPrevDemo)
      : dismissReduceBetMsg();
  }, [isMaxBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    isMinBet
      ? displayIncreaseBetMsg(prettyValue(minBet, isPrevDemo ? DEMO_DECIMALS : tokenDecimals), isPrevDemo)
      : dismissIncreaseBetMsg();
  }, [isMinBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    dismissReduceBetMsg();
    dismissIncreaseBetMsg();
    setCheckBet(isMinBet || isMaxBet ? !checkBet : checkBet);
  }, [isPrevDemo, isGamePlaying]);

  useEffect(() => {
    if (isGamePlaying) return;
    if (!isPrevDemo && allowance && user) setIsSufficientAllowance(allowance.gt(betValue.mul(autoplayAmount)));
  }, [allowance, betValue, autoplayAmount, isPrevDemo, isGamePlaying, user]);

  // control games sounds
  useEffect(() => {
    if (gameAudio === null) return;

    spin
      ? playGameSound(gameAudio, isGameAudioPlaying, spin)
      : stopGameAudio(gameAudio, gameAudioId);
  }, [spin]);

  useEffect(() => {
    if (gameAudio === null) return;

    isSoundOn
      ? playGameSound(gameAudio, isGameAudioPlaying, spin)
      : stopGameAudio(gameAudio, gameAudioId);
  }, [isSoundOn]);

  useEffect(() => {
    if (txTypeInProgress === 'approveVault') {
      if (txStatus === 'Mining') {
        displaySentMsg();
      } else if (txStatus === 'Exception' || txStatus === 'Fail') {
        displayDeclinedMsg();
      }
      else if (txStatus === 'Success') {
        dismissSentMsg();
        displayTxDoneMsg();
      }
    } else if (txTypeInProgress === 'gameInProgress') {
      if (txStatus === 'Mining') {
        dismissMadeMsg();
        displaySentMsg();
        // setTxRes(res);
      } else if (txStatus === 'Exception' || txStatus === 'Fail') {
        setGamePlaying(false);
        sendToParent("setGamePlaying", { value: false });
        displayDeclineResult();
      }
    }
  }, [txStatus])

  useEffect(() => {
    console.log(tokenApproveFunctionState)
    if (tokenApproveFunctionState.status === 'Mining') {
      displaySentMsg();
    } else if (tokenApproveFunctionState.status === 'Exception' || tokenApproveFunctionState.status === 'Fail') {
      displayDeclinedMsg();
    }
    else if (tokenApproveFunctionState.status === 'Success') {
      dismissSentMsg();
      displayTxDoneMsg();
    }
  }, [tokenApproveFunctionState])


  useEffect(() => {
    console.log(playFunctionState)
    if (playFunctionState.status === 'Mining') {
      dismissMadeMsg();
      displaySentMsg();
      // setTxRes(res);
    } else if (playFunctionState.status === 'Exception' || playFunctionState.status === 'Fail') {
      setGamePlaying(false);
      displayDeclineResult();
    }
  }, [playFunctionState])

  const playSound = (audio) => {
    if (isSoundOn) audio.play();
  };

  // game results tracking function
  async function onData(log, game) {
    let result;
    if (game === MEGA_DICE_GAME) {
      let iface = new ethers.utils.Interface(IGAME_ABI);
      let events = iface.parseLog(log);
      console.log("events:", events);
      console.log("log:", log);

      result = parseGameResultMegaDice({
        user: events.args._user,
        sentValue: events.args._sentValue,
        autoroolAmount: events.args._autoroolAmount,
        amount: events.args._wager,
        gameData: events.args._gameData,
        payoutAmount: events.args._payoutAmount,
        randomValue: events.args._randomValue,
        token: events.args._token,
        event: log,
      }, tokenDecimals, preferredChainId);
    }
    console.log(`${game} result:`, result);
    setGameQueueInit((prev) => {
      return [...prev, {result, game}];
    });
  }

  function checkGameQueue(result, game) {
    if ((externalAccount ? externalAccount : account) && result[0].address === (externalAccount ? externalAccount : account)) {
      dismissMadeMsg();
      dismissSentMsg();

      if (game === MEGA_DICE_GAME)
        setGameQueue((prevArray) => [
          ...prevArray,
          {
            items: result,
            is_auto: result.length > 1,
          },
        ]);
    } else {
      for (let i = 0; i < result.length; i++) {
        sendToParent('newGameHistory', { ...result[i], position: result[i].position.toString(), random_value: result[i].random_value.toString() })
      }
    }
  }

  useEffect(() => {
    for (var i = gameQueueInitOffset; i < gameQueueInit.length; i++) {
      setGameQueueInitOffset(i + 1);
      checkGameQueue(gameQueueInit[i].result, gameQueueInit[i].game);
    }
  }, [gameQueueInit]);

  useEffect(() => {
    updateMegaDiceGame(megaDiceGame);
  }, [megaDiceGame]);

  const updateMegaDiceGame = async (megaDiceGame) => {
    if ((await megaDiceGame) && (await megaDiceGame).game.impl !== megaDiceAddress) setMegaDiceAddress((await megaDiceGame).game.impl);
    if ((await megaDiceGame) && !deepEqual((await megaDiceGame), megaDiceGameObject ? megaDiceGameObject : {})) setMegaDiceGameObject((await megaDiceGame));
  }

  useEffect(() => {
    if (megaDiceAddress && wss) subscribeMegaDice();
  }, [megaDiceAddress, wss])

  const subscribeMegaDice = async () => {
    let web3 = new Web3(wss);
    web3.eth.subscribe(
      "logs",
      {
        address: megaDiceAddress,
        topics: [
          new ethers.utils.Interface(IGAME_ABI).getEventTopic('GameSessionPlayed'),
          null,
          null,
        ],
      },
      function (error, result) {
        if (!error) console.log("megadice sub:", result);
      }
    ).on("connected", function (subscriptionId) {
      console.log({ subscriptionId });
    })
      .on("data", function (log) {
        onData(log, MEGA_DICE_GAME);
      })
      .on("changed", function (log) { });;
  };

  // handle position numbers
  function handlePositionChange(evt) {
    const value = evt.target.value;
    switch (true) {
      case value < MIN_BET_MEGA_DICE:
        setPostion(MIN_BET_MEGA_DICE);
        break;
      case value > MAX_BET_MEGA_DICE:
        setPostion(MAX_BET_MEGA_DICE);
        break;
      default:
        setPostion(value);
        sliderAudio.volume(0.65);
        playSound(sliderAudio);
    }
  }

  function handleIncreaseBet() {
    if (position !== MAX_BET_MEGA_DICE) {
      setPostion(Number((position + 0.01).toFixed(2)));
      playSound(sliderAudio);
    }
  }

  function handleReduceBet() {
    if (position !== MIN_BET_MEGA_DICE) {
      setPostion(Number((position - 0.01).toFixed(2)));
      playSound(sliderAudio);
    }
  }

  // format position label
  function valueLabelFormat(value) {
    return value.toFixed(2);
  }

  // toggle position range
  function toggleUnderRange() {
    if (up) {
      setUp(false);
      playSound(buttonsAudio);
    }
  }

  function toggleOverRange() {
    if (!up) {
      setUp(true);
      playSound(buttonsAudio);
    }
  }

  // set game result when transaction is approved
  function displayGameResult(result) {
    dismissSentMsg();
    const payout_real = result.payout_real_amount;
    const payout = result.payout_amount;
    const random = ("0000" + result.random_value.toString())
      .slice(-4)
      .split("")
      .map((el) => Number(el));
    const newSlot1 = NUMBERS_ARR.map((el) =>
      (el + random[0]).toString().slice(-1)
    );
    const newSlot2 = NUMBERS_ARR.map((el) =>
      (el + random[1]).toString().slice(-1)
    );
    const newSlot3 = NUMBERS_ARR.map((el) =>
      (el + random[2]).toString().slice(-1)
    );
    const newSlot4 = NUMBERS_ARR.map((el) =>
      (el + random[3]).toString().slice(-1)
    );

    setTimeout(() => {
      setSlot4((prevSlot) => ({
        ...prevSlot,
        arr: newSlot4,
        rolling: false,
        rollingEnd: true,
      }));
    }, 1500);
    setTimeout(() => {
      setSlot3((prevSlot) => ({
        ...prevSlot,
        arr: newSlot3,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2000);
    setTimeout(() => {
      setSlot2((prevSlot) => ({
        ...prevSlot,
        arr: newSlot2,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2500);
    setTimeout(() => {
      setBlocked(false);
      setSlot1((prevSlot) => ({
        ...prevSlot,
        arr: newSlot1,
        rolling: false,
        rollingEnd: true,
      }));

      if (payout_real.isZero()) {
        displayLostMsg(false);
        setLost(true);
      } else {
        displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, false);
        setWin(true);
      }
      sendToParent('newGameHistory', { ...result, position: result.position.toString(), random_value: result.random_value.toString() })

      setGamePlaying(false);
      sendToParent("setGamePlaying", { value: false });
    }, 3000);
  }

  function displayAUTOGameResult(result) {
    function timer(ms) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
        }, ms);
      });
    }

    async function showRes(res, index) {
      if (index !== 0) await timer(2200);

      console.log({ res, index });
      dismissSentMsg();
      const payout_real = res.payout_real_amount;
      const payout = res.payout_amount;
      const random = ("0000" + res.random_value.toString())
        .slice(-4)
        .split("")
        .map((el) => Number(el));
      const newSlot1 = NUMBERS_ARR.map((el) =>
        (el + random[0]).toString().slice(-1)
      );
      const newSlot2 = NUMBERS_ARR.map((el) =>
        (el + random[1]).toString().slice(-1)
      );
      const newSlot3 = NUMBERS_ARR.map((el) =>
        (el + random[2]).toString().slice(-1)
      );
      const newSlot4 = NUMBERS_ARR.map((el) =>
        (el + random[3]).toString().slice(-1)
      );

      setTimeout(() => {
        setSlot4((prevSlot) => ({
          ...prevSlot,
          arr: newSlot4,
          rolling: false,
          rollingEnd: true,
        }));
      }, 250);
      setTimeout(() => {
        setSlot3((prevSlot) => ({
          ...prevSlot,
          arr: newSlot3,
          rolling: false,
          rollingEnd: true,
        }));
      }, 500);
      setTimeout(() => {
        setSlot2((prevSlot) => ({
          ...prevSlot,
          arr: newSlot2,
          rolling: false,
          rollingEnd: true,
        }));
      }, 750);
      setTimeout(() => {
        setSlot1((prevSlot) => ({
          ...prevSlot,
          arr: newSlot1,
          rolling: false,
          rollingEnd: true,
        }));

        if (payout_real.isZero()) {
          displayLostMsg(index + 1 !== result.length);
          setLost(true);
        } else {
          displayWonMsg(payout + ` ${preferredToken.toUpperCase()}`, index + 1 !== result.length);
          setWin(true);
        }
        sendToParent('newGameHistory', { ...res, position: res.position.toString(), random_value: res.random_value.toString() })
      }, 1000);

      if (index + 1 !== result.length) {
        setTimeout(() => {
          setWin(false);
          setLost(false);
          setSlot1((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
          setSlot2((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
          setSlot3((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
          setSlot4((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
        }, 2000);
      } else {
        setGamePlaying(false);
        setBlocked(false);
        sendToParent("setGamePlaying", { value: false });
      }
    }

    result.reduce(async (a, res, index) => {
      // Wait for the previous item to finish processing
      await a;
      // Process this item
      await showRes(res, index);
    }, Promise.resolve());
  }

  // set transaction declined results
  function displayDeclineResult() {
    dismissMadeMsg();
    dismissSentMsg();
    displayDeclinedMsg();

    setTimeout(() => {
      setSlot4((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
    }, 1500);
    setTimeout(() => {
      setSlot3((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2000);
    setTimeout(() => {
      setSlot2((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2500);
    setTimeout(() => {
      setSlot1((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
      setBlocked(false);
    }, 3000);
  }

  const allowTokenTx = async () => {
    if (!megaDiceGameObject || !vaultAddress) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      ERC20Interface
    )
    const data = gameContract.interface.encodeFunctionData('approve', [
      vaultAddress,
      MAX_BIG_NUMBER
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: tokenAddress, data, value: 0 });
      setTxTypeInProgress('approveVault');
    } else {
      if (!isMagicConnectorStateVar) {
        tokenApproveFunctionSend(
          vaultAddress,
          MAX_BIG_NUMBER
        )
      } else {
        sendMagicTx({ from: externalAccount, to: tokenAddress, data, value: 0 });
        setTxTypeInProgress('approveVault');
      }
    }
  }

  // request to smart contract
  const rollPlaySendTx = async () => {
    if (!(await megaDiceGame) || !(await gasPerRoll)) throw new Error();

    const gameContract = new ethers.Contract(
      NULL_ADDRESS,
      new ethers.utils.Interface(IGAME_ABI)
    )
    const data = gameContract.interface.encodeFunctionData('play', [
      tokenAddress,
      partnerReferralAddress,
      autoplayAmount,
      betValue.mul(autoplayAmount),
      ethers.utils.defaultAbiCoder.encode(["bool", "uint256"], [up, Number((position * 100).toFixed(0))])
    ]);

    if (externalAccount) {
      sendToParent("sendTx", { from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
      sendToParent("setGamePlaying", { value: true });
      setTxTypeInProgress('gameInProgress');
    } else {
      if (!isMagicConnectorStateVar) {
        playFunctionSend(
          tokenAddress,
          partnerReferralAddress,
          autoplayAmount,
          betValue.mul(autoplayAmount),
          ethers.utils.defaultAbiCoder.encode(["bool", "uint256"], [up, Number((position * 100).toFixed(0))]),
          { value: await gasPerRoll }
        )
      } else {
        sendMagicTx({ from: externalAccount, to: gameAddress, data, value: await gasPerRoll });
        setTxTypeInProgress('gameInProgress');
      }
    }
  };

  function allowToken() {
    allowTokenTx()
      .catch(() => {
        displayDeclinedMsg();
      });
  }

  // start game
  function roll() {
    if (user && !isDemo) {
      setGamePlaying(true);
      displayMadeMsg();
      setBlocked(true);
      setWin(false);
      setLost(false);

      setSlot1((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
      setSlot2((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
      setSlot3((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
      setSlot4((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));

      rollPlaySendTx()
        .catch(() => {
          setGamePlaying(false);
          displayDeclineResult();
        });
    } else {
      const autoplayArray = Array(autoplayAmount).join(".").split(".");
      function timer(ms) {
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve();
          }, ms);
        });
      }

      async function showRes(index) {
        if (index !== 0) await timer(3200);

        setWin(false);
        setLost(false);
        setSlot1((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));
        setSlot2((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));
        setSlot3((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));
        setSlot4((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));

        setTimeout(() => {
          let randomInt = Math.random() * 10000;
          const random = (randomInt.toString())
            .slice(-4);
          const randomValues = random
            .split("")
            .map((el) => Number(el))
          const chance = (!up ? ethers.BigNumber.from(position * 100) : ethers.BigNumber.from(10000).sub(position * 100));
          const payoutX = ethers.BigNumber.from(1).mul(ethers.BigNumber.from(10).pow(DEMO_DECIMALS)).div(chance).mul(10000).mul(10000 - 300).div(10000)
          const payout = up
            ? Number(random) / 100 >
              Number(position)
              ? betValue.mul(payoutX).div(ethers.BigNumber.from(10).pow(DEMO_DECIMALS))
              : ethers.BigNumber.from(0)
            : Number(random) / 100 <
              Number(position)
              ? betValue.mul(payoutX).div(ethers.BigNumber.from(10).pow(DEMO_DECIMALS))
              : ethers.BigNumber.from(0);

          const newSlot1 = NUMBERS_ARR.map((el) =>
            (el + randomValues[0]).toString().slice(-1)
          );
          const newSlot2 = NUMBERS_ARR.map((el) =>
            (el + randomValues[1]).toString().slice(-1)
          );
          const newSlot3 = NUMBERS_ARR.map((el) =>
            (el + randomValues[2]).toString().slice(-1)
          );
          const newSlot4 = NUMBERS_ARR.map((el) =>
            (el + randomValues[3]).toString().slice(-1)
          );

          dismissSentMsg();
          setTimeout(() => {
            setSlot4((prevSlot) => ({
              ...prevSlot,
              arr: newSlot4,
              rolling: false,
              rollingEnd: true,
            }));
          }, 250);
          setTimeout(() => {
            setSlot3((prevSlot) => ({
              ...prevSlot,
              arr: newSlot3,
              rolling: false,
              rollingEnd: true,
            }));
          }, 500);
          setTimeout(() => {
            setSlot2((prevSlot) => ({
              ...prevSlot,
              arr: newSlot2,
              rolling: false,
              rollingEnd: true,
            }));
          }, 750);
          setTimeout(() => {

            setSlot1((prevSlot) => ({
              ...prevSlot,
              arr: newSlot1,
              rolling: false,
              rollingEnd: true,
            }));

            if (payout.isZero()) {
              setLost(true);
              if (index + 1 !== autoplayArray.length) {
                setFast(true);
                displayLostMsg(true);
                setTimeout(() => setFast(false), 700);
              } else {
                setFast(false);
                displayLostMsg(false);
              }
            } else {
              setWin(true);
              if (index + 1 !== autoplayArray.length) {
                displayWonMsg(prettyValue(payout, DEMO_DECIMALS) + ` ${DEMO_CURRENCY}`, true);
                setFast(true);
                setTimeout(() => setFast(false), 700);
              } else {
                displayWonMsg(prettyValue(payout, DEMO_DECIMALS) + ` ${DEMO_CURRENCY}`, false);
                setFast(false);
              }
              setDemoBalance((prevValue) => prevValue.add(payout));
            }

            if (index + 1 === autoplayArray.length) {
              setBlocked(false);
            }
          }, 1000);
        }, 1500);
      }

      setDemoBalance(
        (prevValue) => prevValue.sub(betValue.mul(autoplayArray.length))
      );
      setBlocked(true);
      displaySentMsg();
      autoplayArray.reduce(async (a, res, index) => {
        await a;
        // Process this item
        await showRes(index);
        // Wait for the previous item to finish processing
      }, Promise.resolve());
    }
  }

  function checkAndStartAnim() {
    if (!slot1.rolling)
      setSlot1((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
    if (!slot2.rolling)
      setSlot2((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
    if (!slot3.rolling)
      setSlot3((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
    if (!slot4.rolling)
      setSlot4((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
  }

  useEffect(() => {
    console.log({ gameQueue, isGamePlaying });
    if (gameQueue.length > 0) {
      if (isGamePlaying) {
        if (gameQueue.length === 1) {
          dismissSentMsg();
          setBlocked(true);
          checkAndStartAnim();
          if (gameQueue[0].is_auto) {
            setGamePlaying(true);
            displayAUTOGameResult(gameQueue[0].items);
            setGameQueue((prevArr) =>
              prevArr.filter(
                (item) =>
                  item.items[0].transaction_hash !==
                  gameQueue[0].items[0].transaction_hash
              )
            );
          } else {
            setGamePlaying(true);
            displayGameResult(gameQueue[0].items[0]);
            setGameQueue((prevArr) =>
              prevArr.filter(
                (item) =>
                  item.items[0].transaction_hash !==
                  gameQueue[0].items[0].transaction_hash
              )
            );
          }
        }
      }
    }
  }, [gameQueue, isGamePlaying]);

  function handlePlayResultsInQueue() {
    setBlocked(true);
    let itemsArray = [];
    gameQueue.forEach((element) => {
      element.items.forEach((item) => {
        itemsArray = [...itemsArray, item];
      });
    });
    let hashesArray = gameQueue.map((item) => {
      return item.items[0].transaction_hash;
    });
    setGameQueue((prevArr) =>
      prevArr.filter(
        (item) => hashesArray.indexOf(item.items[0].transaction_hash) < 0
      )
    );
    setGamePlaying(true);
    setWin(false);
    setLost(false);
    dismissMadeMsg();
    dismissSentMsg();
    setSlot1((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setSlot2((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setSlot3((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setSlot4((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setTimeout(() => {
      displayAUTOGameResult(itemsArray);
    }, 2000);
  }

  useEffect(() => {
    let root = document.documentElement;
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      console.log(param, value);

      switch (param) {
        case '--number-box-border-color':
        case '--number-box-bg-color':
          root.style.setProperty(param, value);
          break;

        default:
          break;
      }
    }
  }, [searchParams])

  return (
    <section
      className={`dice ${!isGamePlaying && gameQueue.length > 0 ? "dice_with-tx-queue" : ""
        }`}
    >
      {!isGamePlaying && gameQueue.length > 0 ? (
        <div className="dice__tx-in-queue">
          <p className="dice__tx-in-queue-text">
            You have {gameQueue.length} unviewed{" "}
            {gameQueue.length > 1 ? `results` : "result"}!
          </p>
          <button
            className="dice__tx-in-queue-btn"
            type="button"
            onClick={handlePlayResultsInQueue}
          >
            See {gameQueue.length > 1 ? `results` : "result"}
          </button>
        </div>
      ) : (
        <></>
      )}

      <div className="dice__game-block">
        <div
          className={`dice__result-block ${!isGamePlaying && gameQueue.length > 0
            ? "dice__result-block_with-tx-queue"
            : ""
            }`}
        >
          {!isGamePlaying && gameQueue.length > 0 ? (
            <button
              className="dice__tx-in-queue-btn dice__tx-in-queue-btn_mobile"
              type="button"
              onClick={handlePlayResultsInQueue}
            >
              See {gameQueue.length}{" "}
              {gameQueue.length > 1 ? `results` : "result"}
            </button>
          ) : (
            <></>
          )}
          <div className="dice__number-box">
            <ResultNumber
              arr={slot1.arr}
              rolling={slot1.rolling}
              rollingEnd={slot1.rollingEnd}
              {...{ isWin, isLost, isFast }}
            />
            <ResultNumber
              arr={slot2.arr}
              rolling={slot2.rolling}
              rollingEnd={slot2.rollingEnd}
              {...{ isWin, isLost, isFast }}
            />
          </div>
          <p className="dice__comma">.</p>
          <div
            className="dice__number-box"
            onClick={() => {
              // setSkipAutoPlay(!skipAutoPlay)
            }}
          >
            <ResultNumber
              arr={slot3.arr}
              rolling={slot3.rolling}
              rollingEnd={slot3.rollingEnd}
              {...{ isWin, isLost, isFast }}
            />
            <ResultNumber
              arr={slot4.arr}
              rolling={slot4.rolling}
              rollingEnd={slot4.rollingEnd}
              {...{ isWin, isLost, isFast }}
            />
          </div>
        </div>

        <div
          className={`dice__bet-block ${isBlocked ? "dice__bet-block_disabled" : ""
            }`}
        >
          <p className='dice__bet-block-title'>Guess where dice will fall</p>

          <div className="dice__slider-block">
            <button
              className="dice__amount-btn"
              type="button"
              aria-label="Reduce the position"
              onClick={handleReduceBet}
            >
              {ARROW_BTN_ICON}
            </button>
            <div className="dice__slider-container">
              <div className="dice__slider-line-container">
                <div className="dice__slider-line">
                  {Array(Math.ceil(388 / 4))
                    .fill(" ")
                    .map((el, i) => (
                      <div className="dice__slider-item" key={`slider${i}`} />
                    ))}
                </div>
                <div
                  className={`dice__slider-line dice__slider-line_type_active ${!up ? "dice__slider-line_visible" : ""
                    }`}
                  style={{ width: position + "%" }}
                >
                  {Array(Math.floor((388 * (position / 100)) / 4))
                    .fill(" ")
                    .map((el, i) => (
                      <div
                        className="dice__slider-item dice__slider-item_type_active"
                        key={`slider${i}`}
                      />
                    ))}
                </div>
                <div
                  className={`dice__slider-line dice__slider-line_type_active dice__slider-line_reverse ${up ? "dice__slider-line_visible" : ""
                    }`}
                  style={{ width: `${100 - position}%` }}
                >
                  {Array(Math.floor((388 * ((100 - position) / 100)) / 4))
                    .fill(" ")
                    .map((el, i) => (
                      <div
                        className="dice__slider-item dice__slider-item_type_active"
                        key={`slider${i}`}
                      />
                    ))}
                </div>
              </div>
              <Slider
                aria-label="position"
                min={0}
                max={100}
                step={0.15}
                valueLabelDisplay="on"
                valueLabelFormat={valueLabelFormat}
                defaultValue={START_BET_MEGA_DICE}
                value={position}
                onChange={handlePositionChange}
              />
              <p className="dice__slider-text">0</p>
              <p className="dice__slider-text dice__slider-text_type_max">
                100
              </p>
            </div>
            <button
              className="dice__amount-btn dice__amount-btn_type_increase"
              type="button"
              aria-label="Increase the position"
              onClick={handleIncreaseBet}
            >
              {ARROW_BTN_ICON}
            </button>
          </div>

          <div className="dice__range-block">
            <button
              className={`dice__range-button ${!up ? "dice__range-button_selected" : ""
                }`}
              type="button"
              onClick={toggleUnderRange}
            >
              Under
            </button>
            <button
              className={`dice__range-button ${up ? "dice__range-button_selected" : ""
                }`}
              type="button"
              onClick={toggleOverRange}
            >
              Over
            </button>
          </div>
        </div>

        <div className="dice__options-block">
          {user && !minBet ? (
            <div className="dice__options-block-preloader">
              <MiniPreloader />
            </div>
          ) : (
            <GameOptions
              btnText="Roll"
              onClick={roll}
              rolling={spin}
              bet={betValue}
              position={position}
              setBet={setBetValue}
              queue={gameQueue}
              minMaxBet={{ min: minBet, max: maxBet }}
              autoplayText="Number of Games"
              {...{
                preferredToken,
                active,
                chance,
                payout,
                isSoundOn,
                isBlocked,
                user,
                autoplayAmount,
                setAutoplayAmount,
                switchNetwork,
                isSupportedNetwork,
                isAutoplay,
                demoBalance,
                isMaxBet,
                isMinBet,
                isSufficientAllowance,
                allowToken,
                isDemo,
                demoCoinLogo,
                preferredChainId,
                tokenDecimals,
                betValueWriten,
                setBetValueWriten,
                isDemoSwitch,
                openWalletModal,
                externalAccount,
                sendToParent,
                isExternalWalletConnected,
                isExternalAccountConnection
              }}
            />
          )}
        </div>
      </div>

      {/* <div className="dice__table-block">
        <GamesTable
          allGames={allFormatMegadiceGames}
          gamesByUser={allFormatGamesByUser}
          {...{ user, displayCopyMsg, account }}
        />
      </div> */}
    </section>
  );
}

export default MegaDice;
